import React from 'react';
import moment from 'moment';
require('./styles.css');

export default class Countdown extends React.Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  };

  componentDidMount() {
    const { timeTillDate } = this.props;
    const then = moment(timeTillDate);
    let now = moment();
    let countdown = moment.duration(then.diff(now));
    if (countdown < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      this.interval = setInterval(() => {
        now = moment();
        countdown = moment.duration(then.diff(now));

        const days = countdown._data.days;
        const hours = countdown._data.hours;
        const minutes = countdown._data.minutes;
        const seconds = countdown._data.seconds;

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
          this.props.setReload(!this.props.reload);
        }

        this.setState({ days, hours, minutes, seconds });
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;

    if (!seconds) {
      return null;
    }

    return (
      <>
        Ao vivo em
        <div className="countdown-wrapper">
          {days ? <div className="countdown-item">{days}d :</div> : null}
          {hours ? <div className="countdown-item">{hours}h :</div> : null}
          {minutes ? <div className="countdown-item">{minutes}m :</div> : null}
          {seconds ? <div className="countdown-item">{seconds}s</div> : null}
        </div>
      </>
    );
  }
}
