import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-tabs/style/react-tabs.css';
import EventsServices from '../services/EventsServices';
import { setLoading } from '../store/loading/LoadingReducer';
import { useDispatch, useSelector } from 'react-redux';
import TemplateApp from '../components/template/TemplateApp';
import { useHistory } from 'react-router-dom';

const Information = (props) => {
  const auth = useSelector((state) => state.auth);
  const [info, setInfo] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      if (
        !auth.tickets.some((ticket) => ticket.participantId === auth.participantId)
      ) {
        history.push('/home');
        return;
      }

      let result = null;

      if (!props.preview) {
        result = await dispatch(EventsServices.findEventsInformation(props.eventId));
      }

      if (props.preview) {
        result = await dispatch(
          EventsServices.findEventsPreview(props.eventId, history)
        );
      }

      if (result) {
        setInfo(result);
      }
    }

    if (props.eventId && info.length <= 0) {
      fetchData().catch(() => dispatch(setLoading(false)));
    }
  }, [dispatch, history, props, info, auth.tickets, auth.participantId]);

  return (
    <TemplateApp>
      <Main>
        <div className="row no-gutters">
          <div className="col-lg-12 col-md-12 m-auto">
            {info && info.urlLandPage ? (
              <img src={info.url_info} className="image-banner" alt="" />
            ) : null}
          </div>
        </div>
        <div className="container pt-4">
          <div className="row">
            <div className="col-12">
              <div className="card border-light mb-3 border-shadow">
                <div
                  className="card-header text-light font-weight-bolder m-3 pb-0 border-0"
                  id="eventInfoCard"
                >
                  <h3 className="text-dark">
                    <strong>
                      {info.titulo_informacoes_gerais
                        ? info.titulo_informacoes_gerais
                        : 'Informações Gerais'}
                    </strong>
                  </h3>
                </div>
                <div className="card-header m-0 p-0" />
                <div className="card-body m-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: info.eventInformation || '',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default Information;
