import Api from './config/Api';
import ClientNotifications from './config/ClientEventSource';
import { addMessage } from '../store/messages/MessagesReducer';
import { setLoading } from '../store/loading/LoadingReducer';
import { responseError } from './exception/ApiException';
import instanceApiUploadS3 from './config/ApiUploadS3';
import axios from 'axios';

let clientNotifications = null;

class NotificationServices {
  async sendMessage(inputData) {
    try {
      if (inputData.roomFairId !== '' && inputData.message !== '') {
        await Api.instance.post('/notifications/users', inputData);
      }
    } catch (e) {}
  }

  sendImg(fileType, file) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/notifications/sendImg', {
          fileType,
        });

        if (response && response.data && response.data.payload) {
          const { signedUrl, urlFile } = response.data.payload;
          try {
            await instanceApiUploadS3.put(signedUrl, file);
          } catch (e) {}

          dispatch(setLoading(false));

          return urlFile;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));

      return null;
    };
  }

  recoverMyMessagePrivateSocket(roomFairId) {
    return (dispatch) => {
      try {
        window.socket.emit('getMessages', roomFairId);
      } catch (e) {
        dispatch(
          addMessage({
            message: 'Suas mensagens não foram carregadas!',
            level: 'warning',
          })
        );
      }

      return [];
    };
  }

  recoverMyMessagePrivate(roomFairId) {
    return async (dispatch) => {
      try {
        const response = await Api.instance.get(
          '/notifications/recoverMessages?roomFairId=' + roomFairId
        );

        if (response && response.data && response.data.payload) {
          return response.data.payload;
        }
      } catch (e) {
        console.log(e);
        dispatch(
          addMessage({
            message: 'Suas mensagens não foram carregadas!',
            level: 'warning',
          })
        );
      }

      return [];
    };
  }

  recoverAllMessages(roomFairId, interatividade) {
    return async (dispatch) => {
      try {
        const response = await Api.instance.get(
          '/notifications/recoverAllMessages?roomFairId=' +
            roomFairId +
            '&interatividade=' +
            interatividade
        );

        if (response && response.data && response.data.payload) {
          return response.data.payload;
        }
      } catch (e) {
        console.log(e);
        dispatch(
          addMessage({
            message: 'Suas mensagens não foram carregadas!',
            level: 'warning',
          })
        );
      }

      return [];
    };
  }

  recoverAllMessagesDs(roomFairId, token, eventId) {
    return async (dispatch) => {
      try {
        const response = await axios(
          process.env.REACT_APP_BASE_URL +
            '/notifications/recoverAllMessagesDs?roomFairId=' +
            roomFairId +
            '&interatividade=CHAT&token=' +
            token +
            '&eventId=' +
            eventId
        );

        if (response && response.data && response.data.payload) {
          return response.data.payload;
        }
      } catch (e) {
        console.log(e);
        dispatch(
          addMessage({
            message: 'Suas mensagens não foram carregadas!',
            level: 'warning',
          })
        );
      }

      return [];
    };
  }

  recoverQAMessages(roomFairId, interatividade) {
    return async (dispatch) => {
      try {
        const response = await Api.instance.get(
          '/notifications/recoverQAMessages?roomFairId=' +
            roomFairId +
            '&interatividade=' +
            interatividade
        );

        if (response && response.data && response.data.payload) {
          return response.data.payload;
        }
      } catch (e) {
        console.log(e);
        dispatch(
          addMessage({
            message: 'Suas mensagens não foram carregadas!',
            level: 'warning',
          })
        );
      }

      return [];
    };
  }

  onListeningNotification(roomFairId, showNotification) {
    return (dispatch) => {
      try {
        if (clientNotifications !== null) {
          return;
        }

        if (roomFairId !== '') {
          clientNotifications = new ClientNotifications(roomFairId);
          clientNotifications.onmessage = (e) => showNotification(e.data);
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: 'Não foi possível conectar-se com a sala!',
            level: 'danger',
          })
        );
      }
    };
  }

  removeListeningNotification() {
    if (clientNotifications !== null) {
      clientNotifications.close();
      clientNotifications = null;
    }
  }
}

export default new NotificationServices();
