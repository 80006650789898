import React from 'react';
import {useHistory} from 'react-router-dom';
import UserServices from "../services/UserServices";

const ConfirmRegister = (props) => {
    const history = useHistory();
    const eventId = props.eventId;
    const codeActive = props.codeActive;

    UserServices.confirmRegister({codeActive, eventId}, history);

    return (
        <>
        </>
    );
};

export default ConfirmRegister;
