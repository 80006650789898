import './Header.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MenuFast from '../menu/MenuFast';
import Logo from './Logo';
import MenuProfile from '../menu/MenuProfile';

export default ({ preview = false }) => {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const urlLogo = useSelector((state) => state.navigator.urlLogo);
  const [ownerTicket, setOwnerTicket] = useState(false);

  const getLogoCompany = () => {
    return urlLogo || '';
  };

  const goManagerEvent = () => {
    if (preview || !auth || !auth.tickets || auth.tickets.length <= 0) {
      return '#';
    }
    if (
      !preview &&
      !cart &&
      auth &&
      auth.roomsFair &&
      Object.keys(auth.roomsFair).length > 0
    ) {
      return '/eventLobby';
    }
    return '#';
  };

  useEffect(() => {
    if (auth.tickets.length > 0) {
      const isOwner = auth.tickets.some(
        (ticket) => ticket.participantId === auth.participantId
      );

      isOwner ? setOwnerTicket(true) : setOwnerTicket(false);
    }
  }, [auth.tickets, auth.participantId]);

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Logo logoCompany={getLogoCompany()} redirect={goManagerEvent()} />

        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon icon-toggler" />
          <span className="navbar-toggler-icon icon-closed" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {!preview && !cart && auth && auth.roomsFair && ownerTicket ? (
            <MenuFast />
          ) : (
            ''
          )}
          <ul className="navbar-nav ml-auto">
            {!preview && auth && auth.isLoggedIn && <MenuProfile />}
          </ul>
        </div>
      </nav>
    </>
  );
};
