export const CART = '@CartEvent';
export const CART_ITEM = '@CartItemEvent';

export const getCartStorage = () => {
  const cartStore = JSON.parse(localStorage.getItem(CART));

  if (cartStore) {
    return cartStore;
  }

  return null;
};

export const setCartStorage = (cart) => {
  localStorage.setItem(CART, JSON.stringify(cart));
};

export const removeCartStorage = () => {
  localStorage.removeItem(CART);
};


export const getCartItemStorage = () => {
  const cartItemStore = JSON.parse(localStorage.getItem(CART_ITEM));

  if (cartItemStore) {
    return cartItemStore;
  }

  return [];
};

export const setCartItemStorage = (cartItem) => {
  localStorage.setItem(CART_ITEM, JSON.stringify(cartItem));
};

export const removeCartItemStorage = () => {
  localStorage.removeItem(CART_ITEM);
};

export const cleanCouponDiscount = () => {
  const cartStorage = getCartStorage();

  if (!cartStorage) {
    return;
  }
  cartStorage.discountCoupon = undefined;
  setCartStorage(cartStorage);
};
