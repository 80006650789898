import {createAction, createReducer} from '@reduxjs/toolkit';
import {getCartItemStorage, removeCartItemStorage, setCartItemStorage} from "../../session/Cart";

const INITIAL_STATE = getCartItemStorage();

export const addCartItem = createAction('ADD_CART_ITEM');
export const editCartItem = createAction('EDIT_CART_ITEM');
export const removeRemoveItem = createAction('REMOVE_CART_ITEM');
export const removeRemoveAllItem = createAction('REMOVE_CART_ALL_ITEM');

export default createReducer(INITIAL_STATE, {
        [addCartItem.type]: (state, action) => {
            const cartItem = action.payload;
            const purchaseItems = state.find(item => item.ticketId === cartItem.ticketId);

            if (purchaseItems) {
                const qtd = cartItem.quantity + purchaseItems.quantity;
                cartItem.quantity = qtd;
                cartItem.total = qtd * cartItem.value;
                state = state.filter(item => item.ticketId !== cartItem.ticketId);
            }

            const result = [...state, cartItem];

            setCartItemStorage(result);

            return result;
        },

        [editCartItem.type]: (state, action) => {
            const cartItem = action.payload;
            const purchaseItems = state.find(item => item.ticketId === cartItem.ticketId);
            if (purchaseItems) {
                state = state.filter(item => item.ticketId !== cartItem.ticketId);
            }

            const result = [...state, cartItem];

            setCartItemStorage(result);

            return result;
        },

        [removeRemoveItem.type]: (state, action) => {
            const result = state.filter(item => item.ticketId !== action.payload);

            setCartItemStorage(result);

            return result;
        },

        [removeRemoveAllItem.type]: (state, action) => {
            removeCartItemStorage();
            return [];
        }
    }
);
