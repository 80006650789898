import Api from './config/Api';
import { removeAuth, setAuth, getAuth } from '../session/Auth';
import { removeCartStorage } from '../session/Cart';
import { refreshUserAuth } from '../store/auth/AuthReducer';
import { setLoading } from '../store/loading/LoadingReducer';
import { addMessage } from '../store/messages/MessagesReducer';
import { responseError } from './exception/ApiException';
import {
  addSetTicketId,
  removeItemNavigator,
  setNavigatorTabIndex,
} from '../store/navigator/NavigatorReducer';
import EventsServices from './EventsServices';
import LogServices from './LogServices';

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url;
};

class AuthService {
  signIn(data, cart, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/users/signIn', data);

        if (response && response.data && response.data.payload) {
          const authUser = response.data.payload;

          if (authUser.tickets && authUser.tickets.length > 0) {
            authUser.tickets = authUser.tickets.sort((a, b) => {
              if (a.updatedAt && b.updatedAt) {
                const dateA = new Date(a.updatedAt).getTime();
                const dateB = new Date(b.updatedAt).getTime();
                return dateA < dateB ? 1 : -1;
              }
              return 1;
            });
          }

          setAuth(authUser);

          dispatch(refreshUserAuth());

          if (!authUser) {
            dispatch(setLoading(false));
            return;
          }

          if (cart && cart.purchaseItems.length > 0) {
            const {
              valueBuy,
              valueDiscount,
              valueTotal,
            } = EventsServices.calculateCartValue(
              cart.purchaseItems,
              cart.discountCoupon
            );

            if (valueTotal === 0 && valueBuy > 0 && valueDiscount > 0) {
              await dispatch(
                EventsServices.eventFree(
                  cart,
                  data.email,
                  authUser.participantId,
                  history
                )
              );
              return;
            } else {
              history.push('/shoppingFinish');
              dispatch(setLoading(false));
              return;
            }
          }

          if (authUser.tickets && authUser.tickets.length === 1) {
            const defaultObject = {
              eventId: parseInt(response.data.payload.eventId),
              participantId: response.data.payload.participantId,
              url: window.location.pathname,
              socketId: window.socket.id,
              roomId: null,
              standId: null,
              tipo: 'LOGIN',
              isMobile: window.mobileCheck(),
            };

            LogServices.accessLog(defaultObject);

            const { ticketId } = authUser.tickets[0];
            dispatch(addSetTicketId(ticketId));
            try {
              let userStorage = JSON.parse(localStorage.getItem('@navigator'));
              if (
                userStorage &&
                userStorage.direcionar_lobby_salas &&
                userStorage.direcionar_lobby_salas !== null &&
                userStorage.direcionar_lobby_salas !== ''
              ) {
                if (isValidHttpUrl(userStorage.direcionar_lobby_salas)) {
                  const redirectTo = new URL(userStorage.direcionar_lobby_salas);
                  history.push(redirectTo.pathname);
                } else {
                  if (userStorage.direcionar_lobby_salas.startsWith('/')) {
                    history.push(userStorage.direcionar_lobby_salas);
                  } else {
                    history.push('/' + userStorage.direcionar_lobby_salas);
                  }
                }
              } else {
                history.push('/eventLobby');
              }
            } catch (err) {
              console.log(err.message);
            }

            dispatch(setLoading(false));
            return;
          }

          const existPurchasePayment =
            authUser.purchase &&
            authUser.purchase.filter((f) => f.statusPayment === true);

          if (
            existPurchasePayment &&
            existPurchasePayment.length > 0 &&
            authUser.tickets &&
            authUser.tickets.length > 0
          ) {
            const r = await dispatch(
              EventsServices.accessTicketEvent(
                data.email,
                data.eventId,
                authUser.tickets,
                history
              )
            );
            if (r) {
              return;
            }
          }

          if (!cart || cart.purchaseItems.length <= 0) {
            history.push('/home');
          }
        }

        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
    };
  }

  recover(data) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        await Api.instance.post('/users/recover', data);

        dispatch(setLoading(false));

        dispatch(
          addMessage({
            message: 'Senha recuperada com sucesso, acesse o email informado!',
            level: 'success',
          })
        );

        return true;
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return false;
    };
  }

  signOut() {
    return (dispatch) => {
      removeAuth();
      removeCartStorage();
      dispatch(setNavigatorTabIndex(0));
      dispatch(refreshUserAuth());
      dispatch(removeItemNavigator());
    };
  }

  reloadData(data, history) {
    return (dispatch) => {
      Api.instance
        .post('/users/reload', {
          eventId: data.eventId,
          userEmail: data.email,
          token: 'Bearer ' + data.token,
        })
        .then(
          (response) => {
            response.data.payload.token = response.data.payload.token.substring(7);
            const authUser = response.data.payload;
            const oldData = getAuth();
            setAuth(authUser);
            dispatch(refreshUserAuth());
            if (window.location.pathname.startsWith('/room/')) {
              const roomId = window.location.pathname.substring(6);
              const currentRoom = data.roomsFair.find(
                (x) => x.roomFairId === parseInt(roomId)
              );
              if (currentRoom) {
                const prevUrl = data.roomsFair.find(
                  (x) => x.roomFairId === parseInt(roomId)
                ).urlStreaming;
                const newUrl = authUser.roomsFair.find(
                  (x) => x.roomFairId === parseInt(roomId)
                ).urlStreaming;

                if (prevUrl !== newUrl) {
                  history.go(0);
                }
              }
            } else {
              if (JSON.stringify(oldData) !== JSON.stringify(authUser)) {
                history.go(0);
              }
            }

            const css_custom = response.data.payload.css_custom;

            const element = document.getElementById('custom_style');

            if (element) {
              element.parentNode.removeChild(element);
            }
            const customStyle = document.createElement('style');
            customStyle.setAttribute('id', 'custom_style');
            customStyle.append(css_custom);
            document.head.appendChild(customStyle);
          },
          (error) => {
            removeAuth();
            removeCartStorage();
            dispatch(setNavigatorTabIndex(0));
            dispatch(refreshUserAuth());
            dispatch(removeItemNavigator());
            history.go(0);
          }
        );
    };
  }
}

export default new AuthService();
