import React, { useState } from 'react';
import AuthService from '../services/AuthServices';
import { useDispatch, useSelector } from 'react-redux';
import NavigatorCartButton from '../components/cart/NavigatorCartButton';
import { useHistory } from 'react-router-dom';

const Recover = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const eventId = useSelector((state) => state.navigator.eventId);

  const [email, setEmail] = useState('');

  const navigateTo = () => history.push('/login');

  const handleSignIn = async (e) => {
    e.preventDefault();

    const data = {
      eventId,
      email,
    };

    const result = await dispatch(AuthService.recover(data));
    if (result) {
      props.history.push('/login');
    }
  };

  return (
    <>
      <NavigatorCartButton eventId={eventId} />
      <div className="row-cols-1 pt-lg-5">
        <div className="col-md-4 mx-auto">
          <div className="card-form-formated my-5">
            <div className="ml-4 mr-4">
              <h5 className="ml-3 card-title text-left">
                <strong>Recuperar senha</strong>
              </h5>
            </div>

            <hr className="hr-custom" />

            <div className="ml-4 mr-4">
              <div className="card-body">
                <form className="mt-4" onSubmit={handleSignIn}>
                  <div className="form-label-group mt-3">
                    <label htmlFor="email">E-mail</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="p-2">
                    <button
                      className="btn btn-lg btn-orange mt-4 btn-default"
                      type="submit"
                    >
                      RECUPERAR
                    </button>
                    <button
                      className="btn btn-lg btn-grey mt-4 btn-default"
                      onClick={navigateTo}
                    >
                      VOLTAR
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recover;
