import { getToken, removeAuth } from '../../session/Auth';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import * as http from 'http';
import history from './History';

const httpAgent = new http.Agent({
  keepAlive: true,
  timeout: 5 * 1000,
});

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  httpAgent,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

export const setAccessToken = (token) => {
  instanceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const getAccessToken = () => {
  const token = getToken();

  if (!token) return;

  const expirationTime = jwt.decode(token).exp * 1000;
  const dateNow = new Date();

  if (expirationTime > dateNow.getTime()) {
    return token;
  }
};

instanceAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && !error.response) {
      throw new Error('Tempo Excedido');
    }

    if ([401, 403].indexOf(error.response.status) === -1) {
      return Promise.reject(error);
    }

    removeAuth();
    history.push('/');
    window.location.reload();

    return Promise.reject({
      response: {
        data: {},
      },
    });
  }
);

class Api {
  get instance() {
    const token = getAccessToken();
    if (token) {
      setAccessToken(token);
    }
    instanceAxios.defaults.timeout = 40 * 1000;
    return instanceAxios;
  }
}

export default new Api();
