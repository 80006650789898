import React, { useState, useEffect } from 'react';
import undraw_line from '../../assets/images/undraw_wait_in_line.svg';

const QueueEvent = ({ item, roomsFair }) => {
  const [enterQueue, setEnterQueue] = useState('enter');
  const [queueNumber, setQueueNumber] = useState(null);

  const handler = (data) => {
    setQueueNumber(data);
    setEnterQueue('in');
  };

  const handler_left = () => {
    setQueueNumber(null);
    setEnterQueue('enter');
  };

  const handler_already = () => {
    setQueueNumber(null);
    setEnterQueue('already');
  };

  const handler_already_another = () => {
    setQueueNumber(null);
    setEnterQueue('already_another');
  };

  useEffect(() => {
    if (window.socket) {
      window.socket.on('joined_queue', handler);
      window.socket.on('left_queue', handler_left);
      window.socket.on('already_in_queue', handler_already);
      window.socket.on('already_in_another_queue', handler_already_another);

      return () => {
        window.socket.off('joined_queue', handler);
        window.socket.off('left_queue', handler_left);
        window.socket.off('already_in_queue', handler_already);
        window.socket.off('already_in_another_queue', handler_already_another);
      };
    }
  });

  return window.socket ? (
    item ? (
      <div className="queueHolder">
        <div
          className="row justify-content-center m-0 mt-5"
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <img
            src={undraw_line}
            alt="Aguardando Fila"
            style={{ paddingRight: '20px' }}
          />
          <h3 className="my-2">Fila Virtual</h3>
          {enterQueue === 'in' ? (
            <div style={{ maxWidth: '80%' }}>
              <p style={{ fontSize: '1.15rem' }}>Sua posição na fila é</p>
              <p
                style={{
                  fontSize: '1.5rem',
                  border: '2px solid #333',
                  borderRadius: '10px',
                  background: 'rgba(221, 221, 221, 0.61)',
                  display: 'inline-block',
                  padding: '10px 20px',
                  color: '#333',
                }}
              >
                <strong>{queueNumber}</strong>
              </p>
              <p>
                Aguarde sua vez, você será redirecionado automaticamente para a sala
                da atividade.
              </p>

              <button
                className="btn btn-md btn-danger"
                onClick={() => {
                  window.socket.emit('leave_queue', roomsFair.roomFairId);
                }}
              >
                Sair da Fila
              </button>
            </div>
          ) : enterQueue === 'enter' ? (
            <div style={{ maxWidth: '80%' }}>
              <p>
                Esta sala possui uma atividade interativa.
                <br />
                Entre na fila para participar!
              </p>
              <button
                onClick={() => {
                  if (window.socket) {
                    window.socket.emit('enter_queue', roomsFair.roomFairId);
                  }
                }}
                className="btn btn-primary btn-md mb-2"
              >
                Entrar
              </button>
              <p style={{ fontSize: '0.8rem' }}>
                <strong style={{ whiteSpace: 'pre-line' }}>
                  {item.instrucoesFila
                    ? item.instrucoesFila.replace(/\\n/g, '\n')
                    : ''}
                </strong>
              </p>
            </div>
          ) : enterQueue === 'already' ? (
            <div style={{ maxWidth: '80%' }}>
              <p>
                Você já está na fila.
                <br />
                Você só pode entrar na fila com apenas uma sessão.
              </p>
            </div>
          ) : enterQueue === 'already_another' ? (
            <div style={{ maxWidth: '80%' }}>
              <p>
                Você já está em outra fila deste evento.
                <br />
                Você só pode entrar em apenas uma fila por evento por vez.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    ) : null
  ) : null;
};

export default QueueEvent;
