import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Header from '../template/Header';
import HeaderExternal from '../template/HeaderExternal';

const NavigatorCartButton = ({
  eventId,
  invokeCartBuy,
  invokeAccessEvent,
  preview = false,
}) => {
  const modelEvent = useSelector((state) => state.navigator.modelEvent);

  return (
    <div className="container-fluid p-0">
      <div className="container">
        <Header>
          <HeaderExternal
            preview={preview}
            eventId={eventId}
            modelEvent={modelEvent}
            invokeCartBuy={invokeCartBuy}
            invokeAccessEvent={invokeAccessEvent}
          />
        </Header>
      </div>
    </div>
  );
};

export default memo(NavigatorCartButton);
