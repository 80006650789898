import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigatorCartButton from '../components/cart/NavigatorCartButton';
import { useHistory } from 'react-router-dom';
import { maskDocumentNumber } from '../utils/MaskInput';
import UserServices from '../services/UserServices';
import { Link } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';

const Register = (props) => {
  let camposArray;
  let obrigatorio;
  let aceiteEvento;

  try {
    const stringJson = JSON.parse(localStorage.getItem('@navigator'));

    if (stringJson.campos_personalizados !== null) {
      camposArray = JSON.parse(stringJson.campos_personalizados);
    } else {
      camposArray = [];
    }

    if (stringJson.personalizados_obrigatorios !== null) {
      obrigatorio = stringJson.personalizados_obrigatorios;
    } else {
      obrigatorio = 0;
    }

    if (stringJson.url_lgpd_cliente !== null) {
      aceiteEvento = stringJson.url_lgpd_cliente;
    } else {
      aceiteEvento = null;
    }
  } catch (err) {
    camposArray = [];
    obrigatorio = 0;
    aceiteEvento = null;
    console.log('Ocorreu um erro: ' + err.message);
  }

  let stateCampos = {};

  for (const key of camposArray) {
    stateCampos[key] = '';
  }

  const eventId = useSelector((state) => state.navigator.eventId);
  const cart = useSelector((state) => state.cart);
  const modelEvent = useSelector((state) => state.navigator.modelEvent);
  const exigirSenhaComplexa = useSelector(
    (state) => state.navigator.exigir_senha_complexa
  );
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [aceitaTermos, setAceitaTermos] = useState(false);
  const [fieldCustomValues, setFieldCustomValues] = useState(stateCampos);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const navigateTo = () => history.push('/login');

  const onChangeDocumentType = (value) => {
    setDocumentType(value);
    setDocumentNumber('');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (aceitaTermos) {
      const userStorage = JSON.parse(localStorage.getItem('@navigator'));
      const userSession = JSON.parse(localStorage.getItem('@airbnb-Token'));

      const data = {
        eventId,
        documentType,
        documentNumber,
        name,
        email,
        password,
        confirmPassword,
        confirmRegisterUrl: window.location.origin,
        aceitaTermos,
        camposPersonalizados: fieldCustomValues,
      };

      if (data.eventId !== userStorage.eventId) {
        alert('Ocorreu um erro! Por favor tente novamente!');
        history.push('/');
      } else {
        if (modelEvent === 'INGRESSO') {
          await dispatch(UserServices.register(data, cart, history, true));
        } else {
          await dispatch(UserServices.register(data, cart, history, false));
        }

        const userData = {
          session: userSession,
          storage: userStorage,
          url: window.location.pathname,
        };

        window.socket.emit('userData', userData);
      }
    } else {
      alert('Aceite os termos para se cadastrar!');
    }
  };

  const formDinamico = (camposArray, obrigatorio) => {
    if (Array.isArray(camposArray)) {
      if (camposArray.length > 0) {
        return (
          <>
            {camposArray.map((campo, index) => {
              const returnObj = (campo, value) => {
                let objectToUpdate = {};

                objectToUpdate[campo] = value;

                return objectToUpdate;
              };

              return (
                <div className="form-label-group mt-3" key={index}>
                  <label htmlFor={'form_din_' + campo}>{campo}</label>
                  <input
                    type="text"
                    id={'form_din_' + campo}
                    name={'form_din_' + campo}
                    className="form-control"
                    placeholder={campo}
                    required={obrigatorio}
                    value={fieldCustomValues[campo]}
                    onChange={(e) => {
                      const obj = returnObj(campo, e.target.value);
                      setFieldCustomValues({ ...fieldCustomValues, ...obj });
                    }}
                  />
                </div>
              );
            })}
          </>
        );
      }
    }
  };

  return (
    <>
      <NavigatorCartButton eventId={eventId} />
      <div className="container" id="register-container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="card-form-formated border-shadow">
              <div className="m-1 ml-lg-5 mr-lg-5 mt-lg-4 mb-lg-4 md-md-2">
                <h5 className="card-title text-left">
                  <strong>Cadastro</strong>
                </h5>
              </div>

              <hr className="hr-custom" />

              <div className="m-1 m-lg-5 md-md-2" id="card-body-container">
                <div className="card-body p-0">
                  <form onSubmit={handleSignIn}>
                    {modelEvent === 'INGRESSO' ? (
                      <>
                        <div className="form-label-group col-lg-7 form-custom-select">
                          <label htmlFor="documentType">Tipo de Documento</label>
                          <select
                            className="form-control"
                            id="documentType"
                            onChange={(e) => onChangeDocumentType(e.target.value)}
                            required
                            autoFocus
                          >
                            <option />
                            <option>CNPJ</option>
                            <option>CPF</option>
                          </select>
                        </div>
                        <div className="form-label-group mt-3">
                          <label htmlFor="documentNumber">Documento</label>
                          <input
                            type="text"
                            id="documentNumber"
                            name="documentNumber"
                            className="form-control"
                            placeholder="Documento"
                            value={documentNumber}
                            required
                            onChange={(e) =>
                              setDocumentNumber(
                                maskDocumentNumber(e.target.value, documentType)
                              )
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="form-label-group mt-3">
                      <label htmlFor="name">Nome</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome e Sobrenome"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-label-group mt-3">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        autoComplete="username"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      />
                    </div>

                    {formDinamico(camposArray, obrigatorio)}

                    <div className="form-label-group mt-3">
                      <label htmlFor="password">Senha</label>
                      <div className="field-container">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          name="password"
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="Senha"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="no-btn password-eye"
                          id="open-eye"
                          style={
                            !showPassword
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                          onClick={() => setShowPassword(true)}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="no-btn password-eye"
                          id="closed-eye"
                          style={
                            showPassword ? { display: 'block' } : { display: 'none' }
                          }
                          onClick={() => setShowPassword(false)}
                        >
                          <i className="fa fa-eye-slash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="form-label-group mt-3">
                      <label htmlFor="confirmPassword">Confirmar Senha</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        name="password"
                        autoComplete="new-password"
                        className="form-control"
                        placeholder="Confirmar senha"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {exigirSenhaComplexa ? (
                        <div className="mt-4">
                          <PasswordChecklist
                            rules={[
                              'minLength',
                              'specialChar',
                              'number',
                              'capital',
                              'lowercase',
                              'match',
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={confirmPassword}
                            messages={{
                              minLength: 'A senha tem 8 ou mais caracteres.',
                              specialChar:
                                'A senha tem um caractere especial(!,@,$...)',
                              number: 'A senha tem um número.',
                              capital: 'A senha tem uma letra maiúscula.',
                              lowercase: 'A senha tem uma letra minúscula.',
                              match: 'As senhas são iguais.',
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div className="form-label-group mt-3">
                      <input
                        type="checkbox"
                        id="aceitaTermos"
                        name="aceitaTermos"
                        className="cursorPointer dInline"
                        required
                        onChange={(e) => setAceitaTermos(e.target.checked)}
                      />
                      <label htmlFor="aceitaTermos" className="mb-0 dInline">
                        &nbsp;
                        {'Aceito os termos da '}
                        <Link
                          to="static/files/DigiClowd_PoliticaPrivacidade_29032021.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          política de privacidade
                        </Link>
                        {aceiteEvento && aceiteEvento !== null ? (
                          <>
                            {' '}
                            deste site e a{' '}
                            <a
                              href={aceiteEvento}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              política de privacidade
                            </a>{' '}
                            do evento.
                          </>
                        ) : (
                          ' deste site.'
                        )}
                      </label>
                    </div>

                    <div className="pt-2" id="register-btns-container">
                      <button
                        className="btn btn-lg btn-orange mt-4 btn-default"
                        id="register-submit-btn"
                        type="submit"
                      >
                        <span>FINALIZAR CADASTRO</span>
                      </button>
                      <p className="mt-4" style={{ textAlign: 'center' }}>
                        Já possui cadastro?{' '}
                        <button
                          className="btn-to-a"
                          onClick={navigateTo}
                          style={{ cursor: 'pointer' }}
                        >
                          Clique para entrar.
                        </button>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
