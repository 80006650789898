import React, { memo } from 'react';
import IconCheck from '../svg/IconCheck';
import InputQuantity from './InputQuantity';
import { getDate, maskCurrency } from '../../utils/MaskInput';
import EventsServices from '../../services/EventsServices';
import Moment from 'react-moment';
import PopoverInfo from './PopoverInfo';

const CartListEvent = ({
  cart,
  valueTotal,
  onChangeValues,
  addCartItem,
  enableButtonBuy = false,
  preview = false,
  maxHeight,
}) => {
  const decrementCount = (item) => {
    if (item.quantity > 0) {
      onChangeValues(cart, item.ticketId, item.quantity - 1);
    }
  };

  const incrementCount = (item) => {
    onChangeValues(cart, item.ticketId, item.quantity + 1);
  };

  return (
    <>
      <div className="card border-light mb-3 border-shadow">
        <div className="bg-dark m-0 p-0">
          <div className="card-header text-light bg-transparent font-weight-bolder m-3 border-0">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <h3 className="text-light">
                  <strong>Ingressos</strong>
                </h3>
              </div>
              <div className="col-md-7 col-sm-12 text-sm-right">
                <h3>
                  <strong className="text-right">{maskCurrency(valueTotal)}</strong>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-body m-3 pt-0 boxContentHolder"
          style={{ maxHeight: maxHeight }}
        >
          <div className="mt-3 shopping-box">
            <>
              {cart &&
                cart.purchaseItems &&
                cart.purchaseItems.map((item, index) => {
                  return (
                    <div className="row mb-3" key={index}>
                      <div className="col-md-8 col-sm-12">
                        <p className="m-0 title">
                          <strong>{item.description}</strong>
                          {item.informationalText &&
                            item.informationalText !== '' && (
                              <PopoverInfo
                                title={item.description}
                                informationalText={item.informationalText}
                              />
                            )}
                        </p>

                        <p className="m-0 value">R$ {maskCurrency(item.value)}</p>
                        {EventsServices.isTicketAvailable(item) ? (
                          <p className="m-0 description">
                            <i>
                              Venda disponível até{' '}
                              <Moment format="DD/MM/yyyy">
                                {getDate(cart.dateEnd)}
                              </Moment>
                            </i>
                          </p>
                        ) : (
                          <p className="m-0 description">Ingresso esgotado</p>
                        )}
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <InputQuantity
                          disabled={
                            !EventsServices.isTicketAvailable(item) || preview
                          }
                          cart={cart}
                          item={item}
                          decrementCount={decrementCount}
                          incrementCount={incrementCount}
                          onChangeValues={onChangeValues}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          </div>

          {enableButtonBuy &&
            cart &&
            EventsServices.isAllTicketAvailable(cart.purchaseItems) && (
              <div className="mt-5">
                {!preview ? (
                  <button
                    onClick={() => addCartItem(cart)}
                    className="btn  btn-orange"
                  >
                    COMPRAR
                    <IconCheck />
                  </button>
                ) : (
                  <button className="btn  btn-orange">
                    COMPRAR
                    <IconCheck />
                  </button>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default memo(CartListEvent);
