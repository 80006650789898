import React, {memo, useState} from 'react';
import './ModalSytles.css';
import {maskCurrency} from "../../utils/MaskInput";
import {Link} from "react-router-dom";
import Pagination from "./Pagination";
import Moment from 'react-moment';

const ListPurchase = ({purchase}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(4);

    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = purchase ? purchase.slice(indexOfFirstPost, indexOfLastPost) : [];

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className="border-light mb-1">
            <table className="table table-light table-responsive-md table-responsive-sm mt-2">
                <thead className="thead-dark">
                <tr>
                    <th>ID</th>
                    <th>Valor Total</th>
                    <th>Data</th>
                    <th className="text-center">Quantidade</th>
                    <th>Status</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <>
                    {currentItems && currentItems.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.purchaseId}</td>
                                <td>{maskCurrency(item.paymentMethod !== 'FREE' ? item.value : 0)}</td>
                                <td>
                                    <Moment format="DD/MM/yyyy">
                                        {item.createdAt}
                                    </Moment>
                                </td>
                                <td className="text-center">{item.quantity}</td>
                                <td>
                                    {item.paymentMethod === 'FREE' ? 'GRATUITO' : item.statusPayment ? 'PAGO' : 'PENDENTE'}
                                </td>
                                <td className="text-right">
                                    {
                                        item.paymentMethod !== 'FREE' &&
                                        <Link to={'/purchaseDetail/' + item.purchaseId}>
                                            <button
                                                type="button"
                                                className="btn btn-md btn-blue btn-block m-1">
                                                Detalhes
                                            </button>
                                        </Link>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </>
                </tbody>
            </table>

            <div className="d-flex justify-content-end mt-4">
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={purchase ? purchase.length : 0}
                    paginate={paginate}
                />
            </div>

        </div>
    );
};

export default memo(ListPurchase);
