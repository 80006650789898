import React, { useState, useEffect } from 'react';
import EventsServices from '../services/EventsServices';
import ChatDs from '../components/interatividades/ChatDs';

const RoomDigitalSignage = (props) => {
  const [chatData, setChatData] = useState(null);
  const [roomFairId, setRoomFairId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const itemRoomFair = await EventsServices.getRoomsFairDataDs(
        props.token,
        props.eventId
      );

      if (itemRoomFair) {
        setRoomFairId(itemRoomFair.data.room.roomFairId);
        try {
          const dadosChat = JSON.parse(
            itemRoomFair.data.room.dados_interatividade
          ).find((x) => {
            return x.type === 'CHAT';
          });
          setChatData(dadosChat);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [props.token, props.eventId]);

  return chatData ? (
    <>
      <ChatDs
        dados={chatData}
        token={props.token}
        roomFairId={roomFairId}
        eventId={props.eventId}
      />
    </>
  ) : null;
};

export default RoomDigitalSignage;
