import React, { useState, memo } from 'react';
import Avatar from 'react-avatar';
import LinkMediaSocial from '../stands/ListMidiaSocial';
import NetworkModal from './NetworkModal'; // Import the NetworkModal component

const NetworkParticipantsList = (props) => {
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const handleCardClick = (participant) => {
    setSelectedParticipant(participant);
  };

  const closeModal = () => {
    setSelectedParticipant(null);
  };

  const sortedParticipants = props.networkParticipants.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <>
      <div className="row">
        {sortedParticipants &&
          sortedParticipants.sort().map((item, index) => {
            return (
              <div
                className="col-12 col-md-6 col-lg-4 p-2"
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleCardClick(item)}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '16px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'transform 0.3s',
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.transform = 'scale(1.05)')
                  }
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                >
                  <Avatar
                    googleId="118096717852922241760"
                    size="90"
                    round={true}
                    src={item.avatarImage}
                  />
                  <div style={{ marginTop: '16px', textAlign: 'center' }}>
                    <strong>{item.name}</strong>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <small>{item.company}</small>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <small>{item.responsibility}</small>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      maxHeight: '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                    }}
                  >
                    <small>
                      {item.mini_bio && item.mini_bio.length > 90
                        ? `${item.mini_bio.substring(0, 90)}...`
                        : item.mini_bio}
                    </small>
                  </div>
                  <div style={{ marginTop: '16px', textAlign: 'center' }}>
                    <LinkMediaSocial
                      itemData={item}
                      mediaSocial={item.linkMediaSocial}
                      align=""
                      marginRight="mr-0"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        {props.networkParticipants && props.networkParticipants.length <= 0 ? (
          <div className="col-12">
            <div className="text-semi-light text-center">
              Nenhum participante encontrado.
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>

      <NetworkModal participant={selectedParticipant} onClose={closeModal} />
    </>
  );
};

export default memo(NetworkParticipantsList);
