import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import TemplateApp from '../components/template/TemplateApp';
import NetworkTitle from '../components/network/NetworkTitle';
import EventsServices from '../services/EventsServices';
import { setLoading } from '../store/loading/LoadingReducer';
import { useDispatch, useSelector } from 'react-redux';
import NetworkServices from '../services/NetworkServices';
import NetworkLetterList from '../components/network/NetworkLetterList';
import { existUrl } from '../utils/Ultis';
import NetworkInterestAreasList from '../components/network/NetworkInterestAreasList';
import NetworkParticipantsList from '../components/network/NetworkParticipantsList';
import Network from '../pages/Network';
import NetworkCategoriasList from '../components/network/NetworkCategoriasList';

let arrayForHoldingParticipants = [];
const pageSizeDefault = 20;

const NetworkParticipant = (props) => {
  const auth = useSelector((state) => state.auth);
  const [isLoad, setIsLoad] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [searchData, setSearchData] = useState({
    letter: '',
    search: '',
    areaInterest: [],
    categorias_network: [],
    searchOnline: null,
    page: 0,
    pageSize: pageSizeDefault,
  });

  const [interestAreas, setInterestAreas] = useState([]);
  const [selectedInterestAreas, setSelectedInterestAreas] = useState([]);
  const [categorias_network, setCategorias_network] = useState([]);
  const [selectedCategorias_network, setSelectedCategorias_network] = useState([]);
  const [networkPagination, setNetworkPagination] = useState({
    pagination: {
      page: 0,
      pageSize: pageSizeDefault,
      total: 1,
    },
  });
  const [networkParticipants, setNetworkParticipants] = useState([]);
  const dispatch = useDispatch();

  const addInterestAreas = async (value, index) => {
    let item = interestAreas[index].name;

    setInterestAreas(
      NetworkServices.updateInterestAreas(interestAreas, value, item)
    );

    const areas = NetworkServices.getInterestAreasSelected(
      item,
      selectedInterestAreas
    );

    setSelectedInterestAreas(areas);

    await getInterestAreas(areas);
  };

  const addCategorias_network = async (value, index) => {
    let item = categorias_network[index].name;

    setCategorias_network(
      NetworkServices.updateCategoriasNetwork(categorias_network, value, item)
    );

    const areas = NetworkServices.getCategoriasNetworkSelected(
      item,
      selectedCategorias_network
    );
    setSelectedCategorias_network(areas);

    await getCategoriasNetwork(areas);
  };

  const findNetworkParticipant = async (dataInput, isPaginator = false) => {
    const { pagination, response } = await dispatch(
      NetworkServices.searchNetwork(dataInput, auth.eventId)
    );

    if (pagination && response && response.length > 0) {
      setNetworkPagination({
        pagination,
      });

      loadParticipants(response, isPaginator);
    } else {
      setNetworkPagination({
        ...networkPagination,
        pagination: {
          page: networkPagination.pagination.page,
          pageSize: networkPagination.pagination.pageSize,
          total: 1,
        },
      });
    }
  };

  const getInterestAreas = async (areas) => {
    setNetworkParticipants([]);

    setSearchData({
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      areaInterest: areas,
    });

    const dataInput = {
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      areaInterest: areas,
    };
    await findNetworkParticipant(dataInput);
  };

  const getCategoriasNetwork = async (areas) => {
    setNetworkParticipants([]);

    setSearchData({
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      categorias_network: areas,
    });

    const dataInput = {
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      categorias_network: areas,
    };
    await findNetworkParticipant(dataInput);
  };

  const getLetter = async (item) => {
    setNetworkParticipants([]);

    setSearchData({
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      letter: item,
    });

    const dataInput = {
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      letter: item,
    };
    await findNetworkParticipant(dataInput);
  };

  const getSearch = async (item) => {
    setNetworkParticipants([]);

    setSearchData({
      ...searchData,
      page: 0,
      pageSize: pageSizeDefault,
      search: item,
    });

    const dataInput = { ...searchData, search: item };
    await findNetworkParticipant(dataInput);
  };

  const handleShowMoreParticipants = async () => {
    if (networkPagination.pagination.total === 1) {
      return;
    }

    const pageSize = networkPagination.pagination.pageSize;

    setSearchData({
      ...searchData,
      page: networkPagination.pagination.page + 1,
      pageSize,
    });

    const dataInput = {
      ...searchData,
      page: networkPagination.pagination.page + 1,
      pageSize,
    };

    await findNetworkParticipant(dataInput, true);
  };

  const openTab = (e, url) => {
    e.preventDefault();
    if (existUrl(url)) {
      window.open(url);
    }
  };

  const loadParticipants = (participants, isPaginator = false) => {
    const listParticipants = [];
    for (const obj of participants) {
      obj.linkMediaSocial = NetworkServices.loadSocialMedia(obj);
      listParticipants.push(obj);
    }
    loopWithSlice(listParticipants, isPaginator);
  };

  const loopWithSlice = (participants, isPaginator = false) => {
    if (!participants || participants.length <= 0) {
      return;
    }

    if (isPaginator) {
      arrayForHoldingParticipants = [
        ...arrayForHoldingParticipants,
        ...participants,
      ];
      setNetworkParticipants(arrayForHoldingParticipants);
    } else {
      arrayForHoldingParticipants = [];
      arrayForHoldingParticipants = [
        ...arrayForHoldingParticipants,
        ...participants,
      ];
      setNetworkParticipants(arrayForHoldingParticipants);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);

      const { pagination, response } = await dispatch(
        NetworkServices.searchNetwork(searchData, auth.eventId)
      );

      if (pagination && response && response.length > 0) {
        setNetworkPagination({
          pagination,
        });

        const listParticipants = [];
        for (const obj of response) {
          obj.linkMediaSocial = NetworkServices.loadSocialMedia(obj);
          listParticipants.push(obj);
        }
        loopWithSlice(listParticipants, true);
      }

      const eventData = await dispatch(EventsServices.findEvents());
      if (eventData && eventData.interestAreas) {
        let areas = [
          {
            checked: true,
            name: 'Todas as Categorias',
          },
        ];

        areas = areas.concat(
          eventData.interestAreas.map((item) => {
            return {
              checked: false,
              name: item,
            };
          })
        );

        setInterestAreas(areas);
      }

      if (eventData && eventData.categorias_network) {
        let areas = [
          {
            checked: true,
            name: 'Todas as Categorias',
          },
        ];

        areas = areas.concat(
          eventData.categorias_network.map((item) => {
            return {
              checked: false,
              name: item,
            };
          })
        );

        setCategorias_network(areas);
      }
    };

    if (!isLoad && auth.participantId && networkParticipants.length <= 0) {
      arrayForHoldingParticipants = [];
      fetchData().catch(() => dispatch(setLoading(false)));
    }
  }, [isLoad, dispatch, auth, networkParticipants, searchData, networkPagination]);

  /*const [message, setMessage] = useState("");

    const sendChatMessage = e => {
        e.preventDefault();
        window.socket.emit('chat message', message);
    }*/

  return (
    <TemplateApp>
      <Main>
        <nav className="container">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Network
            </a>
            <a
              className="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Perfil
            </a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container network">
              <div className="row">
                <div className="col-12">
                  <NetworkTitle
                    title=""
                    description="Conheça todos os Participantes"
                  >
                    <div>
                      <div className="card-body p-0">
                        {/*<div className="row">
                                                <form onSubmit={sendChatMessage}>
                                                    <input
                                                        type="text"
                                                        id="msg"
                                                        name="msg"
                                                        value={message}
                                                        onChange={(e => setMessage(e.target.value))}
                                                    />
                                                    <button type="submit">
                                                        Enviar
                                                    </button>
                                                </form>
                                            </div>*/}
                        <div className="row">
                          <div className="col-sm-6 col-md-2 col-xl-2">
                            <NetworkLetterList getLetter={getLetter} />
                          </div>
                          <div className="col-sm-6 col-md-6 col-xl-7">
                            <div className="border rounded bg-light">
                              <div className="m-4">
                                <NetworkParticipantsList
                                  networkParticipants={networkParticipants}
                                  openTab={openTab}
                                />
                              </div>
                            </div>
                            {networkParticipants &&
                            networkParticipants.length >= pageSizeDefault &&
                            networkPagination.pagination.total > 1 ? (
                              <div className="pull-right">
                                <label className="btn btn-sm btn-blue mt-2">
                                  <input
                                    type="button"
                                    className="border-0"
                                    onClick={(e) => handleShowMoreParticipants()}
                                  />
                                  Mostrar mais...
                                </label>
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                          <div className="col-sm-12 col-md-4 col-xl-3">
                            <div className="ml-2 mr-2 p-0">
                              <div className="mb-2">
                                <h5>Encontrar pessoas</h5>
                              </div>

                              <div className="mb-4">
                                <div className="input-group mb-3 rounded">
                                  <input
                                    type="text"
                                    id="search"
                                    name="search"
                                    className="form-control"
                                    placeholder="Buscar pessoas"
                                    onChange={(e) => setInputSearch(e.target.value)}
                                    onKeyUp={(e) =>
                                      e.keyCode === 13 &&
                                      getSearch(e.target.value) &&
                                      e.preventDefault()
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      onClick={(e) => getSearch(inputSearch)}
                                    >
                                      <i className="fa fa-search" />
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-2">
                                <div>
                                  {interestAreas && interestAreas.length > 2 ? (
                                    <NetworkInterestAreasList
                                      interestAreas={interestAreas}
                                      addInterestAreas={addInterestAreas}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </div>

                              <div className="mb-2">
                                <div>
                                  {categorias_network &&
                                  categorias_network.length > 2 ? (
                                    <NetworkCategoriasList
                                      categorias_network={categorias_network}
                                      addCategorias_network={addCategorias_network}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NetworkTitle>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <Network eventId={auth.eventId} inside={true} />
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default NetworkParticipant;
