import React, { useState } from 'react';
import External from './External';
import InfoRepresentative from '../stands/InfoRepresentative';
import LobbyStand from '../stands/LobbyStand';
import Calendly from '../calendly/Calendly';

const InteratividadesStands = ({ roomsFair, openTab, openTabLog, preview }) => {
  const [activeTab, setActiveTab] = useState(0);
  let dados = JSON.parse(roomsFair.dados_interatividade);

  if (roomsFair.habilitar_agendamento && roomsFair.usuario_calendly) {
    dados.push({
      name: roomsFair.titulo_calendly ? roomsFair.titulo_calendly : 'Reunião',
      type: 'CALENDLY',
      active: true,
    });
  }

  dados.push({
    name: roomsFair.nome_aba_catalogos ? roomsFair.nome_aba_catalogos : 'Catálogos',
    type: 'CATALOGOS',
    active: roomsFair.standFileDownload.length > 0,
  });

  dados.push({
    name: 'Informações',
    type: 'STANDINFO',
    active: true,
  });

  var tabsAtivas = [];

  tabsAtivas = dados.filter(function (data) {
    return data.active === true;
  });

  return dados ? (
    <div>
      <ul className="nav nav-tabs" style={{ flexWrap: 'wrap-reverse' }}>
        {tabsAtivas.reverse().map((item, index) => {
          return (
            <li
              id={'tabsStand' + item.type}
              className="nav-item"
              key={'tabsInteratividade' + index}
            >
              <button
                className={activeTab === index ? 'active nav-link' : 'nav-link'}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                <span>{item.name}</span>
              </button>
            </li>
          );
        })}
      </ul>

      {tabsAtivas.map((item, index) => {
        if (item.type === 'STANDINFO') {
          return (
            <div
              key={'contentInteratividade' + index}
              className={activeTab === index ? 'active-tab' : 'hidden-tab'}
            >
              <div
                className="col-md-12 col-lg-12 bg-white box-chat m-0"
                style={{ height: 'fit-content', padding: '0.75rem' }}
              >
                <div className="row text-center">
                  <div className="col-12">
                    <InfoRepresentative
                      roomsFair={roomsFair}
                      openTab={openTab}
                      openTabLog={openTabLog}
                      preview={preview}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        if (item.type === 'CATALOGOS' && roomsFair.standFileDownload.length > 0) {
          return (
            <div
              key={'contentInteratividade' + index}
              className={activeTab === index ? 'active-tab' : 'hidden-tab'}
            >
              <div
                className="col-md-12 col-lg-12 bg-white box-chat m-0"
                style={{ height: 'fit-content', padding: '0.75rem' }}
              >
                <div className="row text-center">
                  <div className="col-12 pt-2">
                    <LobbyStand
                      roomsFair={roomsFair}
                      openTabLog={openTabLog}
                      preview={preview}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        if (
          item.type === 'CALENDLY' &&
          roomsFair.habilitar_agendamento &&
          roomsFair.usuario_calendly
        ) {
          return (
            <div
              key={'contentInteratividade' + index}
              className={activeTab === index ? 'active-tab' : 'hidden-tab'}
            >
              <div
                className="col-md-12 col-lg-12"
                style={{ height: 'fit-content', padding: '0' }}
              >
                <div className="row no-gutters">
                  <div className="col-12">
                    <Calendly url={roomsFair.usuario_calendly} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        if (item.type === 'EXTERNO' && item.active) {
          return (
            <div
              key={'contentInteratividade' + index}
              className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              id="fairExternal"
            >
              <External dados={item} />
            </div>
          );
        }
        return null;
      })}
    </div>
  ) : null;
};

export default InteratividadesStands;
