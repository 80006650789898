import React, {useEffect} from 'react';
import './Toast.css';
import {useDispatch} from "react-redux";
import {removeMessage} from "../../store/messages/MessagesReducer";

const Toast = ({toast}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(removeMessage(toast.description));
        }, 5000);
    }, [dispatch, toast]);


    return (
            <div className='notification-container toast-effect'>
                {
                    <div className='notification toast toast-effect' style={{backgroundColor: toast.backgroundColor}}>
                        <div className="notification-image">
                            <img src={toast.icon} alt=""/>
                        </div>
                        <div>
                            <p className="notification-title">{toast.title}</p>
                            <p className="ml-5 mt-3">
                                <strong>{toast.description}</strong>
                            </p>
                        </div>
                    </div>
                }
            </div>
    );
}

export default Toast;