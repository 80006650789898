import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
require('./styles.css');

const AgendaItem = (props) => {
  const [palestranteData, setPalestranteData] = useState(null);

  const getPalestrante = () => {
    const palestrantes = props.data.palestrantes;
    for (let index = 0; index < palestrantes.length; index++) {
      const item = palestrantes[index];
      if (item.tipo === 'PALESTRANTE') {
        setPalestranteData(item);
        break;
      }
    }
  };

  const palestranteNome = () => {
    if (palestranteData !== null) {
      return <strong>{palestranteData.nome}</strong>;
    } else {
      return <></>;
    }
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(getPalestrante, []);

  if (props.data && props.data !== null) {
    return (
      <li>
        <div className="listPreviewHolder">
          <Link to={'/room/' + props.data.roomId}>
            <img src={props.data.urlIcon} alt={props.data.palestra_nome} />
          </Link>
          <div className="listTextHolder">
            <div>
              <h3>{props.data.palestra_nome}</h3>
              <p className="mb-1">
                {props.data.palestra_descricao.substring(0, 100) + '...'}
              </p>
              <p className="mb-1">{palestranteNome()}</p>
              <p className="mb-0">
                <Moment format="L" locale="pt-br" tz={userTimeZone}>
                  {props.data.data.substring(0, 11) +
                    props.data.hora_inicio +
                    '+00:00'}
                </Moment>
                {' - '}
                <Moment
                  format="LT"
                  locale="pt-br"
                  tz={userTimeZone}
                  className="timeSpan"
                >
                  {props.data.data.substring(0, 11) +
                    props.data.hora_inicio +
                    '+00:00'}
                </Moment>
                {props.data.isLive ? (
                  <img
                    className="liveCamera"
                    src="/static/imgs/live-camera.png"
                    alt="Ao vivo agora"
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </li>
    );
  } else {
    return <></>;
  }
};

export default AgendaItem;
