import React, { memo } from 'react';

const NetworkCategorias = ({
  categorias_network,
  addCategorias_network,
  selectedCategorias_network,
}) => {
  return (
    <div className="btn-group-toggle" data-toggle="buttons">
      {categorias_network.categorias_network &&
        categorias_network.categorias_network.map((item, index) => {
          return selectedCategorias_network.includes(item) ? (
            <label key={index} className="btn btn-outline-secondary mr-1 active">
              <input
                type="checkbox"
                name="categorias_network"
                id={index}
                value={item}
                onClick={(e) => addCategorias_network(e.target.value)}
              />
              {item}
            </label>
          ) : (
            <label key={index} className="btn btn-outline-secondary mr-1">
              <input
                type="checkbox"
                name="categorias_network"
                id={index}
                value={item}
                onClick={(e) => addCategorias_network(e.target.value)}
              />
              {item}
            </label>
          );
        })}
    </div>
  );
};

export default memo(NetworkCategorias);
