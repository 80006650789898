import {setLoading} from "../store/loading/LoadingReducer";
import Api from "./config/Api";

class LocalityServices {

    findAllStates() {
        return async (dispatch) => {
            try {
                dispatch(setLoading(true));

                const response = await Api.instance.get('/locality/state');

                if (response && response.data && response.data.payload) {
                    dispatch(setLoading(false));
                    return response.data.payload;
                }

            } catch (e) {
                dispatch(setLoading(false));
                dispatch({
                    message: 'Estados não carregados',
                    level: 'warning'
                });
            }

            dispatch(setLoading(false));

            return [];
        }
    }

    findCityByState(stateId) {
        return async (dispatch) => {
            try {
                dispatch(setLoading(true));

                const response = await Api.instance.get('/locality/city/' + stateId);

                if (response && response.data && response.data.payload) {
                    dispatch(setLoading(false));
                    return response.data.payload;
                }

            } catch (e) {
                dispatch(setLoading(false));
                dispatch({
                    message: 'Cidades não carregadas',
                    level: 'warning'
                });
            }

            dispatch(setLoading(false));

            return [];
        }
    }

}

export default new LocalityServices();