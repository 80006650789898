import React from 'react';
import {Link} from 'react-router-dom';

export default ({logoCompany, redirect}) => {
    return (
        <Link to={redirect || '#'} className="navbar-brand">
            <img src={logoCompany || ''} className="logo" alt="" />
        </Link>
    );
};
