import React, { memo, useState } from 'react';
import Pagination from './Pagination';
import { maskInviteVoucher } from '../../utils/MaskInput';
import Moment from 'react-moment';

const ListTickets = ({
  purchase,
  isPurchase,
  tickets,
  handleApplyVoucher,
  ownerTicket,
  handleExternalVoucher,
  goManagerEvent,
}) => {
  const [inviteVoucher, setInviteVoucher] = useState('');
  const [externalEmail, setExternalEmail] = useState('');
  const [externalName, setExternalName] = useState('');
  const [externalVoucher, setExternalVoucher] = useState({
    ticketId: null,
    eventId: null,
    voucher: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = tickets
    ? tickets.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const statusPayment = (purchaseId) => {
    return (
      purchase &&
      purchase.find((item) => item.purchaseId === purchaseId && item.statusPayment)
    );
  };

  return (
    <>
      <div className="border-light mb-1">
        <table className="table table-light table-responsive-md table-responsive-sm mt-2">
          <thead className="thead-dark">
            <tr>
              <th>Nome</th>
              <th>Data</th>
              <th>ID Ingresso</th>
              <th className="text-center">Utilizado</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <>
              {currentItems &&
                currentItems
                  .filter((obj) => statusPayment(obj.purchaseId))
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>
                          <Moment format="DD/MM/yyyy">{item.createdAt}</Moment>
                        </td>
                        <td>{item.voucher}</td>
                        <td className="text-center">
                          <p>
                            {item.participantId && item.participantName
                              ? item.participantName
                              : 'LIVRE'}
                          </p>
                          <p>
                            {item.participantId && item.participantName ? (
                              <Moment format="DD/MM/yyyy">{item.updatedAt}</Moment>
                            ) : (
                              ''
                            )}
                          </p>
                        </td>
                        <td className="text-right">
                          {!item.participantId && !item.isOwner ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-md btn-blue btn-block m-1"
                                onClick={() =>
                                  ownerTicket({
                                    ticketId: item.ticketId,
                                    eventId: item.eventId,
                                    voucher: item.voucher,
                                  })
                                }
                              >
                                Utilizar Ingresso
                              </button>
                              <button
                                className="btn btn-md btn-blue btn-block m-1"
                                data-toggle="modal"
                                data-target="#ticket-modal"
                                onClick={() =>
                                  setExternalVoucher({
                                    ticketId: item.ticketId,
                                    eventId: item.eventId,
                                    voucher: item.voucher,
                                  })
                                }
                              >
                                Nomear Ingresso
                              </button>
                            </>
                          ) : (
                            item.participantId &&
                            item.isOwner && (
                              <button
                                className="btn btn-md btn-blue btn-block m-1"
                                onClick={() =>
                                  goManagerEvent(
                                    item.ticketId,
                                    item.voucher,
                                    item.eventId
                                  )
                                }
                              >
                                Acessar
                              </button>
                            )
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </>
          </tbody>
        </table>

        <div className="d-flex justify-content-end mt-4">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tickets ? tickets.length : 0}
            paginate={paginate}
          />
        </div>

        {(!tickets || tickets.length <= 0) && !isPurchase && (
          <form
            className="mt-4"
            onSubmit={(e) => handleApplyVoucher(e, inviteVoucher)}
          >
            <div className="row no-gutters mt-2">
              <div className="col-md-4 mr-2">
                <div className="form-label-group">
                  <label htmlFor="ticketId">
                    <strong>Sou convidado e tenho Id do Ingresso</strong>
                  </label>
                  <input
                    type="text"
                    id="ticketId"
                    name="ticketId"
                    className="form-control"
                    required
                    placeholder="ID do ingresso"
                    value={inviteVoucher}
                    maxLength={36}
                    onChange={(e) =>
                      setInviteVoucher(maskInviteVoucher(e.target.value))
                    }
                  />
                </div>
              </div>

              <div className="col-md-3 mt-2">
                <button type="submit" className="btn btn-lg btn-blue mt-4">
                  Acessar Evento
                </button>
              </div>
            </div>
          </form>
        )}
      </div>

      <div className="modal fade" id="ticket-modal" tabindex="-1" aria-hidden="true">
        <div
          className="modal-dialog"
          id="ticket-modal-dialog"
          style={{ marginLeft: 'calc(50% - 325px)' }}
        >
          <div
            className="modal-content"
            id="ticket-modal-content"
            style={{ width: '650px' }}
          >
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3>Nomear Ingresso</h3>
              <p>
                Informe o nome e o e-mail do convidado a receber o ingresso. Uma
                conta será criada e o convidado receberá os dados para o login em seu
                e-mail.
              </p>
              <p>
                Caso o e-mail informado esteja associado a uma conta existente, o
                ingresso será atribuído automaticamente.
              </p>
              <form
                onSubmit={(e) =>
                  handleExternalVoucher(externalName, externalEmail, externalVoucher)
                }
              >
                <div className="row" style={{ alignItems: 'center' }}>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label for="nameInput">Nome</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={externalName}
                        onChange={(e) => setExternalName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label for="inputEmail">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        value={externalEmail}
                        onChange={(e) => setExternalEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <button
                      type="submit"
                      className="btn btn-md btn-blue btn-block mt-2"
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ListTickets);
