import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Picker from 'emoji-picker-react';
import { animateScroll } from 'react-scroll';
import NotificationServices from '../../services/NotificationServices';
import { useDispatch } from 'react-redux';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from 'react-modal';

const Chat = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [messageList, setMessageList] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [openEmojiSelect, setOpenEmojiSelect] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [moderatorOn, setModeratorOn] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [changeFile, setChangeFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [currentModalData, setCurrentModalData] = useState(null);
  const [acceptImg, setAcceptImg] = useState(false);
  const [cropperActive, setCropperActive] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [fileName, setFileName] = useState(null);

  const fileRef = useRef();

  const resetFile = () => {
    fileRef.current.value = '';
  };

  const cropperRef = useRef(null);

  const onCrop = () => {
    setCropperActive(true);
  };

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const uploadCrop = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    const cropperResponse = cropper.getCroppedCanvas().toDataURL();

    urltoFile(cropperResponse, fileName, fileType).then(async (croppedFile) => {
      const croppedImage = await sendImg(fileType, croppedFile);

      setFileUrl(croppedImage);
      resetFile();
      setCropperActive(false);
    });
  };

  const closeModal = async () => {
    setFileUrl('');
    setChangeFile('');
    setFileName(null);
    setFileType(null);
    setCurrentMessage('');
    resetFile();

    setCropperActive(false);
  };

  const onEmojiClick = (event, emojiObject) => {
    setCurrentMessage(currentMessage + emojiObject.emoji);
  };

  const onOpenEmoji = () => {
    setOpenEmojiSelect(!openEmojiSelect);
  };

  const changedFile = (event) => {
    event.preventDefault();
    let files;

    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    if (files.length > 0 && files[0].type && files[0].name) {
      setFileType(files[0].type);
      setFileName(files[0].name);

      const reader = new FileReader();
      reader.onload = () => {
        setChangeFile(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setCropperActive(true);
    }
  };

  const sendImg = async (fileType, file) => {
    const urlFile = await dispatch(NotificationServices.sendImg(fileType, file));

    return urlFile;
  };

  const deleteMessage = (index) => {
    const deletedMsg = {
      room: 'socket_room_' + location.pathname.replace('/room/', ''),
      messageIndex: index,
      token: auth.token,
      interatividade: 'CHAT',
    };
    window.socket.emit('delete_message', deletedMsg);
  };

  const approveMessage = (item, e) => {
    e.currentTarget.parentNode.style.display = 'none';
    const approvedObj = {
      token: auth.token,
      messageId: item._id,
      room: 'socket_room_' + location.pathname.replace('/room/', ''),
      interatividade: 'CHAT',
    };
    window.socket.emit('approve_message', approvedObj);
  };

  const sendMessage = () => {
    if (fileUrl !== '' || currentMessage !== '') {
      const newMsg = {
        room: 'socket_room_' + location.pathname.replace('/room/', ''),
        message: currentMessage,
        username: auth.username,
        email: auth.email,
        sender_id: auth.participantId,
        avatar: auth.avatar,
        photo: fileUrl,
        token: auth.token,
        approved: props.dados.moderatorEmail === auth.email,
      };

      window.socket.emit('send_chat_message', newMsg);

      setMessageList((prevState) => [...prevState, newMsg]);
      setFileUrl('');
      setChangeFile('');
      setFileName(null);
      setFileType(null);
    }
    setCurrentMessage('');
  };

  const transformMessage = (messages) => {
    const transformedMessages = [];
    for (let index = 0; index < messages.length; index++) {
      const item = messages[index];

      const transformedMessage = {
        message: item.message,
        username: item.username,
        email: item.email,
        avatar: item.avatar,
        photo: item.photo,
        _id: item._id,
        approved: item.approved,
        participantId: item.participantId,
      };
      transformedMessages.push(transformedMessage);
    }
    return transformedMessages;
  };

  const linkify = (text) => {
    const urlRegex = /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
    return text.replace(urlRegex, (url) => {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return (
        '<a target="_blank" href="' + url + '" title="' + url + '">' + url + '</a>'
      );
    });
  };

  const fetchData = useCallback(async () => {
    const roomFairId = location.pathname.replace('/room/', '');
    const messagesHistory = await dispatch(
      NotificationServices.recoverAllMessages(roomFairId, 'CHAT')
    );

    const transformedMsgs = transformMessage(messagesHistory);
    if (props.dados.acceptImg === '1') {
      setAcceptImg(true);
    }
    setMessageList(transformedMsgs);
  }, [dispatch, location.pathname, props.dados.acceptImg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const autoScrollFunc = useCallback(() => {
    if (autoScroll) {
      animateScroll.scrollToBottom({
        containerId: 'chatHolder',
        smooth: true,
        duration: 200,
      });
    }
    setModeratorOn(props.dados.moderatorOn);
    if (props.dados.moderatorOn) {
      setIsModerator(props.dados.moderatorEmail === auth.email);
    }
  }, [auth.email, autoScroll, props.dados.moderatorEmail, props.dados.moderatorOn]);

  useEffect(() => {
    autoScrollFunc();
  });

  const handler = (data) => {
    if (auth.participantId !== parseInt(data.participantId)) {
      setMessageList((prevState) => [...prevState, data]);
    }
  };

  useEffect(() => {
    if (window.socket) {
      window.socket.on('received_chat_message', handler);
      window.socket.on('updateAll', fetchData);
      return () => {
        window.socket.off('received_chat_message', handler);
        window.socket.off('updateAll', fetchData);
      };
    }
  });

  return window.socket ? (
    <>
      <div className="row mx-0" style={{ height: '100%' }}>
        <div
          className="chat-container"
          style={{ width: '100%', height: '100%', background: '#F9FEFF' }}
        >
          <div className="chat-feed" id="chatHolder">
            <ul className="chat-msgList">
              {messageList.map((item, index) => {
                if (
                  (moderatorOn && isModerator) ||
                  item.approved ||
                  auth.participantId === parseInt(item.sender_id) ||
                  !moderatorOn
                ) {
                  return (
                    <li key={'msg' + index}>
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          float: 'left',
                          marginRight: 10,
                          backgroundImage: `url(${item.avatar})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          borderRadius: '50%',
                        }}
                      ></div>

                      <div className="msgContainer">
                        <div>
                          <strong>{item.username}</strong>
                          {moderatorOn &&
                          props.dados.moderatorEmail === item.email ? (
                            <div
                              className="ml-1"
                              style={{
                                color: 'white',
                                background: 'red',
                                padding: '2.5px',
                                borderRadius: '10%',
                                display: 'inline-block',
                                fontSize: '10px',
                                fontWeight: '800',
                              }}
                            >
                              <span>Moderador</span>
                            </div>
                          ) : null}
                        </div>
                        {item.photo ? (
                          <div className="row">
                            <div className="col-xl-8 col-md-6 col-sm-6 col-10">
                              <img
                                onLoad={autoScrollFunc}
                                alt={
                                  item.message !== '' ? item.message : item.username
                                }
                                src={item.photo}
                                style={{ width: 'inherit' }}
                                className="embed-responsive-item"
                                type="button"
                                data-toggle="modal"
                                data-target="#photoModal"
                                onClick={() => {
                                  setCurrentModalData(item);
                                }}
                              />
                            </div>
                          </div>
                        ) : null}

                        <p
                          dangerouslySetInnerHTML={{ __html: linkify(item.message) }}
                        ></p>

                        {moderatorOn && isModerator ? (
                          <>
                            <div
                              style={{
                                justifyContent: 'flex-end',
                                backgroudColor: '#757575',
                              }}
                              className="row my-2"
                            >
                              <div className="col-2 col-lg-1">
                                <button
                                  onClick={() => {
                                    deleteMessage(index);
                                  }}
                                  className="btn-danger btn btn-sm delete-btn"
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </div>
                              {!item.approved ? (
                                <div className="col-2 col-lg-1">
                                  <button
                                    onClick={(e) => {
                                      approveMessage(item, e);
                                    }}
                                    className="btn-success btn btn-sm"
                                  >
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>

          {acceptImg ? (
            <div className="input-group" style={{ position: 'unset' }}>
              <div className="custom-file">
                <input
                  ref={fileRef}
                  type="file"
                  className="custom-file-input"
                  onChange={(e) => {
                    changedFile(e);
                  }}
                  id="inputGroupFile01"
                  aria-describedby="inputGroupFileAddon01"
                />
                <label
                  className="custom-file-label"
                  htmlFor="inputGroupFile01"
                  style={{
                    background: '#F9FEFF',
                    borderRadius: '0',
                    padding: '0.375rem 6px',
                  }}
                >
                  <i className="fa fa-picture-o mr-3" aria-hidden="true"></i>
                  {fileName ? fileName : 'Escolher Imagem'}
                </label>
              </div>
            </div>
          ) : null}

          <div style={{ position: 'relative' }}>
            <div id="pickerHolder" className={!openEmojiSelect ? 'hide' : null}>
              <Picker onEmojiClick={onEmojiClick} />
            </div>
            <div className="row chat-msgBox chatInputHolder p-0">
              <span
                id="openEmojis"
                onClick={onOpenEmoji}
                title="Clique para abrir ou fechar os emojis."
                role="img"
                aria-label="Emojis"
              >
                😃
              </span>

              <input
                id="textMessageInput"
                className="form-control custom-border"
                maxLength={500}
                value={currentMessage}
                onChange={(e) => {
                  setCurrentMessage(e.target.value);
                }}
                type="text"
                onKeyUp={(e) =>
                  e.keyCode === 13 && sendMessage() && e.preventDefault()
                }
                style={{
                  background: '#F9FEFF',
                  border: 'none',
                  borderBottom: '1px solid  #ced4da',
                  borderRadius: '0',
                }}
                placeholder="Digite sua Mensagem"
              />

              <div
                className="btn btn-primary btn-event-send-msg"
                onClick={sendMessage}
              >
                <i className="fa fa-chevron-right" />
              </div>
              <div className="row no-gutters mt-2 pr-2 scroll-row">
                <div className="form-check form-switch" id="autoScrollCheckHolder">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={autoScroll}
                    onChange={() => {
                      setAutoScroll(!autoScroll);
                    }}
                    id="autoScrollCheck"
                  />
                  <label
                    className="form-check-label text-end"
                    htmlFor="autoScrollCheck"
                  >
                    Rolagem automática
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cropperActive && acceptImg ? (
        <Modal
          ariaHideApp={false}
          id="croppModal"
          isOpen={cropperActive}
          onRequestClose={closeModal}
        >
          <Cropper
            src={changeFile}
            style={{ height: 400, width: '100%', zIndex: 10, textAlign: 'center' }}
            // Cropper.js options
            initialAspectRatio={1 / 1}
            aspectRatio={1 / 1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            viewMode={2}
          />
          <p style={{ paddingTop: '10px' }}>
            <strong>
              {' '}
              Posicione a moldura{' '}
              <i
                style={{ paddingLeft: '5px' }}
                className="fa fa-mouse-pointer"
                aria-hidden="true"
              ></i>
            </strong>{' '}
            para recortar sua imagem{' '}
          </p>
          <p style={{ paddingTop: '5px' }}>
            Utilize o <strong>Scroll</strong> para aumentar ou diminuir o zoom
          </p>
          <button onClick={closeModal} className="btn btn-danger mr-2">
            Cancelar
          </button>
          <button
            onClick={uploadCrop}
            style={{ zIndex: 10 }}
            className="btn btn-primary"
          >
            Recortar Imagem
          </button>
        </Modal>
      ) : null}
      {currentModalData ? (
        <div
          className="modal fade"
          id="photoModal"
          tabIndex="-1"
          aria-labelledby="photoModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content qaModal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="photoModalLabel">
                  Foto de {currentModalData.username}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  alt={
                    currentModalData.message !== ''
                      ? currentModalData.message
                      : currentModalData.username
                  }
                  src={currentModalData.photo}
                  className="qaPhotoModal"
                />
                <p style={{ marginTop: 40, maxWidth: 600, wordBreak: 'break-word' }}>
                  {currentModalData.message}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
};

export default Chat;
