import React, { useEffect, useState } from 'react';
import ListRoomFair from '../components/events/ListRoomFair';
import { useDispatch, useSelector } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';
import TemplateApp from '../components/template/TemplateApp';
import EventsServices from '../services/EventsServices';
import { useHistory } from 'react-router-dom';

const RoomsList = (props) => {
  const auth = useSelector((state) => state.auth);
  const email = auth && auth.email ? auth.email : null;
  const [managerEvents, setManagerEvents] = useState(null);
  const ticketId = useSelector((state) => state.navigator.ticketId);
  const [roomsFair, setRoomsFair] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (
        !auth.tickets.some((ticket) => ticket.participantId === auth.participantId)
      ) {
        history.push('/home');
        return;
      }

      const result = await dispatch(
        EventsServices.findRoomFair({
          eventId: props.eventId,
          ticketId,
          email,
        })
      );

      if (result && result.roomsFair && result.roomsFair.length > 0) {
        const rooms = result.roomsFair.filter(
          (item) => item.typeEvent === 'access_room' && !item.ocultar_sala
        );
        setRoomsFair(rooms ? rooms : []);
        setManagerEvents(result.managerEvents);
      }
    };

    if (!props.preview && !managerEvents && roomsFair.length <= 0 && email) {
      fetchData().catch();
    }
  }, [
    dispatch,
    props,
    managerEvents,
    email,
    ticketId,
    roomsFair,
    auth.tickets,
    auth.participantId,
    history,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(
        EventsServices.findEventsPreview(props.eventId, history)
      );
      if (result && result.roomsFair && result.roomsFair.length > 0) {
        const rooms = result.roomsFair.filter(
          (item) => item.typeEvent === 'access_room' && !item.ocultar_sala
        );
        setRoomsFair(rooms ? rooms : []);
        setManagerEvents(result.managerEvents);
      }
    };

    if (props.preview && roomsFair.length <= 0) {
      fetchData().catch();
    }
  }, [dispatch, ticketId, props, history, roomsFair]);

  return (
    <TemplateApp>
      <div
        className="container-fluid custom-carroussel"
        style={{
          width: '100%',
          minHeight: 'calc(90vh - 32px)',
          backgroundImage:
            managerEvents && managerEvents.urlLobbyRoom
              ? `url("${encodeURI(managerEvents.urlLobbyRoom)}")`
              : null,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {managerEvents && managerEvents.urlLobbyRoom
          ? console.log(managerEvents.urlLobbyRoom)
          : null}
        <div className="row no-gutters">
          <div className="col-lg-12 col-md-12 m-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col carousel-center">
                  {roomsFair.length > 0 && (
                    <ListRoomFair roomsFair={roomsFair} preview={props.preview} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateApp>
  );
};

export default RoomsList;
