export const TOKEN_KEY = '@airbnb-Token';

export const isAuthenticated = () => {
  const authUser = localStorage.getItem(TOKEN_KEY);
  return authUser !== null && JSON.parse(authUser).isLoggedIn === true;
};

export const getToken = () => {
  const authUser = getAuth();
  return authUser && authUser.token ? authUser.token : null;
};

export const getAuth = () =>
  isAuthenticated() ? JSON.parse(localStorage.getItem(TOKEN_KEY)) : null;

export const updatePurchase = (data) => {
  const authUser = getAuth();

  if (!authUser) {
    return;
  }

  authUser.purchase.push(data.purchase);
  if (authUser.tickets && authUser.tickets.length > 0) {
    authUser.tickets = authUser.tickets.concat(data.tickets);
  } else {
    authUser.tickets = data.tickets;
  }

  if (authUser.tickets && authUser.tickets.length > 0) {
    authUser.tickets = authUser.tickets.sort((a, b) => {
      if (a.updatedAt && b.updatedAt) {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA < dateB ? 1 : -1;
      }
      return 1;
    });
  }

  if (data.managerEvents) {
    authUser.managerEvents = data.managerEvents;
  }

  setAuth(authUser);

  return authUser.tickets;
};

export const updatePurchaseFullDiscount = (data) => {
  const authUser = getAuth();

  if (!authUser) {
    return;
  }

  authUser.purchase.push(data);
  console.log('AUTHUSER', authUser, 'TICKETS', authUser.tickets);
  if (authUser && authUser.tickets && authUser.tickets.length > 0) {
    authUser.tickets = authUser.tickets.concat(data.tickets);
  } else {
    console.log('DATA', data, 'TICKETS', data.purchaseDetail);
    authUser.tickets = data.purchaseDetail;
  }

  if (authUser.tickets && authUser.tickets.length > 0) {
    authUser.tickets = authUser.tickets.sort((a, b) => {
      if (a.updatedAt && b.updatedAt) {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA < dateB ? 1 : -1;
      }
      return 1;
    });
  }

  if (data.managerEvents) {
    authUser.managerEvents = data.managerEvents;
  }
  console.log('authUser', authUser);
  setAuth(authUser);

  return authUser.tickets;
};

export const updateOwnerTickets = (ownerTickets, shouldSet) => {
  const authUser = getAuth();

  if (!authUser) {
    return;
  }

  authUser.purchase = ownerTickets.purchase;

  if (ownerTickets.tickets && ownerTickets.tickets.length > 0) {
    authUser.tickets = ownerTickets.tickets.sort((a, b) => {
      if (a.updatedAt && b.updatedAt) {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA < dateB ? 1 : -1;
      }
      return 1;
    });
  }

  authUser.managerEvents = ownerTickets.managerEvents;

  if (ownerTickets.roomsFair && ownerTickets.roomsFair.length > 0) {
    authUser.roomsFair = ownerTickets.roomsFair;
  }

  if (shouldSet) {
    setAuth(authUser);
  }

  return authUser;
};

export const updateRoomFair = (roomsFair) => {
  const authUser = getAuth();

  if (!authUser) {
    return;
  }

  authUser.roomsFair = roomsFair;

  setAuth(authUser);

  return { roomsFair: authUser.roomsFair, managerEvents: authUser.managerEvents };
};

export const updateProfile = (data) => {
  if (!data) {
    return;
  }

  const authUser = getAuth();

  if (!authUser) {
    return;
  }

  if (data.avatar && data.avatar !== '') {
    authUser.avatar = data.avatar;
  }

  authUser.username = data.name;

  setAuth(authUser);
};

export const setAuth = (authUser) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authUser));
};

export const removeAuth = () => {
  localStorage.removeItem(TOKEN_KEY);
};
