import React, { memo } from 'react';
import InputQuantity from './InputQuantity';
import { maskCurrency } from '../../utils/MaskInput';
import EventsServices from '../../services/EventsServices';
import './CartItemList.scss';

const CartItemList = ({ purchaseItems, onChangeValues }) => {
  const decrementCount = (item) => {
    if (item.quantity > 0) {
      onChangeValues(purchaseItems, item.ticketId, item.quantity - 1);
    }
  };

  const incrementCount = (item) => {
    onChangeValues(purchaseItems, item.ticketId, item.quantity + 1);
  };

  const removeItemCart = (item) => {
    onChangeValues(purchaseItems, item.ticketId, 0, true);
  };

  return (
    <>
      <div className="container item-list-container">
        <div className="row">
          <div className="border-light col-md-12 pr-0 pl-0">
            <table className="table bg-white table-responsive-md table-responsive-sm mt-0">
              <thead className="thead-dark">
                <tr>
                  <th>Nº</th>
                  <th>Ingressos</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                  <th>Total</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <>
                  {purchaseItems &&
                    purchaseItems.length > 0 &&
                    purchaseItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="font-weight-bold">{item.ticketId}</td>
                          <td className="font-weight-bold">{item.description}</td>
                          <td style={{ width: 200 }}>
                            <InputQuantity
                              disabled={!EventsServices.isTicketAvailable(item)}
                              cart={purchaseItems}
                              item={item}
                              decrementCount={decrementCount}
                              incrementCount={incrementCount}
                              onChangeValues={onChangeValues}
                              floatDirection="flot-left"
                            />
                          </td>
                          <td>R$ {maskCurrency(item.value)}</td>
                          <td className="total-td">{maskCurrency(item.total)}</td>
                          <td className="text-center">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              title="Remove item do carrinho"
                              onClick={() => removeItemCart(item)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CartItemList);
