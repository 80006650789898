import React, { memo, useEffect, useState } from 'react';
import Clappr from 'clappr';
import ContextMenuPlugin from 'clappr-context-menu-plugin';
import HlsjsPlayback from '@clappr/hlsjs-playback';
import LevelSelector from '@c3voc/clappr-level-selector';
import AudioTrackSelector from '@c3voc/clappr-audio-track-selector';
import './VideoRoom.css';
import Api from '../../services/config/Api';
import { isMobile } from 'react-device-detect';
import Zoom from '../zoom';
import '@whereby.com/browser-sdk';

//ClapprPIPPlugin.PipButton, ClapprPIPPlugin.PipPlugin  ClapprPip.PipButton, ClapprPip.PipPlugin HlsjsPlayback

const VideoRoom = ({
  streamUrl,
  index,
  type,
  hackathon_data,
  auth,
  roomId,
  roomName,
  setLoaded,
  tabsAtivas,
}) => {
  const [player, setPlayer] = useState(null);
  const [wbSources, setwbSources] = useState(null);

  const mount = () => {
    if (player) {
      if (streamUrl !== null && !streamUrl.startsWith('<iframe')) {
        player.destroy();
      }
      setPlayer(null);
    }

    if (type === 'TRANSMISSAO') {
      if (streamUrl !== null && !streamUrl.startsWith('<iframe')) {
        setPlayer(
          new Clappr.Player({
            source: streamUrl,
            parentId: '#player',
            baseUrl: '/static/fonts',
            poster: '',
            width: '100%',
            height: '100%',
            plugins: [
              HlsjsPlayback,
              LevelSelector,
              AudioTrackSelector,
              ContextMenuPlugin,
            ],
            hlsUseNextLevel: false,
            hlsMinimumDvrSize: 60,
            hlsRecoverAttempts: 16,
            autoPlay: true,
            hideMediaControl: false,
            contextMenu: {
              menuItems: ['loop'],
              customStyle: {
                container: {
                  display: 'hide',
                },
              },
            },
            events: {
              onPlay: function () {
                setLoaded(true);
              },
            },
            levelSelectorConfig: {
              //Optional : custom labels
              title: 'Qualidade',
              // labels: {
              //   0: 'Mínima',
              //   1: 'Baixa',
              //   2: 'Média',
              //   3: 'HD',
              //   4: 'Full HD',
              // },
            },
            playback: {
              extrapolatedWindowNumSegments: 2,
              triggerFatalErrorOnResourceDenied: false,
            },
            hlsjsConfig: {
              autoStartLoad: true,
              enableWebVTT: true,
              startPosition: -1,
              debug: false,
              capLevelOnFPSDrop: false,
              capLevelToPlayerSize: false,
              defaultAudioCodec: undefined,
              initialLiveManifestSize: 1,
              maxBufferLength: 30,
              maxMaxBufferLength: 600,
              maxBufferSize: 60 * 1000 * 1000,
              maxBufferHole: 0.5,
              lowBufferWatchdogPeriod: 0.5,
              highBufferWatchdogPeriod: 3,
              nudgeOffset: 0.1,
              nudgeMaxRetry: 3,
              maxFragLookUpTolerance: 0.25,
              liveSyncDurationCount: 3,
              liveMaxLatencyDurationCount: Infinity,
              liveDurationInfinity: false,
              liveBackBufferLength: Infinity,
              enableWorker: true,
              enableSoftwareAES: true,
              manifestLoadingTimeOut: 10000,
              manifestLoadingMaxRetry: 1,
              manifestLoadingRetryDelay: 1000,
              manifestLoadingMaxRetryTimeout: 64000,
              startLevel: undefined,
              levelLoadingTimeOut: 10000,
              levelLoadingMaxRetry: 4,
              levelLoadingRetryDelay: 1000,
              levelLoadingMaxRetryTimeout: 64000,
              fragLoadingTimeOut: 20000,
              fragLoadingMaxRetry: 6,
              fragLoadingRetryDelay: 1000,
              fragLoadingMaxRetryTimeout: 64000,
              startFragPrefetch: false,
              testBandwidth: true,
              fpsDroppedMonitoringPeriod: 5000,
              fpsDroppedMonitoringThreshold: 0.2,
              appendErrorMaxRetry: 3,
              enableCEA708Captions: true,
              stretchShortVideoTrack: false,
              maxAudioFramesDrift: 1,
              forceKeyFrameOnDiscontinuity: true,
              abrEwmaFastLive: 3.0,
              abrEwmaSlowLive: 9.0,
              abrEwmaFastVoD: 3.0,
              abrEwmaSlowVoD: 9.0,
              abrEwmaDefaultEstimate: 500000,
              abrBandWidthFactor: 0.95,
              abrBandWidthUpFactor: 0.7,
              abrMaxWithRealBitrate: false,
              maxStarvationDelay: 4,
              maxLoadingDelay: 4,
              minAutoBitrate: 0,
              emeEnabled: false,
              widevineLicenseUrl: undefined,
              drmSystemOptions: {},
            },
          })
        );
      }
    }

    if (type === 'WEBINAR') {
      Api.instance
        .get('/events/' + auth.eventId + '/webinar/' + roomId)
        .then((data) => {
          const domain = '8x8.vc';
          const options = {
            configOverwrite: {
              toolbarButtons: [
                'microphone',
                'camera',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'hangup',
                'profile',
                'chat',
                'recording',
                'livestreaming',
                'etherpad',
                'participants-pane',
                'sharedvideo',
                'shareaudio',
                'settings',
                'raisehand',
                'videoquality',
                'filmstrip',
                'stats',
                'shortcuts',
                'tileview',
                'select-background',
                'download',
                'mute-everyone',
                'mute-video-everyone',
                'security',
              ],
              defaultLanguage: 'ptBR',
              disableInviteFunctions: true,
              prejoinPageEnabled: true,
              requireDisplayName: true,
              hideConferenceSubject: false,
              subject: roomName,
              enableLipSync: true,
              useHostPageLocalStorage: false,
              resolution: 720,

              brandingRoomAlias:
                window.location.host.replace('/', '') + window.location.pathname,
            },
            interfaceConfigOverwrite: {
              DISABLE_TRANSCRIPTION_SUBTITLES: true,
              HIDE_INVITE_MORE_HEADER: true,
              LANG_DETECTION: true,
              SHOW_CHROME_EXTENSION_BANNER: false,
            },
            roomName:
              process.env.REACT_APP_WEBINAR_APP_ID +
              '/event' +
              auth.eventId +
              'room' +
              roomId,
            width: '100%',
            height: '100%',
            parentNode: document.querySelector('#webinar'),
            jwt: data.data.payload,
          };

          if (window.JitsiMeetExternalAPI) {
            let meetApi = new window.JitsiMeetExternalAPI(domain, options);
            meetApi.on('readyToClose', () => {
              meetApi.dispose();
              meetApi = new window.JitsiMeetExternalAPI(domain, options);
            });
          }
        });
    }

    if (type === 'HACKATHON') {
      Api.instance
        .get('/events/' + auth.eventId + '/hackathon/' + roomId)
        .then((data) => {
          if (data.data.payload.isModerador === 'true') {
            setwbSources(data.data.payload.hostRoomUrl);
          } else {
            setwbSources(data.data.payload.roomUrl);
          }
        });
    }
  };

  useEffect(mount, [streamUrl, index, type, hackathon_data]);

  if (type === 'WEBINAR') {
    return <div id="webinar"></div>;
  }

  if (type === 'TRANSMISSAO') {
    let playerWidth;
    const checkIfIframe = () => {
      return streamUrl !== null && streamUrl.startsWith('<iframe') ? true : false;
    };

    if (!checkIfIframe()) {
      playerWidth = tabsAtivas ? '95%' : '75%';
    }

    return (
      <div
        id={checkIfIframe() ? 'transmissao' : 'player'}
        style={{ width: playerWidth ? playerWidth : '100%' }}
        className="embed-responsive-item"
        dangerouslySetInnerHTML={{
          __html:
            streamUrl !== null && streamUrl.startsWith('<iframe') ? streamUrl : null,
        }}
      ></div>
    );
  }

  if (type === 'HACKATHON') {
    if (!isMobile) {
      return wbSources ? (
        <>
          <style>{`
         .avatarWrapper-1_mI { display: none; }
         `}</style>
          <div id="hackathon" style={{ width: '90%' }}>
            <whereby-embed
              room={wbSources}
              displayName={auth.username}
              style={{ height: '100%' }}
            />
          </div>
        </>
      ) : null;
    } else {
      return wbSources ? (
        <>
          <style>{`
        .avatarWrapper-1_mI { display: none; }
        `}</style>
          <div id="hackathon" style={{ width: '90%' }}>
            <whereby-embed
              room={wbSources}
              displayName={auth.username}
              style={{ height: '100%' }}
            />
          </div>
        </>
      ) : null;
    }
  }

  if (type === 'ZOOM') {
    return (
      <Zoom
        roomId={roomId}
        eventId={auth.eventId}
        style={{ width: '90%', height: '100%' }}
      />
    );
  }
};

export default memo(VideoRoom);
