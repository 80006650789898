import React from 'react';
import 'moment/locale/pt-br';
import AgendaItem from './agendaItem';
require('./styles.css');

const MainPreview = (props) => {
  if (props.listData && props.listData !== null) {
    return (
      <ul id="agendaList" className="mb-0">
        {props.listData.map((item) => {
          return <AgendaItem data={item} key={item.agendaId} />;
        })}
      </ul>
    );
  } else {
    return <></>;
  }
};

export default MainPreview;
