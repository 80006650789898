import React, { useEffect } from 'react';

export default function Calendly({ url }) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="App" id="cal-com-holder" style={{ overflow: 'hidden' }}>
      <div
        className="calendly-inline-widget"
        data-url={url}
        style={{ height: '100vh' }}
      ></div>
    </div>
  );
}
