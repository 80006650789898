import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CheckoutStripeSuccess = () => {
  return (
    <div class="container-fluid p-0 m-0">
      <div
        class="alert alert-success text-center d-flex"
        role="alert"
        style={{
          height: '100vh',
          width: '100vw',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          className="mt-2"
          style={{
            border: '1px solid white',
            fontSize: '40px',
            padding: '10px 20px',
            borderRadius: '100%',
          }}
        >
          <i className="fa fa-check alert-success check-icon-animate" />
        </div>
        <h4 class="font-weight-bold">Compra efetuada com sucesso!</h4>
        <p>Acesse o evento novamente para aplicar seu ingresso.</p>
        <Link to={'/?logout=true'} className="btn btn-success btn-lg mt-4">
          Acessar Evento
        </Link>
      </div>
    </div>
  );
};

export default CheckoutStripeSuccess;
