import './Footer.css';
import React from 'react';

const Footer = (props) => {
  if (window.location.pathname.includes('/ds/')) {
    return null;
  }

  return (
    <footer className="footer mt-5">
      <div>Selecione o idioma:</div>
      &nbsp;
      <div id="google_translate_element"></div>
    </footer>
  );
};

export default Footer;
