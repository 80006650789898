import React, {useEffect} from 'react';

const SlackChatlio = (chatId) => {

    const scriptBody = slackId => {
        let scriptText = 'window._chatlio = window._chatlio||[];!function(){ var t=document.getElementById("chatlio-widget-embed");if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},i=["configure","identify","track","show","hide","isShown","isOnline", "page", "open", "showOrHide"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,n.setAttribute("data-embed-version","2.3");n.setAttribute("data-widget-options", \'{"embedInline": true}\');n.setAttribute("data-widget-id", "'+slackId.chatId+'");c.parentNode.insertBefore(n,c);}();';
        return scriptText;
    };
    
    useEffect(() => {

        document.getElementById('alertHolder').classList.add("hide");

        const script = document.createElement("script");
        script.id = 'slackScript';
        script.type = "text/javascript";
        script.text = scriptBody(chatId);
        script.async = true;
        document.body.appendChild(script);

    });

    return (
        <div id="chatlioWidgetPlaceholder"></div>
    )

};

export default SlackChatlio;
