import React, { useEffect, useState, useCallback } from 'react';
import { animateScroll } from 'react-scroll';
import NotificationServices from '../../services/NotificationServices';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { QRCodeSVG } from 'qrcode.react';

const ChatDs = (props) => {
  const dispatch = useDispatch();

  const [messageList, setMessageList] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [hasMessages, setHasMessages] = useState(true);

  const settings = {
    pauseOnHover: false,
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const fetchData = useCallback(async () => {
    const data = await dispatch(
      NotificationServices.recoverAllMessagesDs(
        props.roomFairId,
        props.token,
        props.eventId
      )
    );

    setEventData(data.eventData);
    setMessageList(data.messages);
  }, [dispatch, props.token, props.eventId, props.roomFairId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const autoScrollFunc = useCallback(() => {
    animateScroll.scrollToBottom({
      containerId: 'htmlId',
      smooth: true,
      duration: 3000,
    });
  }, []);

  useEffect(() => {
    autoScrollFunc();
  });

  const handler = (data) => {
    setMessageList((prevState) => [...prevState, data]);
  };

  useEffect(() => {
    if (window.socket) {
      window.socket.on('received_chat_message', handler);
      return () => {
        window.socket.off('received_chat_message', handler);
      };
    }
  });

  useEffect(() => {
    if (messageList.length > 0) {
      setHasMessages(false);
    }
  }, [messageList]);

  return window.socket && eventData ? (
    <>
      {hasMessages ? (
        <Slider {...settings}>
          <div className="slide-container d-flex no-message">
            <div className="text-container">
              <p>Aguardando Mensagens</p>
            </div>
          </div>
        </Slider>
      ) : (
        <Slider {...settings}>
          {messageList.map((item, index) => {
            return (
              <div
                key={'msg' + index}
                className={
                  'slide-container d-flex' +
                  (item.photo !== '' ? ' hasPhoto' : item.photo)
                }
              >
                <div className="text-container">
                  <h1 className="sender">
                    <img src={item.avatar} alt="Remetente" />
                    {item.username}
                  </h1>
                  <p>{item.message}</p>
                </div>
                {item.photo !== '' ? (
                  <div className="photo-container">
                    <img src={item.photo} alt="Imagem" />
                  </div>
                ) : null}
              </div>
            );
          })}
        </Slider>
      )}
      <div className="bottom-container container-fluid">
        <div className="row">
          <div
            className="col-sm-3 logo-funeraria bottom-section"
            style={{ backgroundImage: 'url(' + eventData.event.urlLogo + ')' }}
          ></div>
          <div className="col-sm-5 nome-evento bottom-section d-flex">
            <div>
              <img src={eventData.room.urlIcon} alt={eventData.room.roomFairName} />
            </div>
            <h2>{eventData.room.roomFairName}</h2>
          </div>
          <div className="col-sm-4 qr-code bottom-section d-flex">
            <p className="pt-2">
              <strong>Deixe sua mensagem</strong>
            </p>

            <QRCodeSVG className="qr-ds" value={eventData.room.og_url} />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ChatDs;
