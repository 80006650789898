import { isMobile, isTablet } from 'react-device-detect';

export const getImageHeight = () => {
  if (isTablet) {
    return '400px';
  }
  if (isMobile) {
    return '200px';
  }
  return '640px';
};

export const getVideoHeightRoom = () => {
  if (isTablet) {
    return '300px';
  }
  if (isMobile) {
    return '200px';
  }
  return '540px';
};

export const getVideoHeightFair = () => {
  if (isTablet) {
    return '300px';
  }
  if (isMobile) {
    return '200px';
  }
  return '630px';
};

export const getScreenResizeCarousel = (roomsFair) => {
  if (isTablet) {
    return 2;
  }
  if (isMobile) {
    return 1;
  }

  if (roomsFair && roomsFair.length <= 3) {
    return roomsFair.length;
  }

  return 4;
};
