import axios from 'axios';
import * as http from 'http';


const httpAgent = new http.Agent({
    keepAlive: true,
    timeout: 5 * 1000,
});

const instanceApiUploadS3 = axios.create({
    httpAgent,
    headers: {
        'Content-Type': 'image/*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
    },
});


export default instanceApiUploadS3;