import React, {useEffect} from 'react';
import './Loading.css';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from "../../store/loading/LoadingReducer";

const Loading = () => {
    const loading = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 60 * 1000);
        }
    }, [dispatch, loading]);

    if (!loading) return null;

    return <div className="loading-container"/>;
};

export default Loading;
