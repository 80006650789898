import React, {memo} from "react";
import './CatalogFilesDownload.css';
import {Link} from "react-router-dom";


const CatalogFilesDownload = ({standFileDownload, openTabLog, preview}) => {
    return (
        <div className="row text-center pt-2 bg-white">
            <div className="col-12 col-12">
                {
                    standFileDownload && standFileDownload.length > 0 &&
                    <>
                        <div className="text-left">
                            <p className='font-weight-bolder'>Catálogos</p>
                        </div>
                        <div className="catalog-list">
                            <table className="table table-striped">
                                <tbody>
                                    {
                                        standFileDownload.map((item, index) => {
                                            return (
                                                <tr key={index} role="button" className="custom-hover-tr">
                                                    <th className="custom-th" scope="row">
                                                        {preview ?
                                                            item.title
                                                            :
                                                            <Link className="custom-link-pdf" target='_blank' to="route"
                                                                  onClick={e => openTabLog(e, item.urlFile, 'DOWNLOAD')}>
                                                                {item.title}
                                                            </Link>
                                                        }
                                                    </th>
                                                </tr>

                                            );
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default memo(CatalogFilesDownload);
