import React, {memo} from "react";
import CartCalculate from "./CartCalculate";

const CouponDiscount = ({cartItem, discountCoupon, setDiscountCoupon, applyDiscountCoupon}) => {
    return (
        <form onSubmit={applyDiscountCoupon}>
            <div className="row">
                <div className="col-12">
                    <div className="form-label-group float-lg-right float-md-right">
                        <label htmlFor="discountCoupon">
                            <h4 className="font-weight-bolder text-dark">CUPOM DE DESCONTO</h4>
                        </label>
                        <div className="d-flex">
                            <input
                                type="text"
                                id="discountCoupon"
                                name="discountCoupon"
                                className="form-control"
                                placeholder="Cupom de Desconto"
                                value={discountCoupon && discountCoupon.coupon ? discountCoupon.coupon : ''}
                                onChange={e =>
                                    setDiscountCoupon({
                                        ...discountCoupon,
                                        coupon: e.target.value,
                                    })
                                }
                            />
                            <button type="submit" className="btn btn-lg btn-blue ml-3">Aplicar</button>
                        </div>

                        <CartCalculate
                            discountCoupon={discountCoupon}
                            purchaseItems={cartItem && cartItem.length > 0 && cartItem}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default memo(CouponDiscount);
