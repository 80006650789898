import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/AuthReducer';
import CartReducer from './cart/CartReducer';
import LoadingReducer from './loading/LoadingReducer';
import NavigatorReducer from './navigator/NavigatorReducer';
import MessagesReducer from './messages/MessagesReducer';
import CartItemReducer from "./cart/CartItemReducer";

export default configureStore({
  reducer: {
    auth: AuthReducer,
    cart: CartReducer,
    cartItem: CartItemReducer,
    loading: LoadingReducer,
    navigator: NavigatorReducer,
    messages: MessagesReducer,
  },
});
