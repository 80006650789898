import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import './ListMidiaSocial.css';
import { existUrl } from '../../utils/Ultis';
import LogServices from '../../services/LogServices';
import xLogo from '../../assets/images/x-logo.jpg';

const LinkMediaSocial = ({
  itemData,
  mediaSocial,
  preview = false,
  align = 'text-sm-right',
  marginRight = 'mr-3',
}) => {
  const verifyIconStyle = (iconType) => {
    switch (iconType) {
      case 'facebook':
        return 'btn-blue facebook-btn';
      case 'linkedin':
        return 'btn-blue linkedin-btn';
      case 'youtube':
        return 'btn-danger youtube-btn';
      case 'whatsapp':
        return 'btn-success whatsapp-btn';
      case 'twitter':
        return 'btn-blue twitter-btn';
      case 'instagram':
        return 'btn-danger instagram-btn';
      default:
        return '';
    }
  };

  const verifyIcon = (iconType) => {
    switch (iconType) {
      case 'facebook':
        return 'fa-facebook-f';
      case 'linkedin':
        return 'fa-linkedin';
      case 'youtube':
        return 'fa-youtube-play';
      case 'whatsapp':
        return 'fa-whatsapp';
      case 'twitter':
        return '';
      case 'instagram':
        return 'fa-instagram';
      default:
        return '';
    }
  };

  const registerLog = (item, itemData) => {
    const auth = JSON.parse(localStorage.getItem('@airbnb-Token'));
    let tgId = auth.participantId;

    if (itemData && typeof itemData.id !== 'undefined') {
      tgId = itemData.id;
    }

    if (auth && window.socket) {
      const defaultObject = {
        eventId: parseInt(auth.eventId),
        participantId: auth.participantId,
        url: item.urlPage,
        socketId: window.socket.id,
        isMobile: window.mobileCheck(),
        targetId: tgId,
        targetType: 'participante',
      };
      switch (item.typeMedia) {
        case 'whatsapp':
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'WHATSAPP',
          });
          break;
        case 'linkedin':
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'LINKEDIN',
          });
          break;
        case 'twitter':
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'TWITTER',
          });
          break;
        case 'instagram':
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'INSTAGRAM',
          });
          break;
        case 'facebook':
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'FACEBOOK',
          });
          break;
        default:
          console.log('erro');
          return false;
      }
    }
  };

  const openTabAndLog = (e, itemData, item) => {
    e.preventDefault();
    if (existUrl(item.urlPage)) {
      registerLog(item, itemData);
      window.open(item.urlPage);
    }
  };

  return (
    <div className={'text-center ' + align}>
      {mediaSocial &&
        mediaSocial
          .filter((obj) => existUrl(obj.urlPage))
          .map((item, index) => {
            return preview ? (
              <button
                key={index}
                className={`${verifyIconStyle(
                  item.typeMedia
                )} btn custom-class rounded-circle ${marginRight}`}
              >
                <i className={`fa ${verifyIcon(item.typeMedia)}`} />
              </button>
            ) : (
              <Link
                style={
                  item.typeMedia === 'twitter'
                    ? {
                        backgroundImage: 'url(' + xLogo + ')',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                      }
                    : null
                }
                target="_blank"
                key={index}
                to={'route'}
                className={`${verifyIconStyle(
                  item.typeMedia
                )} btn custom-class rounded-circle ${marginRight}`}
                onClick={(e) => openTabAndLog(e, itemData, item)}
              >
                <i className={`fa ${verifyIcon(item.typeMedia)}`} />
              </Link>
            );
          })}
    </div>
  );
};

export default memo(LinkMediaSocial);
