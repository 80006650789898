import './Header.scss';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IconCartShop from '../svg/IconCartShop';
import IconMonitorAccess from '../svg/IconMonitorAccess';
import Logo from './Logo';
import { useSelector, useDispatch } from 'react-redux';
import EventsServices from '../../services/EventsServices';

export default ({
  eventId,
  modelEvent,
  invokeCartBuy,
  invokeAccessEvent,
  preview = false,
}) => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const urlLogo = useSelector((state) => state.navigator.urlLogo);
  const titulo_acesso_evento = useSelector(
    (state) => state.navigator.titulo_acesso_evento
  );

  const getLinkSignIn = () => {
    return 'https://' + window.location.host + '/eventLobby';
  };

  const getLogoCompany = () => {
    return urlLogo || '';
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Logo logoCompany={getLogoCompany()} redirect={!preview ? '/' : '#'} />
        {!preview && (
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon icon-toggler" />
            <span className="navbar-toggler-icon icon-closed" />
          </button>
        )}
        {!auth ? (
          <button
            id="reloadBtn"
            onClick={() => {
              dispatch(EventsServices.findEvents());
              history.push(location.pathname);
            }}
          >
            Reload
          </button>
        ) : null}
        {!preview && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {modelEvent === 'INGRESSO' ? (
                <li className="nav-item">
                  <Link
                    onClick={invokeCartBuy}
                    to={'/cartBuy/' + eventId}
                    className="nav-link font-weight-bold"
                  >
                    <IconCartShop /> <span className="navbar-text">Carrinho</span>
                  </Link>
                </li>
              ) : (
                ''
              )}
              <li className="nav-item custom-enter-btn">
                <Link
                  onClick={invokeAccessEvent}
                  to="/login"
                  className="nav-link font-weight-bold"
                >
                  <IconMonitorAccess />{' '}
                  <span className="navbar-text">
                    {titulo_acesso_evento
                      ? titulo_acesso_evento
                      : 'Acesso ao evento'}
                  </span>
                </Link>
              </li>
              <li className="hide">
                <a
                  href={getLinkSignIn()}
                  onClick={() => {
                    const userStorage = JSON.parse(
                      localStorage.getItem('@navigator')
                    );
                    const userSession = JSON.parse(
                      localStorage.getItem('@airbnb-Token')
                    );
                    const userData = {
                      session: userSession,
                      storage: userStorage,
                      url: window.location.pathname,
                    };
                    window.socket.emit('userData', userData);
                  }}
                  className="hide"
                  id="storagesign"
                >
                  &nbsp;
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};
