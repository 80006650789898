import React, { memo } from 'react';
import { useSelector } from 'react-redux';

const NetworkInterestAreasList = (props) => {
  const titulo_areasInteresse = useSelector(
    (state) => state.navigator.titulo_areasInteresse
  );

  return (
    <>
      <div>
        <h5>
          {titulo_areasInteresse ? titulo_areasInteresse : 'Áreas de Interesse'}
        </h5>
      </div>
      <div>
        <div className="toggle-switch btn-group-vertical btn-group-toggle btn-block">
          {props.interestAreas &&
            props.interestAreas.length > 0 &&
            props.interestAreas.map((item, index) => {
              return (
                <label
                  key={index}
                  className={`btn text-left m-0 p-0 text-semi-light pt-1 ${
                    item.checked ? 'active text-color-custom' : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    value={index}
                    checked={item.checked}
                    onChange={(e) => props.addInterestAreas(e.target.checked, index)}
                  />
                  {item.name}
                </label>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(NetworkInterestAreasList);
