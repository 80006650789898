import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import CarouselCustom from '../carousel/CarouselCustom';
import { getScreenResizeCarousel } from '../../utils/ScreenResize';

const ListStands = ({ roomsFair, preview = false }) => {
  function urlLink(item) {
    if (item.typeEvent === 'access_room') {
      return '/roomLobby/' + item.roomFairId;
    }

    if (item.typeEvent === 'access_fairs') {
      return '/fair/' + item.roomFairId;
    }

    return '#';
  }

  const [termoBusca, setTermoBusca] = useState('');

  return (
    <>
      <div className="col-12 search-col" style={{ marginTop: '10px' }}>
        <div className="input-group mb-3">
          <input
            type="search"
            placeholder="Pesquisar"
            className="form-control search-input"
            onChange={(event) => {
              setTermoBusca(event.target.value);
            }}
          />
          <span className="input-group-text" id="basic-addon1">
            <i className="fa fa-fw" aria-hidden="true" title="Copy to use search">
              
            </i>
          </span>
        </div>
      </div>

      <CarouselCustom
        showPagination={true}
        itemsToShow={getScreenResizeCarousel(roomsFair)}
      >
        {roomsFair &&
          roomsFair
            .filter((val) => {
              if (termoBusca === '') {
                return val;
              } else {
                return val.roomFairName
                  .toLowerCase()
                  .includes(termoBusca.toLowerCase());
              }
            })
            .map((item, index) => {
              return (
                <Link
                  to={!preview ? urlLink(item) : '#'}
                  className="item rounded border-shadow carouselItemClickable"
                  key={index}
                >
                  <div>
                    <div className="btn btn-customcolor m-auto title d-block text-wrap align-middle">
                      <strong>{item.roomFairName}</strong>
                    </div>
                    <i className="fa fa-caret-down fa-2x btn m-auto d-block custom-arrow-icon p-0 m-0" />
                  </div>

                  <div
                    className="carousel-img m-auto image-background"
                    style={{
                      backgroundImage: `url("${item.urlLobby}")` || '',
                    }}
                  ></div>
                </Link>
              );
            })}
      </CarouselCustom>
    </>
  );
};

export default memo(ListStands);
