import React, { useEffect, useState, useCallback } from 'react';
import Main from '../components/template/Main';
import InfoDetailEvent from '../components/cart/InfoDetailEvent';
import { useDispatch, useSelector } from 'react-redux';
import EventsServices from '../services/EventsServices';
import { setLoading } from '../store/loading/LoadingReducer';
import 'react-lazy-load-image-component/src/effects/blur.css';
import EventFree from '../components/cart/EventFree';
import { addEventInfo } from '../store/navigator/NavigatorReducer';
import NavigatorCartButton from '../components/cart/NavigatorCartButton';
import { removeItem } from '../store/cart/CartReducer';
import { removeRemoveAllItem } from '../store/cart/CartItemReducer';
import CartListEvent from '../components/cart/CartListEvent';
import { useHistory } from 'react-router-dom';
import { addMessage } from '../store/messages/MessagesReducer';
import Slider from 'react-slick';
import PageNotFound from './404';
import AuthServices from '../services/AuthServices';

const ShoppingEvents = (props) => {
  const [cart, setCart] = useState(null);
  const cartEvent = useSelector((state) => state.cart);
  const [isLoaded, setIsLoaded] = useState(false);

  const [result, setResult] = useState(null);

  const [valueTotal, setValueTotal] = useState(0);
  const [carrosselImages, setCarrosselImages] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const logoutParam = urlParams.get('logout');
    if (logoutParam && logoutParam.toLowerCase() === 'true') {
      dispatch(AuthServices.signOut());
      localStorage.clear();
      if (window.socket) {
        window.socket.emit('killOtherSessions');
        window.socket.emit('logOut');
      }
    }
    // eslint-disable-next-line
  }, []);

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    adaptiveHeight: true,
  };

  let userStorage;
  try {
    userStorage = JSON.parse(localStorage.getItem('@navigator'));
  } catch (err) {
    userStorage = null;
    console.log(err.message);
  }

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    let reqResult = null;
    if (!result) {
      if (!props.preview) {
        reqResult = await dispatch(EventsServices.findEvents());
        setResult(reqResult);
      }
    }

    setIsLoaded(true);

    if (reqResult) {
      if (
        reqResult.url_externa &&
        reqResult.url_externa !== '' &&
        reqResult.url_externa !== null &&
        window.location.origin !== reqResult.url_externa
      ) {
        window.location = reqResult.url_externa;
      }

      if (cartEvent && reqResult.eventFree) {
        dispatch(removeItem());
        dispatch(removeRemoveAllItem());
      }

      try {
        setCarrosselImages(JSON.parse(reqResult.urlLandPage));
      } catch (err) {
        console.log(err);
      }

      setCart(reqResult);

      dispatch(
        addEventInfo({
          eventId: reqResult.eventId,
          primaryColor: reqResult.primaryColor,
          secondaryColor: reqResult.secondaryColor,
          urlLogo: reqResult.urlLogo,
          eventTitle: reqResult.eventName,
          exibir_traducao_automatica: reqResult.exibir_traducao_automatica,
          exigir_senha_complexa: reqResult.exigir_senha_complexa,
          modelEvent: reqResult.modelEvent,
          favicon: reqResult.favicon,
          url_facebook: reqResult.url_facebook,
          url_linkedin: reqResult.url_linkedin,
          url_twitter: reqResult.url_twitter,
          url_landpage: reqResult.urlLandPage,
          css_custom: reqResult.css_custom,
          js_custom: reqResult.js_custom,
          campos_personalizados: reqResult.campos_personalizados,
          personalizados_obrigatorios: reqResult.personalizados_obrigatorios,
          direcionar_lobby_salas: reqResult.direcionar_lobby_salas,
          url_lgpd_cliente: reqResult.url_lgpd_cliente,
          texto_caixa_home: reqResult.texto_caixa_home,
          titulo_acesso_evento: reqResult.titulo_acesso_evento,
          titulo_cadastrar: reqResult.titulo_cadastrar,
          titulo_inscrevase: reqResult.titulo_inscrevase,
          titulo_login: reqResult.titulo_login,
          titulo_areasInteresse: reqResult.titulo_areasInteresse,
          titulo_informacoes_gerais: reqResult.titulo_informacoes_gerais,
          formas_pagamento: reqResult.formas_pagamento,
        })
      );
    }
  }, [cartEvent, dispatch, props, result]);

  useEffect(() => {
    if (localStorage.getItem('@airbnb-Token')) {
      window.location.pathname = '/eventLobby';
    }
    if (!result) {
      fetchData().catch(() => {
        dispatch(setLoading(false));
      });
    }
  }, [dispatch, result, fetchData]);

  const addCartItem = (cartItem) => {
    if (cart) {
      history.push('/cartBuy/' + cart.eventId);
      dispatch(EventsServices.addCartItem(cartItem));
    } else {
      dispatch(addMessage({ message: 'Nenhum item adicionado!', level: 'warning' }));
    }
  };

  const invokeCartBuy = () => {
    if (cart) {
      dispatch(EventsServices.addCartItem(cart));
    }
  };

  const invokeAccessEvent = () => {
    dispatch(removeItem());
    dispatch(removeRemoveAllItem());
  };

  const calculateTotal = (itemChange) => {
    if (itemChange) {
      setValueTotal(itemChange.map((item) => item.total).reduce((a, b) => a + b, 0));
    }
  };

  const onChangeValues = (cartItem, itemId, quantity) => {
    const itemChange = EventsServices.onChangeValues(
      cartItem.purchaseItems,
      itemId,
      quantity
    );
    setCart({ ...cartItem, purchaseItems: itemChange });
    calculateTotal(itemChange);
  };

  if (cart) {
    return (
      <>
        <NavigatorCartButton
          eventId={result.eventId}
          preview={props.preview}
          invokeCartBuy={invokeCartBuy}
          invokeAccessEvent={invokeAccessEvent}
        />
        <Main>
          {carrosselImages.length > 0 ? (
            <div className="container-fluid">
              <div className="row mb-5">
                <div id="bannerWrapper" className="col-lg-12 p-0">
                  <Slider {...settings}>
                    {carrosselImages.map((image, i) => {
                      return (
                        <div className="image-banner-holder" key={i}>
                          <img
                            className="image-banner"
                            src={unescape(image.url)}
                            alt={unescape(image.nome)}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          ) : null}
          <div className="container pt-4">
            <div className="row justify-content-center">
              <div id="eventdetailinfo" className="col-lg-7">
                <InfoDetailEvent
                  info={cart}
                  infoHtml={cart.eventDescription}
                  preview={props.preview}
                />
              </div>

              <div className="col-lg-5">
                {!cart.eventFree && cart.modelEvent !== 'CONVIDADOS' ? (
                  <CartListEvent
                    {...props}
                    cart={cart}
                    valueTotal={valueTotal}
                    onChangeValues={onChangeValues}
                    addCartItem={addCartItem}
                    enableButtonBuy={true}
                    preview={props.preview}
                  />
                ) : (
                  <EventFree
                    preview={props.preview}
                    model={cart.modelEvent}
                    userStorage={userStorage}
                  />
                )}
              </div>
            </div>
          </div>
        </Main>
      </>
    );
  } else {
    return (
      <>
        {isLoaded && (
          <PageNotFound
            title={`Evento não encontrado`}
            description={`Não foi possível carregar o evento!`}
          />
        )}
      </>
    );
  }
};

export default ShoppingEvents;
