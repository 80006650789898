import React, { memo } from 'react';

const NetworkInterestAreas = ({
  interestAreas,
  addInterestAreas,
  selectedInterestAreas,
}) => {
  return (
    <div className="btn-group-toggle" data-toggle="buttons">
      {interestAreas.areaInterest &&
        interestAreas.areaInterest.map((item, index) => {
          return selectedInterestAreas.includes(item) ? (
            <label key={index} className="btn btn-outline-secondary mr-1 active">
              <input
                type="checkbox"
                name="interestAreas"
                id={index}
                value={item}
                onClick={(e) => addInterestAreas(e.target.value)}
              />
              {item}
            </label>
          ) : (
            <label key={index} className="btn btn-outline-secondary mr-1">
              <input
                type="checkbox"
                name="interestAreas"
                id={index}
                value={item}
                onClick={(e) => addInterestAreas(e.target.value)}
              />
              {item}
            </label>
          );
        })}
    </div>
  );
};

export default memo(NetworkInterestAreas);
