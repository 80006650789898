import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  messages: [
    {
      message: '',
      level: 'info',
    },
  ],
};

export const addMessage = createAction('ADD_MESSAGE');
export const removeMessage = createAction('REMOVE_MESSAGE');

export default createReducer(INITIAL_STATE, {
  [addMessage.type]: (state, action) => ({
    ...state,
    messages: [...state.messages, action.payload],
  }),
  [removeMessage.type]: (state, action) => ({
    ...state,
    messages: state.messages.filter((item) => item.message !== action.payload),
  }),
});
