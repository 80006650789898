import React, { useEffect } from 'react';
import Loading from './Loading';
import Messages from '../messages/Messages';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../Routes';
import { useSelector } from 'react-redux';
import Footer from './Footer';

const TemplateMain = () => {
  const primaryColor = useSelector((state) => state.navigator.primaryColor);
  const secondaryColor = useSelector((state) => state.navigator.secondaryColor);
  const eventTitle = useSelector((state) => state.navigator.eventTitle);
  const eventFavicon = useSelector((state) => state.navigator.favicon);
  const css_custom = useSelector((state) => state.navigator.css_custom);
  const js_custom = useSelector((state) => state.navigator.js_custom);
  const exibir_traducao_automatica = useSelector(
    (state) => state.navigator.exibir_traducao_automatica
  );

  if (eventTitle && eventTitle !== '') {
    document.title = eventTitle;
  }
  if (eventFavicon && eventFavicon !== '') {
    document.getElementById('favicon').href = eventFavicon;
    document.getElementById('appleIcon').href = eventFavicon;
  }

  const root = document.documentElement;
  root.style.setProperty(
    '--backgroundalpha-color',
    primaryColor + 'dd' || '#f18e12dd'
  );
  root.style.setProperty(
    '--backgroundtransparent-color',
    primaryColor + '40' || '#f18e12dd'
  );
  root.style.setProperty('--background-color', primaryColor || '#f18e12');
  root.style.setProperty('--color', secondaryColor || '#000000');

  useEffect(() => {
    const element = document.getElementById('custom_style');
    if (element) {
      element.parentNode.removeChild(element);
    }
    const customStyle = document.createElement('style');
    customStyle.setAttribute('id', 'custom_style');
    customStyle.append(css_custom);
    document.head.appendChild(customStyle);
  });

  useEffect(() => {
    if (js_custom && js_custom !== null && js_custom !== '') {
      eval(js_custom); // eslint-disable-line no-eval
    }
  }, [js_custom]);

  return (
    <>
      <div
        id="alertHolder"
        className={process.env.REACT_APP_ENV_SHOW === 'TRUE' ? 'show' : 'hide'}
      >
        <div
          id="versionAlert"
          className="alert alert-danger progress-bar-striped"
          role="alert"
        >
          {process.env.REACT_APP_ENV_NAME} mode
        </div>
      </div>
      <Loading />
      <Messages />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      {exibir_traducao_automatica === 1 ? <Footer /> : null}
    </>
  );
};

export default TemplateMain;
