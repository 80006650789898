import React from 'react';
import Main from '../components/template/Main';
import 'react-lazy-load-image-component/src/effects/blur.css';
import TemplateApp from '../components/template/TemplateApp';

const LeaveZoom = (props) => {
  return (
    <TemplateApp>
      <Main>
        <div className="container mt-4">
          <div className="row justify-content-center">
            <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Agradecemos sua participação no Evento!
            </span>
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default LeaveZoom;
