import React from 'react';
import {Provider} from 'react-redux';
import store from './store/index';
import TemplateMain from "./components/template/TemplateMain";

const App = () =>
    <Provider store={store}>
        <TemplateMain/>
    </Provider>

export default App;
