import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  getEventId,
  getExibirTraducaoAutomatica,
  getExigirSenhaComplexa,
  getFavicon,
  getUrlFacebook,
  getUrlLinkedin,
  getUrlTwitter,
  getEventTitle,
  getModelEvent,
  getNavigatorRoomFair,
  getPrimaryColor,
  getSecondaryColor,
  getTituloAcessoEvento,
  getTituloCadastrar,
  getTituloInscrevase,
  getTituloInformacoesGerais,
  getTituloAreasInteresse,
  getTicketId,
  getUrlLogo,
  removeNavigator,
  getCssCustom,
  getJsCustom,
  setEventInfo,
  setNavigatorConfirm,
  setNavigatorRoomFair,
  setTicketId,
  getTituloLogin,
} from '../../session/Navigator';

const INITIAL_STATE = {
  navigatorTabIndex: 0,
  showMenu: false,
  eventId: getEventId(),
  exibir_traducao_automatica: getExibirTraducaoAutomatica(),
  exigir_senha_complexa: getExigirSenhaComplexa(),
  favicon: getFavicon(),
  urlLogo: getUrlLogo(),
  primaryColor: getPrimaryColor(),
  secondaryColor: getSecondaryColor(),
  eventTitle: getEventTitle(),
  modelEvent: getModelEvent(),
  navigatorRoomFair: getNavigatorRoomFair(),
  titulo_acesso_evento: getTituloAcessoEvento(),
  titulo_cadastrar: getTituloCadastrar(),
  titulo_inscrevase: getTituloInscrevase(),
  titulo_informacoes_gerais: getTituloInformacoesGerais(),
  titulo_login: getTituloLogin(),
  titulo_areasInteresse: getTituloAreasInteresse(),
  ticketId: getTicketId(),
  url_facebook: getUrlFacebook(),
  url_linkedin: getUrlLinkedin(),
  url_twitter: getUrlTwitter(),
  css_custom: getCssCustom(),
  js_custom: getJsCustom(),
};

export const setNavigatorTabIndex = createAction('NAVIGATOR_TAB_HOME');
export const addEventInfo = createAction('ADD_EVENT_INFO');
export const addSetTicketId = createAction('ADD_TICKET_ID');
export const addSetNavigatorRoomFair = createAction('ADD_NAVIGATOR_ROOM_FAIR');
export const removeItemNavigator = createAction('REMOVE_ITEM_NAVIGATOR');

export default createReducer(INITIAL_STATE, {
  [setNavigatorTabIndex.type]: (state, action) => {
    return { ...state, navigatorTabIndex: action.payload };
  },
  [addSetTicketId.type]: (state, action) => {
    const ticketId = action.payload;
    setTicketId(ticketId);
    return { ...state, ticketId };
  },
  [addEventInfo.type]: (state, action) => {
    let {
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      url_landpage,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    } = action.payload;

    if (primaryColor === '#FFFFFF') {
      primaryColor = '#f18e12';
    }

    setEventInfo(
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      url_landpage,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento
    );

    setNavigatorConfirm({
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    });

    return {
      ...state,
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      url_landpage,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    };
  },

  [addSetNavigatorRoomFair.type]: (state, action) => {
    const { typeEvent, urlLobby } = action.payload;
    const navigatorRoomFair = { typeEvent, urlLobby };
    setNavigatorRoomFair(navigatorRoomFair);
    return { ...state, navigatorRoomFair };
  },

  [removeItemNavigator.type]: (state) => {
    removeNavigator();
    return { ...state, navigatorRoomFair: null, ticketId: '' };
  },
});
