import React, { useEffect, useCallback } from 'react';
import { isAuthenticated } from '../session/Auth';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Register from '../pages/Register';
import PageNotFound from '../pages/404';
import ShoppingFinish from '../pages/ShoppingFinish';
import MyEvents from '../pages/MyEvents';
import RoomEvents from '../pages/RoomEvents';
import RoomsList from '../pages/RoomsList';
import LeaveZoom from '../pages/LeaveZoom';
import CartBuy from '../pages/CartBuy';
import UserProfile from '../pages/UserProfile';
import ManagerEvents from '../pages/ManagerEvents';
import FairsList from '../pages/FairsList';
import FairEvents from '../pages/FairEvents';
import Information from '../pages/Information';
import Recover from '../pages/Recover';
import PurchaseDetail from '../pages/PurchaseDetail';
import ConfirmRegister from '../pages/ConfirmRegister';
import Network from '../pages/Network';
import NetworkParticipant from '../pages/NetworkParticipant';
import SlackChatlio from '../pages/SlackChatlio';
import LogServices from '../services/LogServices';
import RoomDigitalSignage from '../pages/RoomDigitalSignage';
import ShoppingEvents from '../pages/ShoppingEvents';
import CheckoutStripeSuccess from '../pages/CheckoutStripeSuccess';
import CheckoutStripeError from '../pages/CheckoutStripeError';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.socket) {
      window.socket.emit('urlChange', window.location.pathname);
    }
  }, [location]);

  const getRoomFairIdFromAuth = (auth) => {
    for (let index = 0; index < auth.roomsFair.length; index++) {
      const item = auth.roomsFair[index];
      if (item.typeEvent === 'access_fairs') {
        return item.roomId;
      }
    }
  };

  const checkIfStartsWith = (test) => {
    if (window.location.pathname.startsWith(test)) {
      return window.location.pathname;
    }
  };

  const registerLog = useCallback(() => {
    const auth = JSON.parse(localStorage.getItem('@airbnb-Token'));

    if (auth && window.socket) {
      const defaultObject = {
        eventId: parseInt(auth.eventId),
        participantId: auth.participantId,
        url: window.location.pathname,
        socketId: window.socket.id,
        isMobile: window.mobileCheck(),
      };
      switch (window.location.pathname) {
        case checkIfStartsWith('/room/'):
          LogServices.accessLog({
            ...defaultObject,
            roomId: parseInt(window.location.pathname.replace('/room/', '')),
            standId: null,
            tipo: 'SALA',
          });
          break;
        case checkIfStartsWith('/fair/'):
          LogServices.accessLog({
            ...defaultObject,
            roomId: getRoomFairIdFromAuth(auth),
            standId: parseInt(window.location.pathname.replace('/fair/', '')),
            tipo: 'STAND',
          });
          break;
        case checkIfStartsWith('/information/'):
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'INFORMACOES',
          });
          break;
        case checkIfStartsWith('/eventLobby'):
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'LOBBY',
          });
          break;
        case checkIfStartsWith('/network/'):
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'NETWORK',
          });
          break;
        default:
          LogServices.accessLog({
            ...defaultObject,
            roomId: null,
            standId: null,
            tipo: 'URL_CHANGE',
          });
      }
    } else {
      if (auth && checkIfStartsWith('/leaveZoom')) {
        LogServices.accessLog({
          eventId: parseInt(auth.eventId),
          participantId: auth.participantId,
          url: window.location.pathname,
          socketId: null,
          isMobile: window.mobileCheck(),
          roomId: parseInt(window.location.pathname.replace('/leaveZoom/', '')),
          standId: null,
          tipo: 'LEAVE_ZOOM',
        });
      }
    }
  }, []);

  useEffect(registerLog);

  return (
    <Switch>
      <Route
        exact
        path="/"
        component={(props) => <ShoppingEvents preview={false} />}
      />

      <Route
        exact
        path="/chatlio/:chatId"
        component={(props) => (
          <SlackChatlio chatId={props.match.params.chatId} preview={true} />
        )}
      />

      <Route
        exact
        path="/eventLobby/:eventId/preview"
        component={(props) => (
          <ManagerEvents eventId={props.match.params.eventId} preview={true} />
        )}
      />
      <Route
        path="/information/:eventId/preview"
        component={(props) => (
          <Information eventId={props.match.params.eventId} preview={true} />
        )}
      />
      <Route
        exact
        path="/roomsList/:eventId/preview"
        component={(props) => (
          <RoomsList eventId={props.match.params.eventId} preview={true} />
        )}
      />
      <Route
        exact
        path="/roomLobby/:eventId/:roomFairId/preview"
        component={(props) => (
          <MyEvents
            eventId={props.match.params.eventId}
            roomFairId={props.match.params.roomFairId}
            preview={true}
          />
        )}
      />
      <Route
        exact
        path="/room/:eventId/:roomFairId/preview"
        component={(props) => (
          <RoomEvents
            eventId={props.match.params.eventId}
            roomFairId={props.match.params.roomFairId}
            preview={true}
          />
        )}
      />
      <Route
        exact
        path="/ds/:eventId/:roomFairId/:token"
        component={(props) => (
          <RoomDigitalSignage
            token={props.match.params.token}
            eventId={props.match.params.eventId}
            roomFairId={props.match.params.roomFairId}
          />
        )}
      />
      <Route
        exact
        path="/fairsList/:eventId/preview"
        component={(props) => (
          <FairsList eventId={props.match.params.eventId} preview={true} />
        )}
      />
      <Route
        exact
        path="/fair/:eventId/:roomFairId/preview"
        component={(props) => (
          <FairEvents
            eventId={props.match.params.eventId}
            roomFairId={props.match.params.roomFairId}
            preview={true}
          />
        )}
      />

      <Route
        path="/cartBuy/:eventId"
        component={(props) => <CartBuy eventId={props.match.params.eventId} />}
      />
      <Route path="/login" component={() => <Login />} />
      <Route path="/register" component={(props) => <Register {...props} />} />
      <Route path="/recover" component={(props) => <Recover {...props} />} />

      <Route
        path="/confirm/:eventId/:codeActive"
        component={(props) => (
          <ConfirmRegister
            eventId={props.match.params.eventId}
            codeActive={props.match.params.codeActive}
          />
        )}
      />

      <Route
        path="/checkout-stripe/success"
        component={() => <CheckoutStripeSuccess />}
      />

      <Route
        path="/checkout-stripe/error"
        component={() => <CheckoutStripeError />}
      />

      <PrivateRoute
        path="/sala-share/:eventId/:roomFairId"
        component={(props) => (
          <RoomEvents roomFairId={props.match.params.roomFairId} preview={false} />
        )}
      />

      <PrivateRoute path="/userProfile" component={() => <UserProfile />} />
      <PrivateRoute
        path="/network/participant/:eventId"
        component={(props) => (
          <NetworkParticipant eventId={props.match.params.eventId} />
        )}
      />
      <PrivateRoute
        path="/network/:eventId"
        component={(props) => <Network eventId={props.match.params.eventId} />}
      />
      <PrivateRoute
        path="/shoppingFinish"
        component={(props) => <ShoppingFinish {...props} />}
      />
      <PrivateRoute path="/home" component={(props) => <Home {...props} />} />
      <PrivateRoute
        path="/eventLobby"
        component={(props) => <ManagerEvents preview={false} />}
      />
      <PrivateRoute
        path="/purchaseDetail/:purchaseId"
        component={(props) => (
          <PurchaseDetail purchaseId={props.match.params.purchaseId} />
        )}
      />
      <PrivateRoute
        exact
        path="/roomLobby/:roomFairId"
        component={(props) => (
          <MyEvents roomFairId={props.match.params.roomFairId} preview={false} />
        )}
      />
      <PrivateRoute
        exact
        path="/room/:roomFairId"
        component={(props) => (
          <RoomEvents roomFairId={props.match.params.roomFairId} preview={false} />
        )}
      />
      <PrivateRoute
        exact
        path="/fair/:roomFairId"
        component={(props) => (
          <FairEvents roomFairId={props.match.params.roomFairId} preview={false} />
        )}
      />
      <PrivateRoute
        exact
        path="/roomsList/:eventId"
        component={(props) => (
          <RoomsList eventId={props.match.params.eventId} preview={false} />
        )}
      />
      <PrivateRoute
        exact
        path="/leaveZoom/:roomId"
        component={(props) => (
          <LeaveZoom roomId={props.match.params.roomId} preview={false} />
        )}
      />
      <PrivateRoute
        exact
        path="/fairsList/:eventId"
        component={(props) => (
          <FairsList eventId={props.match.params.eventId} preview={false} />
        )}
      />
      <PrivateRoute
        path="/information/:eventId"
        component={(props) => (
          <Information eventId={props.match.params.eventId} preview={false} />
        )}
      />

      <Route
        path="*"
        component={(props) => (
          <PageNotFound
            title={'Página não encontrada'}
            description={'Não foi possível carregar está página'}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
