import React from 'react';

export default (props) => {
    return (
        <>
            <div className="mt-2 mb-2">
                <h5 className="card-title text-left text-color-custom">
                    <strong>{props.title}</strong>
                </h5>
                <h6>
                    <strong className="text-muted">{props.description}</strong>
                </h6>
            </div>

            <hr className="hr-custom"/>

            {props.children}
        </>
    )
};
