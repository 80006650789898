import React, { memo, useEffect, useState } from 'react';
import { maskCurrency } from '../../utils/MaskInput';
import './CartItemList.scss';
import EventsServices from '../../services/EventsServices';

const CartCalculate = ({ discountCoupon, purchaseItems, purchaseDetail }) => {
  const [values, setValues] = useState({
    valueBuy: 0,
    valueDiscount: 0,
    valueTotal: 0,
  });

  useEffect(() => {
    const calculateValueTotal = () => {
      const {
        valueBuy,
        valueDiscount,
        valueTotal,
      } = EventsServices.calculateCartValue(
        purchaseItems,
        discountCoupon,
        purchaseDetail
      );
      setValues({
        valueBuy,
        valueDiscount,
        valueTotal,
      });
    };

    calculateValueTotal();
  }, [purchaseItems, purchaseDetail, discountCoupon]);

  return (
    <div className="col-12 p-2">
      <table className="mt-3">
        <tbody className="tbody-custom">
          <tr>
            <td className="p-1">Total da Compra</td>
            <td className="p-1 total-td text-right">
              {maskCurrency(values.valueBuy)}
            </td>
          </tr>
          <tr className="text-table-blue">
            <td className="p-1">
              Cupom utilizado
              {discountCoupon && discountCoupon.coupon && values.valueDiscount > 0
                ? maskCurrency(discountCoupon.coupon)
                : ''}
            </td>
            <td className="p-1 total-td text-right">
              {maskCurrency(parseFloat(values.valueDiscount))}
            </td>
          </tr>
          <tr className="tr-custom">
            <td className="p-1">Total</td>
            <td className="p-1 total-td text-right">
              {maskCurrency(values.valueTotal)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default memo(CartCalculate);
