import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { setNavigatorTabIndex } from '../../store/navigator/NavigatorReducer';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../services/AuthServices';
import Avatar from 'react-avatar';

const MenuProfile = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);

  const goHome = () => dispatch(setNavigatorTabIndex(1));

  const singOutKill = () => {
    dispatch(AuthService.signOut());
    window.socket.emit('killOtherSessions');
    window.socket.emit('logOut');
  };

  const signOut = () => {
    dispatch(AuthService.signOut());
    window.socket.emit('logOut');
  };

  return (
    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle p-0"
        to="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="user-name">{auth && auth.username}</span>
        <Avatar
          googleId="118096717852922241760"
          size="65"
          round={true}
          src={auth && auth.avatar}
        />
      </Link>

      <div
        className="dropdown-menu dropdown-menu-right user-menu-custom"
        aria-labelledby="navbarDropdown"
      >
        {!cart && (
          <Link
            className="dropdown-item font-weight-bold"
            onClick={goHome}
            to="/home"
          >
            Meus Ingressos
          </Link>
        )}

        {auth && cart && (
          <Link className="dropdown-item font-weight-bold" to="/shoppingFinish">
            Carrinho de compras
          </Link>
        )}

        <Link className="dropdown-item font-weight-bold" to="/userProfile">
          Perfil
        </Link>

        <Link
          className="dropdown-item font-weight-bold"
          onClick={singOutKill}
          to="#"
          id="dislogKillBtn"
        >
          Sair
        </Link>

        <Link className="hide" onClick={signOut} to="#" id="dislogBtn" />
      </div>
    </li>
  );
};

export default memo(MenuProfile);
