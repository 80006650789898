import React, {useState} from 'react';


const UploadImage = ({setUpload}) => {
    const [fileName, setFileName] = useState('');

    const handleImageChange = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) {
            setFileName(file.name);
        }

        reader.onloadend = () => {
            setUpload({
                file: file,
                imageUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    }

    const searchFile = () => {
        document.getElementById("file-profile").click();
    }

    return (
        <div className="row">
            <div className="col-md-6 font-weight-bold pr-lg-0 pr-md-0">
                <div className="form-label-group d-flex custom-form-photo-profile">
                    <input type="file" id="file-profile" className="file-profile" style={{display: "none"}}
                           onChange={e => handleImageChange(e)}/>
                    <input type="text" id="file" className="border-0 custom-photo-input form-control" placeholder="Selecione um arquivo"
                           value={fileName}
                           readOnly="readonly"/>
                    <button type="button" className="btn btn-md btn-blue mt-0"
                        onClick={() => searchFile()}
                    >
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UploadImage;
