import React, { memo } from 'react';

const NetworkCategoriasList = (props) => {
  return (
    <>
      <div>
        <h5>Categorias</h5>
      </div>
      <div>
        <div className="toggle-switch btn-group-vertical btn-group-toggle btn-block">
          {props.categorias_network &&
            props.categorias_network.length > 0 &&
            props.categorias_network.map((item, index) => {
              return (
                <label
                  key={index}
                  className={`btn text-left m-0 p-0 text-semi-light pt-1 ${
                    item.checked ? 'active text-color-custom' : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    value={index}
                    checked={item.checked}
                    onChange={(e) =>
                      props.addCategorias_network(e.target.checked, index)
                    }
                  />
                  {item.name}
                </label>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(NetworkCategoriasList);
