import React, {memo} from "react";
import Carousel from "react-elastic-carousel";
import NavigatorArrow from "./NavigatorArrow";
import NavigatorPagination from "./NavigatorPagination";
import './CarouselCustom.css';

const CarouselCustom = (props) => {
    function navigatorArrow({type, onClick, isEdge}) {
        return (
            <NavigatorArrow
                type={type}
                onClick={onClick}
                isEdge={isEdge}
            />
        )
    }

    function navigatorPagination({pages, activePage, onClick}) {
        if (props.showPagination === false) {
            return (<div/>);
        }
        return (
            <NavigatorPagination
                pages={pages}
                activePage={activePage}
                onClick={onClick}
            />
        )
    }

    return (
        <Carousel itemsToShow={props.itemsToShow} itemPadding={[0, 0]}
                    renderArrow={navigatorArrow}
                    renderPagination={navigatorPagination}
                    className="rounded border-shadow">
            {props.children}
        </Carousel>
    )
}

export default memo(CarouselCustom);
