import React, {memo} from 'react';

const CartEmpty = () => (
    <div className="container">
        <div className="row">
            <div className="col-12">
                <h3 className="text-center text-dark p-4">Nenhum item encontrado</h3>
            </div>
        </div>
    </div>
);

export default memo(CartEmpty);
