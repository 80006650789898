import React, { memo } from 'react';
import CatalogFilesDownload from '../events/CatalogFilesDownload';
import './LobbyStand.scss';

const LobbyStand = ({ roomsFair, openTabLog, preview = false }) => {
  return (
    <div className="row text-center pt-2">
      <div className="col-xl-6 col-lg-12 text-left pt-2">
        <CatalogFilesDownload
          standFileDownload={roomsFair && roomsFair.standFileDownload}
          openTabLog={openTabLog}
          preview={preview}
        />
      </div>
    </div>
  );
};

export default memo(LobbyStand);
