import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import { useDispatch, useSelector } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ListPurchase from '../components/events/ListPurchase';
import ListTickets from '../components/events/ListTickets';
import EventsServices from '../services/EventsServices';
import TemplateApp from '../components/template/TemplateApp';
import { useHistory } from 'react-router-dom';
import { addSetTicketId } from '../store/navigator/NavigatorReducer';

const Home = () => {
  const auth = useSelector((state) => state.auth);
  const ticketId = useSelector((state) => state.navigator.ticketId);
  const [tickets, setTickets] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleApplyVoucher = async (e, inviteVoucher) => {
    e.preventDefault();
    await dispatch(
      EventsServices.applyTicketId(
        {
          eventId: auth.eventId,
          participantId: auth.participantId,
          voucher: inviteVoucher,
        },
        auth.email,
        history
      )
    );
  };

  const handleExternalVoucher = async (name, email, voucherDetails) => {
    await dispatch(
      EventsServices.externalVoucher({
        name: name,
        email: email,
        voucherDetails: voucherDetails,
        participantId: auth.participantId,
        buyer: auth.email,
      })
    );
  };

  const ownerTicket = async (data) => {
    await dispatch(
      EventsServices.ownerTicket(
        { ...data, participantId: auth.participantId },
        auth.email,
        history
      )
    );
  };

  const goManagerEvent = async (ticketId, voucher, eventId) => {
    dispatch(addSetTicketId(ticketId));
    await dispatch(
      EventsServices.findRoomFair(
        { eventId, ticketId, voucher, email: auth.email },
        history
      )
    );
  };

  const isDisabledTab = () => {
    return !!(
      auth &&
      auth.purchase &&
      auth.purchase.length === 1 &&
      auth.purchase.find((item) => item.paymentMethod === 'FREE')
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(
        EventsServices.findEventByParticipant(
          auth.eventId,
          auth.participantId,
          ticketId
        )
      );
      if (result && result.length > 0) {
        setTickets(result);
      }
    };

    if (tickets.length <= 0 && auth.eventId && auth.participantId) {
      fetchData().catch();
    }
  }, [dispatch, auth.eventId, auth.participantId, tickets, ticketId]);

  return (
    <TemplateApp>
      <Main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Tabs defaultIndex={1} className="col">
                <TabList>
                  <Tab disabled={isDisabledTab()}>
                    <button className="navtab-custom" type="button">
                      Pedidos
                    </button>
                  </Tab>
                  <Tab>
                    <button className="navtab-custom" type="button">
                      Ingressos
                    </button>
                  </Tab>
                </TabList>

                <TabPanel>
                  <ListPurchase purchase={auth && auth.purchase} />
                </TabPanel>

                <TabPanel>
                  <ListTickets
                    purchase={auth && auth.purchase}
                    isPurchase={
                      !!(auth && auth.purchase && auth.purchase.length > 0)
                    }
                    tickets={tickets}
                    handleApplyVoucher={handleApplyVoucher}
                    ownerTicket={ownerTicket}
                    handleExternalVoucher={handleExternalVoucher}
                    goManagerEvent={goManagerEvent}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default Home;
