import React, { useState } from 'react';
import AuthService from '../services/AuthServices';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../assets/css/Login.css';
import NavigatorCartButton from '../components/cart/NavigatorCartButton';

const Login = (props) => {
  const cart = useSelector((state) => state.cart);
  const [showPassword, setShowPassword] = useState(false);
  const [inputLogin, setInputLogin] = useState({
    eventId: useSelector((state) => state.navigator.eventId),
    email: '',
    password: '',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const navigateTo = () => history.push('/register');

  const handleSignIn = async (e) => {
    e.preventDefault();

    const userStorage = JSON.parse(localStorage.getItem('@navigator'));

    if (inputLogin.eventId !== userStorage.eventId) {
      alert('Ocorreu um erro! Por favor tente novamente!');
      history.push('/');
    } else {
      await dispatch(AuthService.signIn(inputLogin, cart, history));

      const userStorage = JSON.parse(localStorage.getItem('@navigator'));
      const userSession = JSON.parse(localStorage.getItem('@airbnb-Token'));

      const userData = {
        session: userSession,
        storage: userStorage,
        url: window.location.pathname,
      };

      window.socket.emit('userData', userData);
      window.socket.emit('killOtherSessions');
    }
  };

  return (
    <>
      <NavigatorCartButton eventId={inputLogin.eventId} />

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="card-form-formated border-shadow">
              <div className="m-1 ml-lg-5 mr-lg-5 mt-lg-4 mb-lg-4 md-md-2">
                <h5 className="card-title text-left">
                  <strong>Login</strong>
                </h5>
              </div>

              <hr className="hr-custom" />

              <div className="m-1 m-lg-5 md-md-2">
                <div className="card-body p-0">
                  <form onSubmit={(e) => handleSignIn(e)}>
                    <fieldset>
                      <div className="form-label-group">
                        <label htmlFor="username">Usuário</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          autoComplete="username"
                          required
                          placeholder="Email"
                          autoFocus
                          onChange={(e) =>
                            setInputLogin({
                              ...inputLogin,
                              email: e.target.value.toLowerCase(),
                            })
                          }
                        />
                      </div>
                      <div className="form-slabel-group mt-4">
                        <label htmlFor="password">Senha</label>
                        <div className="field-container">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            name="password"
                            autoComplete="current-password"
                            className="form-control"
                            required
                            placeholder="Senha"
                            onChange={(e) =>
                              setInputLogin({
                                ...inputLogin,
                                password: e.target.value,
                              })
                            }
                          />
                          <button
                            type="button"
                            className="no-btn password-eye"
                            id="open-eye"
                            style={
                              !showPassword
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                            onClick={() => setShowPassword(true)}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            className="no-btn password-eye"
                            id="closed-eye"
                            style={
                              showPassword
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                            onClick={() => setShowPassword(false)}
                          >
                            <i className="fa fa-eye-slash"></i>
                          </button>
                        </div>
                      </div>
                      <h4>
                        <Link to="/recover">Esqueci minha senha</Link>
                      </h4>
                    </fieldset>

                    <div className="pt-2" id="custom-login-btns">
                      <button
                        className="btn btn-orange btn-lg btn-default mt-4"
                        type="submit"
                      >
                        ENTRAR
                      </button>
                      <p className="mt-4" style={{ textAlign: 'center' }}>
                        Não possui cadastro?{' '}
                        <button
                          className="btn-to-a"
                          onClick={navigateTo}
                          style={{ cursor: 'pointer' }}
                        >
                          Clique para cadastrar.
                        </button>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Login);
