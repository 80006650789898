import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const CheckoutStripeError = () => {
  return (
    <div class="container-fluid p-0 m-0">
      <div
        class="alert alert-danger text-center d-flex"
        role="alert"
        style={{
          height: '100vh',
          width: '100vw',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          className="mt-2"
          style={{
            border: '1px solid white',
            fontSize: '40px',
            padding: '5px 25px',
            borderRadius: '100%',
          }}
        >
          <div className="check-icon-animate">X</div>
        </div>
        <h4 class="font-weight-bold">Falha ao efetuar compra!</h4>
        <p>
          Houve um problema em seu pedido e o pagamento não foi realizado. Por favor,
          tente novamente.
        </p>
        <Link to={'/shoppingFinish'} className="btn btn-danger btn-lg mt-4">
          Voltar
        </Link>
      </div>
    </div>
  );
};

export default CheckoutStripeError;
