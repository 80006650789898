import React, {memo} from "react";
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import './PopoverInfo.css';

const PopoverInfo = ({title, informationalText}) => {
    const Tooltip = ({
                         arrowRef,
                         tooltipRef,
                         getArrowProps,
                         getTooltipProps,
                         placement
                     }) => (
        <div className="mt-5">
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: 'tooltip-container'
                })}
            >
                <div
                    {...getArrowProps({
                        ref: arrowRef,
                        className: 'tooltip-arrow',
                        'data-placement': placement
                    })}
                />
                {informationalText}
            </div>
        </div>
    );

    const Trigger = ({getTriggerProps, triggerRef}) => (
        <span  {...getTriggerProps({ref: triggerRef, className: 'trigger'})}>
             <button id="button-trigger" type="button" className="btn btn-sm btn-outline ml-2"
                     title={title}>
                <i className="fa fa-info-circle fa-2x"/>
             </button>
        </span>
    );

    return (
        <TooltipTrigger placement="bottom" trigger="click" tooltip={Tooltip}>
            {Trigger}
        </TooltipTrigger>
    )
}

export default memo(PopoverInfo);