import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplateApp from '../components/template/TemplateApp';
import ItemRoomFair from '../components/events/ItemRoomFair';
import { existUrl } from '../utils/Ultis';
import { useHistory } from 'react-router-dom';
import EventsServices from '../services/EventsServices';

const MyEvents = (props) => {
  const auth = useSelector((state) => state.auth);
  const [roomsFair, setRoomsFair] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const openTab = (e, url) => {
    e.preventDefault();
    if (existUrl(url)) {
      window.open(url);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!props.preview) {
        if (auth && auth.roomsFair && auth.roomsFair.length > 0) {
          setRoomsFair(
            auth.roomsFair.find(
              (item) =>
                item.roomFairId === Number(props.roomFairId) &&
                item.typeEvent === 'access_room'
            )
          );
        }
      }

      if (props.preview) {
        const result = await dispatch(
          EventsServices.findEventsPreview(props.eventId, history)
        );
        if (result && result.roomsFair && result.roomsFair.length > 0) {
          const room = result.roomsFair.find(
            (item) =>
              item.roomFairId === Number(props.roomFairId) &&
              item.typeEvent === 'access_room'
          );
          if (room) {
            setRoomsFair(room);
          } else {
            history.push('/');
          }
        }
      }
    };

    if (!roomsFair) {
      fetchData().catch();
    }
  }, [dispatch, history, props, auth, roomsFair]);

  return (
    <TemplateApp>
      <div className="row no-gutters myEventsRow">
        {roomsFair && roomsFair.urlLobby ? (
          <img src={roomsFair.urlLobby} className="image-banner" alt="" />
        ) : null}
        <div
          className={
            existUrl(roomsFair && roomsFair.urlChatVideo) ? 'absBot2' : 'absBot'
          }
        >
          <div className="container">
            {roomsFair && (
              <ItemRoomFair
                roomsFair={roomsFair}
                openTab={openTab}
                preview={props.preview}
              />
            )}
          </div>
        </div>
      </div>
    </TemplateApp>
  );
};

export default MyEvents;
