import React, { useEffect, useState } from 'react';
import Header from './Header';
import HeaderInternal from './HeaderInternal';

export default (props) => {
  const [preview, setPreview] = useState(false);
  const baseUrl = window.location.href;

  useEffect(() => {
    if (baseUrl && baseUrl !== '' && baseUrl.indexOf('preview') !== -1) {
      setPreview(true);
    }
  }, [baseUrl]);

  return (
    <>
      <div className="container-fluid">
        <div className="container-xl">
          <Header>
            <HeaderInternal preview={preview} />
          </Header>
        </div>
      </div>
      <main
        className={
          process.env.REACT_APP_ENV_SHOW === 'TRUE'
            ? 'content container-fluid p-0 alert-show'
            : 'content container-fluid p-0'
        }
        style={props.videoRoom ? { overflow: 'unset' } : null}
      >
        {props.children}
      </main>
    </>
  );
};
