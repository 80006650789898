import './Header.scss';
import React from 'react';

export default (props) => {
    return (
        <>
            {props.children}
        </>
    );
};
