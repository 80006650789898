import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Main from '../components/template/Main';
import EventsServices from '../services/EventsServices';
import CartItemList from '../components/cart/CartItemList';
import NavigatorCartButton from '../components/cart/NavigatorCartButton';
import { removeItem } from '../store/cart/CartReducer';
import { removeRemoveAllItem } from '../store/cart/CartItemReducer';
import IconCheck from '../components/svg/IconCheck';
import CouponDiscount from '../components/cart/CouponDiscount';
import { addMessage } from '../store/messages/MessagesReducer';

const CartBuy = (props) => {
  const [cartItem, setCartItem] = useState(useSelector((state) => state.cartItem));
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const history = useHistory();

  const setDiscountCoupon = (discountCoupon) =>
    dispatch(EventsServices.setCouponDiscount(cart, discountCoupon));

  const finishCartItem = async () => {
    if (cart && cartItem && cartItem.length > 0) {
      const discountCoupon =
        cart && cart.discountCoupon ? cart.discountCoupon : null;

      const result = await dispatch(
        EventsServices.finishCartItem(props.eventId, {
          purchaseItems: cartItem,
          discountCoupon,
        })
      );

      if (result) {
        history.push('/login');
      }
    } else {
      dispatch(
        addMessage({
          message: 'Nenhum item adicionado no carrinho!',
          level: 'warning',
        })
      );
    }
  };

  const onChangeValues = (purchaseItems, ticketId, quantity, isRemoved) => {
    const itemChange = EventsServices.onChangeValues(
      purchaseItems,
      ticketId,
      quantity,
      isRemoved
    );
    setCartItem(itemChange);
    dispatch(EventsServices.updateCartItem(itemChange, ticketId, isRemoved));
  };

  const applyDiscountCoupon = async (e) => {
    e.preventDefault();
    const discountCoupon = cart && cart.discountCoupon ? cart.discountCoupon : null;
    let result = null;
    if (discountCoupon !== null) {
      result = await dispatch(
        EventsServices.findDiscountCoupon(
          props.eventId,
          discountCoupon.coupon,
          setDiscountCoupon
        )
      );
    }
    if (result) {
      setDiscountCoupon(result);
    }
  };

  const invokeAccessEvent = () => {
    dispatch(removeItem());
    dispatch(removeRemoveAllItem());
  };

  return (
    <>
      <NavigatorCartButton
        eventId={props.eventId}
        invokeAccessEvent={invokeAccessEvent}
      />

      <Main>
        <div className="container">
          {
            <>
              <div className="row">
                <div className="col-12">
                  <div className="mt-5">
                    <h3 className="text-dark">
                      <strong>Carrinho de compra</strong>
                    </h3>
                  </div>

                  <div className="border-shadow bg-white">
                    <CartItemList
                      {...props}
                      purchaseItems={cartItem}
                      onChangeValues={onChangeValues}
                    />

                    <div className="col-12 p-5">
                      <CouponDiscount
                        cartItem={cartItem}
                        discountCoupon={
                          cart && cart.discountCoupon ? cart.discountCoupon : null
                        }
                        setDiscountCoupon={setDiscountCoupon}
                        applyDiscountCoupon={applyDiscountCoupon}
                      />
                    </div>

                    <div className="col-12 pb-5 pl-5 pr-5">
                      <div className="row">
                        <div className="col-12 col-lg-4 offset-lg-4">
                          <Link to={'/'} className="btn btn-lg btn-grey btn-cart">
                            VOLTAR
                          </Link>
                        </div>
                        <div className="col-12 col-lg-4">
                          <button
                            onClick={finishCartItem}
                            className="btn btn-lg btn-orange"
                          >
                            FINALIZAR
                            <IconCheck />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </Main>
    </>
  );
};

export default CartBuy;
