import React, {memo} from "react";
import {Link} from "react-router-dom";
import {existUrl} from "../../utils/Ultis";

const MenuItem = ({item}) => {
    const openTab = (e, url) => {
        e.preventDefault();
        if (existUrl(url)) {
            window.open(url);
        }
    }

    return (
        <>
            {
                item.typeEvent === 'access_coffee' ?
                    <li className="nav-item">
                        <Link to="route" onClick={e => openTab(e, item.urlPage)} target='_blank' className="nav-link font-weight-bold">
                            <span className="navbar-text">{item.title}</span>
                        </Link>
                    </li>
                    :
                    <li className="nav-item">
                        <Link to={item.urlPage} className="nav-link font-weight-bold">
                            <span className="navbar-text">{item.title}</span>
                        </Link>
                    </li>
            }
        </>
    )
}

export default memo(MenuItem);
