import React from 'react';
import { Link } from 'react-router-dom';
require('./styles.css');

const SponsorItem = (props) => {
  return (
    <Link to={'/fair/' + props.data.roomFairId} title={props.data.roomFairName}>
      <img src={props.data.urlLobby} alt={props.data.roomFairName} />
    </Link>
  );
};

export default SponsorItem;
