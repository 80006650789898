import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import SponsorItem from './SponsorItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
require('./styles.css');

const SponsorsCarousel = (props) => {
  const [sponsorsData, setSponsorsData] = useState(null);
  const [sponsorsLabel, setSponsorsLabel] = useState(null);

  const getSponsors = (sponsorsData) => {
    return sponsorsData.map((item) => {
      return item.typeEvent === 'access_fairs' && item.exibe_lobby === 1 ? (
        <SponsorItem data={item} key={item.roomFairId} />
      ) : null;
    });
  };

  const getSponsorsLength = (sponsorsData, max) => {
    if (sponsorsData && sponsorsData.length > 0) {
      let sponsors = 0;
      for (let index = 0; index < sponsorsData.length; index++) {
        const element = sponsorsData[index];
        if (element.typeEvent === 'access_fairs' && element.exibe_lobby === 1) {
          sponsors++;
        }
      }

      return sponsors <= max ? sponsors : max;
    } else {
      return null;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: getSponsorsLength(sponsorsData, 8),
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 7),
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 6),
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 5),
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 4),
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 3),
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSponsorsLength(sponsorsData, 1),
        },
      },
    ],
  };

  const init = () => {
    if (
      props.data !== null &&
      props.data.roomsFair !== null &&
      props.data.roomsFair.length > 0
    ) {
      const standsToShow = [];
      for (let index = 0; index < props.data.roomsFair.length; index++) {
        const element = props.data.roomsFair[index];
        if (element.typeEvent === 'access_fairs' && element.exibe_lobby === 1) {
          standsToShow.push(element);
        }
      }

      setSponsorsData(standsToShow);

      if (
        props.data.nome_area_patrocinio &&
        props.data.nome_area_patrocinio !== '' &&
        standsToShow.length > 0
      ) {
        setSponsorsLabel(props.data.nome_area_patrocinio);
      }
    }
  };

  useEffect(init, []);

  if (sponsorsData && sponsorsData !== null && sponsorsData.length > 0) {
    return (
      <>
        <h6
          id="patrocinadoresLabel"
          style={
            getSponsorsLength(sponsorsData, 1) === 0 ||
            sponsorsLabel === null ||
            sponsorsLabel === ''
              ? { display: 'none' }
              : null
          }
        >
          {sponsorsLabel}
        </h6>
        <div id="sponsors">
          <Slider {...settings}>{getSponsors(sponsorsData)}</Slider>
        </div>
      </>
    );
  } else {
    return <div className="divDivision"></div>;
  }
};

export default SponsorsCarousel;
