import React, { useState } from 'react';

const EventRoomShop = ({ item }) => {
  const [termoBusca, setTermoBusca] = useState('');

  return item ? (
    <div className="productsHolder">
      <div
        className="row m-0"
        style={{
          background: 'rgb(249, 254, 255)',
          borderBottom: '1px solid rgb(200, 200, 200)',
        }}
      >
        <div className="col-12 px-2" style={{ marginTop: '10px' }}>
          <div className="input-group mb-3">
            <input
              type="search"
              placeholder="Pesquisar Produto"
              className="form-control search-input"
              onChange={(event) => {
                setTermoBusca(event.target.value);
              }}
            />
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-fw" aria-hidden="true" title="Copy to use search">
                
              </i>
            </span>
          </div>
        </div>
      </div>
      {JSON.parse(item.products)
        .filter((val) => {
          if (termoBusca === '') {
            return val;
          } else {
            return val.nome.toLowerCase().includes(termoBusca.toLowerCase());
          }
        })
        .map((product, index) => {
          return (
            <div key={index}>
              <a
                href={decodeURIComponent(product.link)}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className="remove-link event-products-link row no-gutters p-2"
              >
                <img
                  src={decodeURIComponent(product.url)}
                  alt={product.nome}
                  width={'150px'}
                  style={{
                    maxHeight: 'unset',
                    height: '100%',
                    aspectRatio: '1/1',
                    padding: '0',
                  }}
                />
                <div
                  className="products-texts pl-1"
                  style={{ width: 'calc(100% - 175px)', position: 'relative' }}
                >
                  <h3 style={{ fontSize: '16px' }}>{product.nome}</h3>
                  {product.descricao ? (
                    <p
                      style={{
                        color: '#000',
                        wordBreak: 'break-all',
                        fontSize: '14px',
                        paddingBottom: '10px',
                      }}
                    >
                      {product.descricao}
                    </p>
                  ) : null}
                  {product.preco ? (
                    <p className="m-0" style={{ position: 'absolute', bottom: '0' }}>
                      A partir de <b>R$ {(product.preco / 100).toLocaleString()}</b>
                    </p>
                  ) : null}
                </div>
              </a>
              <hr className="m-0" />
            </div>
          );
        })}
    </div>
  ) : null;
};

export default EventRoomShop;
