import {createAction, createReducer} from '@reduxjs/toolkit';
import {
    cleanCouponDiscount,
    getCartStorage,
    removeCartStorage,
    setCartStorage,
} from '../../session/Cart';

const INITIAL_STATE = getCartStorage();

export const addItem = createAction('ADD_ITEM');
export const finishItem = createAction('FINISH_ITEM');
export const removeItem = createAction('REMOVE_ITEM');
export const removeCouponDiscount = createAction('REMOVE_COUPON_DISCOUNT');

export default createReducer(INITIAL_STATE, {
    [addItem.type]: (state, action) => {
        const cartItem = action.payload;
        setCartStorage(cartItem);
        return cartItem;
    },
    [removeCouponDiscount.type]: (state) => {
        cleanCouponDiscount();
        return {...state, discountCoupon: undefined}
    },
    [finishItem.type]: (state, action) => {
        const {purchaseItems, discountCoupon} = action.payload;
        const cartItem = {...state, purchaseItems, discountCoupon};

        setCartStorage(cartItem);

        return cartItem;
    },
    [removeItem.type]: () => {
        removeCartStorage();
        return null;
    },
});
