import Api from './config/Api';

class LogServices {
  accessLog(data) {
    Api.instance
      .post('/accesslog/register', {
        eventId: data.eventId,
        participantId: data.participantId,
        roomId: data.roomId,
        standId: data.standId || null,
        tipo: data.tipo,
        url: data.url || null,
        socketId: data.socketId,
        isMobile: data.isMobile,
        targetId: data.targetId || null,
        targetType: data.targetType || null,
      })
      .catch();
  }
}

export default new LogServices();
