import Api from './config/Api';
import { setLoading } from '../store/loading/LoadingReducer';
import {
  addItem,
  finishItem,
  removeCouponDiscount,
  removeItem,
} from '../store/cart/CartReducer';
import {
  updatePurchase,
  updateOwnerTickets,
  updateRoomFair,
  updatePurchaseFullDiscount,
} from '../session/Auth';
import { refreshUserAuth } from '../store/auth/AuthReducer';
import { addMessage } from '../store/messages/MessagesReducer';
import {
  addCartItem,
  editCartItem,
  removeRemoveAllItem,
  removeRemoveItem,
} from '../store/cart/CartItemReducer';
import { isErrorCouponNotFound, responseError } from './exception/ApiException';
import { addEventInfo, addSetTicketId } from '../store/navigator/NavigatorReducer';
import axios from 'axios';
import bigDecimal from 'js-big-decimal';

class EventsServices {
  findEvents() {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await axios(
          process.env.REACT_APP_BASE_URL +
            '/get-event-url?url=' +
            window.location.hostname
        );

        if (response && response.data && response.data.payload) {
          dispatch(setLoading(false));
          return response.data.payload;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return null;
    };
  }

  findEventsPreview(eventId, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.get('/events/' + eventId + '/preview');

        if (response && response.data && response.data.payload) {
          const result = response.data.payload;

          dispatch(
            addEventInfo({
              eventId: result.eventId,
              primaryColor: result.primaryColor,
              secondaryColor: result.secondaryColor,
              urlLogo: result.urlLogo,
              eventTitle: result.eventName,
              exibir_traducao_automatica: result.exibir_traducao_automatica,
              exigir_senha_complexa: result.exigir_senha_complexa,
              modelEvent: result.modelEvent,
            })
          );

          dispatch(setLoading(false));
          return result;
        }
      } catch (e) {
        history.push('/');
      }

      dispatch(setLoading(false));

      return null;
    };
  }

  findEventsInformation(eventId) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.get('/events/information/' + eventId);

        if (response && response.data && response.data.payload) {
          dispatch(setLoading(false));
          return response.data.payload;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return null;
    };
  }

  ownerTicket(data, email, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/ownerTicket', data);

        if (response && response.data && response.data.payload) {
          const result = response.data.payload;
          updateOwnerTickets(result, true);
          dispatch(refreshUserAuth());

          if (result.tickets && result.tickets.length > 0) {
            dispatch(addSetTicketId(data.ticketId));
            await dispatch(
              this.findRoomFair({
                eventId: data.eventId,
                ticketId: data.ticketId,
                email,
              })
            );
          }

          dispatch(
            addMessage({
              message: 'Ingresso adicionado com sucesso!',
              level: 'success',
            })
          );

          if (result && result.tickets && result.tickets.length > 0) {
            const ticket = result.tickets.find(
              (item) => item.voucher === data.voucher
            );
            if (ticket && ticket.ticketId && ticket.eventId) {
              history.push('/eventLobby');
            }
          }
        }

        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(
          addMessage({ message: 'Ingresso indisponível!', level: 'warning' })
        );
      }
    };
  }

  externalVoucher(data, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/externalTicket', data);

        if (response) {
          dispatch(
            addMessage({
              message: 'Ingresso adicionado com sucesso!',
              level: 'success',
            })
          );

          history.push('/eventLobby');
        } else {
          dispatch(
            addMessage({
              message: 'Ingresso indisponível.',
              level: 'warning',
            })
          );
        }

        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(
          addMessage({
            message: 'Ingresso indisponível.',
            level: 'warning',
          })
        );
      }
    };
  }

  applyTicketId(data, email, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/applyTicketId', data);

        if (response && response.data && response.data.payload) {
          const result = response.data.payload;
          updateOwnerTickets(result, true);
          dispatch(refreshUserAuth());

          if (result.tickets && result.tickets.length > 0) {
            const ticketId = result.tickets[0].ticketId;

            dispatch(addSetTicketId(ticketId));

            await dispatch(
              this.findRoomFair({
                eventId: data.eventId,
                ticketId,
                email,
              })
            );
          }

          dispatch(
            addMessage({
              message: 'Ingresso aplicado com sucesso!',
              level: 'success',
            })
          );

          if (result && result.tickets && result.tickets.length > 0) {
            const ticket = result.tickets.find(
              (item) => item.voucher === data.voucher
            );
            if (ticket && ticket.ticketId && ticket.eventId) {
              history.push('/eventLobby');
            }
          }
        }

        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(
          addMessage({ message: 'Ingresso indisponível!', level: 'warning' })
        );
      }
    };
  }

  accessTicketEvent(email, eventId, tickets, history) {
    return async (dispatch) => {
      console.log('ACESSSING......... TICKETS', tickets);
      const ticketLast = this.getTicketLast(tickets);
      console.log('A2222.........', ticketLast);
      if (ticketLast) {
        const ticketId = ticketLast.ticketId;
        console.log('dispatching access.........', ticketId);
        dispatch(addSetTicketId(ticketId));

        await dispatch(
          this.findRoomFair(
            {
              eventId,
              ticketId,
              email,
            },
            history
          )
        );
        console.log('RETRUNIG TRUE access.........');
        return true;
      }

      return false;
    };
  }

  getTicketLast(tickets) {
    try {
      return tickets
        .map((m) => {
          return {
            ticketId: m.ticketId,
            updatedAt: m.updatedAt,
            participantId: m.participantId,
          };
        })
        .filter((f) => f.participantId !== null)
        .reduce((a, b) => {
          return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
        });
    } catch (e) {
      return null;
    }
  }

  eventFree(cart, email, participantId, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const data = {
          cart: {
            eventFree: true,
            eventId: cart.eventId,
            purchaseItems: cart.purchaseItems,
            discountCoupon: cart.discountCoupon,
          },
          participantId,
          formOfPayment: 'FREE',
        };
        const response = await Api.instance.post('/events/buyEvent', data);

        if (response && response.data && response.data.payload) {
          dispatch(removeItem());
          dispatch(removeRemoveAllItem());

          const result = response.data.payload;
          const tickets = updatePurchase(result);
          dispatch(refreshUserAuth());

          if (
            result &&
            result.purchase &&
            result.purchase.statusPayment &&
            tickets &&
            tickets.length > 0
          ) {
            await dispatch(
              this.accessTicketEvent(
                email,
                result.purchase.eventId,
                tickets,
                history
              )
            );
          } else {
            history.push('/home');
          }
        }
      } catch (e) {
        const error = e && e.response && e.response.data ? e.response.data : null;
        if (isErrorCouponNotFound(error)) {
          dispatch(removeCouponDiscount());
          dispatch(addMessage(responseError(e)));
          history.push('/shoppingFinish');
        } else {
          dispatch(setLoading(false));
          dispatch(addMessage(responseError(e)));
          history.push('/home');
        }
      }

      dispatch(setLoading(false));
    };
  }

  setCouponDiscount(cart, discountCoupon) {
    return (dispatch) => {
      dispatch(addItem({ ...cart, discountCoupon }));
    };
  }

  toStripeCheckout(data, setDiscountCoupon, history, email) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        console.log('CHECKOUT *********');
        let response = await Api.instance.post('/events/checkoutStripe', data);
        console.log('RESPONSE FRONT 222 ****', response.data.payload);
        if (response.data.payload.stripeId && !response.data.payload.purchase) {
          return {
            stripeId: response.data.payload.stripeId,
            purchase: null,
          };
        } else if (response.data.payload.purchase) {
          console.log(1, 'DISPATCHING.....', response.data.payload.purchase);
          response.data.payload.purchase.purchaseDetail = response.data.payload.purchase.purchaseDetail.map(
            (item) => ({
              ...item,
              participantId: response.data.payload.purchase.user.participantId,
              participantName: response.data.payload.purchase.user.participantName,
              statusPayment: 'PAGO',
              purchaseId: response.data.payload.purchase.purchaseId,
            })
          );
          dispatch(removeItem());
          dispatch(removeRemoveAllItem());
          console.log(2, 'UPDATING.....');
          const result = response.data.payload.purchase;
          const tickets = updatePurchaseFullDiscount(result);
          console.log(3, 'REFRESHING.....');
          await dispatch(refreshUserAuth());
          console.log(
            4,
            'NOT SELECTING.....',
            'RESULT',
            result,
            'STATUS',
            result.statusPayment
          );

          if (result && result.statusPayment && tickets && tickets.length > 0) {
            console.log(5, 'ACESSING TICKET.....');
            await dispatch(
              this.accessTicketEvent(email, result.eventId, tickets, history)
            );
          }

          dispatch(setLoading(false));
          console.log(6, 'RETURNING.....');
          return {
            stripeId: null,
            purchase: response.data.payload.purchase,
          };
        }
      } catch (e) {
        const error = e && e.response && e.response.data ? e.response.data : null;
        console.log('Erro na validação da compra.', e);
        if (isErrorCouponNotFound(error)) {
          dispatch(removeCouponDiscount());
          setDiscountCoupon({
            coupon: '',
            valueCoupon: 0,
          });
        }

        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));
    };
  }

  finishFreeEvent(data, setDiscountCoupon, history, email) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        let newData = data;

        const response = await Api.instance.post('/events/freeEvent', newData);

        if (response && response.data && response.data.payload) {
          dispatch(removeItem());
          dispatch(removeRemoveAllItem());

          const result = response.data.payload;
          const tickets = updatePurchase(result);
          dispatch(refreshUserAuth());

          if (
            result &&
            result.purchase &&
            result.purchase.statusPayment &&
            tickets &&
            tickets.length > 0
          ) {
            await dispatch(
              this.accessTicketEvent(
                email,
                result.purchase.eventId,
                tickets,
                history
              )
            );
            history.push('/home');
          } else {
            history.push('/home');
          }

          dispatch(setLoading(false));
        }
      } catch (e) {
        const error = e && e.response && e.response.data ? e.response.data : null;
        console.log('Erro na validação da compra.');
        if (isErrorCouponNotFound(error)) {
          dispatch(removeCouponDiscount());
          setDiscountCoupon({
            coupon: '',
            valueCoupon: 0,
          });
        }

        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));
    };
  }

  isTicketAvailable(item) {
    return item.quantitySold < item.quantityAvailable;
  }

  isAllTicketAvailable(tickets) {
    if (!tickets || tickets.length <= 0) {
      return false;
    }

    const result = tickets.filter(
      (item) => item.quantitySold < item.quantityAvailable
    );

    return !(result && result.length <= 0);
  }

  findDiscountCoupon(eventId, coupon, setDiscountCoupon) {
    return async (dispatch) => {
      try {
        if (eventId === '' || coupon === '') {
          return null;
        }

        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/coupon', {
          eventId,
          coupon,
        });

        if (response && response.data && response.data.payload) {
          dispatch(setLoading(false));
          return response.data.payload;
        }
      } catch (e) {
        const error = e && e.response && e.response.data ? e.response.data : null;

        if (isErrorCouponNotFound(error)) {
          dispatch(removeCouponDiscount());
          setDiscountCoupon({
            coupon: '',
            valueCoupon: 0,
          });
        }

        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));

      return null;
    };
  }

  findEventByParticipant(eventId, participantId, ticketId) {
    return async (dispatch) => {
      try {
        if (!eventId || !participantId) {
          return;
        }

        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/participant', {
          eventId,
          participantId,
          ticketId,
        });

        if (response && response.data && response.data.payload) {
          const { tickets } = updateOwnerTickets(response.data.payload, false);
          dispatch(refreshUserAuth());
          dispatch(setLoading(false));

          return tickets || [];
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));

      return [];
    };
  }

  findRoomFair(data, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/events/roomsFair', data);

        if (response && response.data && response.data.payload) {
          const roomsFair = response.data.payload;

          const { managerEvents } = updateRoomFair(roomsFair);
          dispatch(refreshUserAuth());

          if (roomsFair && roomsFair.length > 0) {
            if (history) {
              history.push('/eventLobby');
              dispatch(setLoading(false));
              return;
            }
            dispatch(setLoading(false));
            return { roomsFair, managerEvents };
          } else {
            dispatch(
              addMessage({
                message: 'Nenhuma sala/stands encontrado!',
                level: 'warning',
              })
            );
          }
        }
      } catch (e) {
        dispatch(setLoading(false));
        if (e && e.response && e.response.data) {
          dispatch(addMessage(responseError(e)));
        }
      }

      dispatch(setLoading(false));

      return null;
    };
  }

  findPrivateRoom(data) {
    return async (dispatch) => {
      try {
        const response = await Api.instance.post('/events/findPrivateRoom', data);

        return response;
      } catch (e) {
        dispatch(addMessage(responseError(e)));
      }
      return false;
    };
  }

  findRoomsFairType(roomsFair, roomFairId, typeEvent) {
    return roomsFair.find(
      (item) =>
        item.roomFairId === Number(roomFairId) && item.typeEvent === typeEvent
    );
  }

  async getRoomsFairDataDs(token, eventId) {
    const response = await axios(
      process.env.REACT_APP_BASE_URL +
        '/getDsData?token=' +
        token +
        '&eventId=' +
        eventId
    );
    return response;
  }

  addCartItem(cartItem) {
    return (dispatch) => {
      cartItem.purchaseItems = cartItem.purchaseItems.filter(
        (item) => item.quantity > 0
      );

      for (let item of cartItem.purchaseItems) {
        dispatch(addCartItem(item));
      }

      cartItem.purchaseItems = [];
      dispatch(addItem({ ...cartItem, discountCoupon: null }));
    };
  }

  updateCartItem(purchaseItems, ticketId, isRemoved = false) {
    return (dispatch) => {
      if (isRemoved && ticketId) {
        dispatch(removeRemoveItem(ticketId));
        return;
      }
      for (let item of purchaseItems) {
        dispatch(editCartItem(item));
      }
    };
  }

  findTicketAvailable(data) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        await Api.instance.post('/events/ticketAvailable', data);
        dispatch(setLoading(false));
        return true;
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return false;
    };
  }

  finishCartItem(eventId, cartItem) {
    return async (dispatch) => {
      cartItem.purchaseItems = cartItem.purchaseItems.filter(
        (item) => item.quantity > 0
      );

      const tickets = cartItem.purchaseItems.map((m) => {
        return {
          ticketId: m.ticketId,
          quantity: m.quantity,
        };
      });

      const result = await dispatch(this.findTicketAvailable({ eventId, tickets }));

      if (result) {
        dispatch(finishItem(cartItem));
        return true;
      }

      return false;
    };
  }

  calculateValueDiscount(items, discountCoupon) {
    if (discountCoupon) {
      const result = items.find((item) => item.ticketId === discountCoupon.ticketId);
      if (result) {
        return bigDecimal.multiply(
          result.total,
          bigDecimal.divide(discountCoupon.percentage, 100, 2)
        );
      }
    }
    return 0;
  }

  calculateCartValue(purchaseItems, discountCoupon, purchaseDetail) {
    let valueBuy = 0;
    let valueDiscount = 0;
    let valueTotal = 0;

    if (purchaseItems && purchaseItems.length > 0 && discountCoupon === null) {
      valueBuy = purchaseItems.map((item) => item.total).reduce((a, b) => a + b, 0);
      valueDiscount = 0;
      valueTotal = valueBuy - valueDiscount;

      return {
        valueBuy,
        valueDiscount,
        valueTotal,
      };
    }
    if (purchaseItems && purchaseItems.length > 0) {
      valueBuy = purchaseItems.map((item) => item.total).reduce((a, b) => a + b, 0);
      valueDiscount = this.calculateValueDiscount(purchaseItems, discountCoupon);
      valueTotal = valueBuy - valueDiscount;

      return {
        valueBuy,
        valueDiscount,
        valueTotal,
      };
    }

    if (purchaseDetail && purchaseDetail.length > 0) {
      valueBuy = purchaseDetail.map((item) => item.total).reduce((a, b) => a + b, 0);
      valueDiscount = this.calculateValueDiscount(purchaseDetail, discountCoupon);
      valueTotal = valueBuy - valueDiscount;

      return {
        valueBuy,
        valueDiscount,
        valueTotal,
      };
    }

    return {
      valueBuy,
      valueDiscount,
      valueTotal,
    };
  }

  onChangeValues(purchaseItems, ticketId, quantity, isRemoved = false) {
    if (isRemoved) {
      return purchaseItems.filter((f) => f.ticketId !== ticketId);
    }

    return purchaseItems.map((item) => {
      if (item.ticketId === ticketId) {
        const qtd = Number(quantity);
        return {
          ...item,
          quantity: qtd,
          total: qtd * item.value,
        };
      }
      return item;
    });
  }

  noTicketFound() {
    return (dispatch) => {
      dispatch(
        addMessage({
          message: 'Aplique um ingresso para visualizar.',
          level: 'warning',
        })
      );
    };
  }
}

export default new EventsServices();
