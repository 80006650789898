import React, {memo} from 'react';

const SelectState = ({value, list, setValue}) => {
    return (
        <select
            id="state"
            name="state"
            className="form-control"
            required
            value={value || -1}
            onChange={e => setValue(e.target.value)}
        >
            <option value={-1}>...</option>
            {
                list && list.map((item, index) => {
                    return (
                        <option key={index} value={item.stateId}>{item.stateUF}</option>
                    )
                })
            }
        </select>
    )
}

export default memo(SelectState);