import {getAccessToken} from "./Api";

const baseUrl = process.env.REACT_APP_BASE_URL;

class ClientNotifications extends EventSource {
  constructor(roomFairId) {
    super(
      baseUrl +
        '/notifications/users?roomFairId=' +
        roomFairId +
        '&token=Bearer ' + getAccessToken()
    );
  }
}

export default ClientNotifications;
