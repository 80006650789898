import React, { memo } from 'react';
import Moment from 'react-moment';
import './InfoDetailEvent.scss';
import { getDate, getTime } from '../../utils/MaskInput';

const InfoDetailEvent = ({ info, infoHtml, preview = false }) => {
  return (
    <div id="cxinfo" className="card border-light mb-3 border-shadow">
      <div className="card-header text-light font-weight-bolder mt-3 ml-3 mr-3 mb-0 pb-0 border-0">
        <h3 className="text-dark">
          <strong className="notranslate">{info.eventName}</strong>
        </h3>
        <h6>
          <strong className="text-color-custom">
            <Moment format="DD/MM/yyyy">{getDate(info.dateStart)}</Moment> -{' '}
            <time>{getTime(info.dateStart)}</time>
            <br />
            {/* Evento Online */}
          </strong>
        </h6>
      </div>
      <div className="card-header m-0 p-0">
        <div className="socialMedias">
          <div className="col-12 text-center text-sm-right">
            {preview ? (
              <>
                <button className="btn-blue facebook-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-facebook-f" />
                </button>
                <button className="btn-blue linkedin-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-linkedin" />
                </button>
                <button className="btn-blue twitter-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-twitter" />
                </button>
                <button className="btn-blue instagram-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-instagram" />
                </button>
                <button className="btn-danger instagram-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-youtube-play" />
                </button>
                <button className="btn-blue globe-btn btn custom-class rounded-circle mr-3">
                  <i className="fa fa-globe" />
                </button>
              </>
            ) : (
              <>
                {info.url_facebook ? (
                  <a
                    target="_blank"
                    className="btn-blue facebook-btn btn custom-class rounded-circle mr-3"
                    href={info.url_facebook}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook-f" />
                  </a>
                ) : (
                  <> </>
                )}
                {info.url_linkedin ? (
                  <a
                    target="_blank"
                    className="btn-blue linkedin-btn btn custom-class rounded-circle mr-3"
                    href={info.url_linkedin}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                ) : (
                  <> </>
                )}
                {info.url_twitter ? (
                  <a
                    target="_blank"
                    className="btn-blue twitter-btn btn custom-class rounded-circle mr-3"
                    href={info.url_twitter}
                    rel="canonical noopener noreferrer"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                ) : (
                  <> </>
                )}
                {info.url_instagram ? (
                  <a
                    target="_blank"
                    className="btn-blue instagram-btn btn custom-class rounded-circle mr-3"
                    href={info.url_instagram}
                    rel="canonical noopener noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                ) : (
                  <> </>
                )}
                {info.url_youtube ? (
                  <a
                    target="_blank"
                    className="btn-danger youtube-btn btn custom-class rounded-circle mr-3"
                    href={info.url_youtube}
                    rel="canonical noopener noreferrer"
                  >
                    <i className="fa fa-youtube-play" />
                  </a>
                ) : (
                  <> </>
                )}
                {info.url_custom_social ? (
                  <a
                    target="_blank"
                    className="btn-blue globe-btn btn custom-class rounded-circle mr-3"
                    href={info.url_custom_social}
                    rel="canonical noopener noreferrer"
                  >
                    <i className="fa fa-globe" />
                  </a>
                ) : (
                  <> </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card-body m-3">
        <div dangerouslySetInnerHTML={{ __html: infoHtml || '' }} />
      </div>
    </div>
  );
};

export default memo(InfoDetailEvent);
