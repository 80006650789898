import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import NotificationServices from '../../services/NotificationServices';
import { useDispatch } from 'react-redux';

const QA = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [messageList, setMessageList] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [autoScroll, setAutoScroll] = useState(true);
  const [moderatorOn, setModeratorOn] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [changeFile, setChangeFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [currentModalData, setCurrentModalData] = useState(null);
  const [acceptImg, setAcceptImg] = useState(false);

  const changedFile = async (event) => {
    if (event.target.files[0] !== undefined) {
      if (
        event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpg'
      ) {
        setChangeFile(event.target.files[0]);

        const uploadImage = await sendImg(event.target.files[0]);
        setFileUrl(uploadImage);
      } else {
        alert('Apenas arquivos de imagem .JPG ou .PNG .');
        setChangeFile('');
      }
    }
  };

  const sendImg = async (file) => {
    const fileParts = file.name.split('.');
    const fileType = fileParts[1];

    const urlFile = await dispatch(NotificationServices.sendImg(fileType, file));

    return urlFile;
  };

  const deleteMessage = (index) => {
    const deletedMsg = {
      room: 'socket_room_' + location.pathname.replace('/room/', ''),
      messageIndex: index,
      token: auth.token,
      interatividade: 'QA',
    };
    window.socket.emit('delete_message', deletedMsg);
  };

  const sendMessage = () => {
    if ((fileUrl !== '' || currentMessage !== '') && !isModerator) {
      const newMsg = {
        room: 'socket_room_' + location.pathname.replace('/room/', ''),
        message: currentMessage,
        sender: auth.username,
        sender_id: auth.participantId,
        avatar: auth.avatar,
        photo: fileUrl,
        token: auth.token,
      };
      window.socket.emit('send_qa_message', newMsg);
      setMessageList((prevState) => [...prevState, newMsg]);
      setFileUrl('');
      setChangeFile('');
    }
    setCurrentMessage('');
  };

  const transformMessage = (messages) => {
    const transformedMessages = [];
    for (let index = 0; index < messages.length; index++) {
      const item = messages[index];
      const transformedMessage = {
        message: item.message,
        sender: item.username,
        avatar: item.avatar,
        photo: item.photo,
      };
      transformedMessages.push(transformedMessage);
    }
    return transformedMessages;
  };

  const linkify = (text) => {
    const urlRegex = /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
    return text.replace(urlRegex, (url) => {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return (
        '<a target="_blank" href="' + url + '" title="' + url + '">' + url + '</a>'
      );
    });
  };

  const fetchData = useCallback(async () => {
    const roomFairId = location.pathname.replace('/room/', '');
    const messagesHistory = await dispatch(
      NotificationServices.recoverQAMessages(roomFairId, 'QA')
    );
    const transformedMsgs = transformMessage(messagesHistory);
    setMessageList(transformedMsgs);
    if (props.dados.acceptImg === '1') {
      setAcceptImg(true);
    }
  }, [dispatch, location.pathname, props.dados.acceptImg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const autoScrollFunc = useCallback(() => {
    if (autoScroll) {
      animateScroll.scrollToBottom({
        containerId: 'qaHolder',
        smooth: true,
        duration: 200,
      });
    }
    setModeratorOn(props.dados.moderatorOn);
    if (props.dados.moderatorOn) {
      setIsModerator(props.dados.moderatorEmail === auth.email);
    }
  }, [auth.email, props.dados.moderatorEmail, props.dados.moderatorOn, autoScroll]);

  useEffect(() => {
    autoScrollFunc();
  });

  const handler = (data) => {
    setMessageList((prevState) => [...prevState, data]);
  };

  useEffect(() => {
    if (window.socket) {
      window.socket.on('received_qa_message', handler);
      window.socket.on('updateAll', fetchData);
      return () => {
        window.socket.off('received_qa_message', handler);
        window.socket.off('updateAll', fetchData);
      };
    }
  });

  return window.socket ? (
    <>
      <div className="row mx-0" style={{ height: '100%' }}>
        <div
          className="chat-container"
          style={{ width: '100%', height: '100%', background: '#F9FEFF' }}
        >
          <div className="chat-feed" id="qaHolder">
            <ul className="chat-msgList">
              {messageList.map((item, index) => {
                return (
                  <li key={'msg' + index}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        float: 'left',
                        marginRight: 10,
                        backgroundImage: `url(${item.avatar})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        borderRadius: '50%',
                      }}
                    ></div>

                    <div className="msgContainer">
                      <div>
                        <strong>{item.sender}</strong>
                        {moderatorOn && isModerator ? (
                          <>
                            <button
                              onClick={() => {
                                deleteMessage(index);
                              }}
                              className="btn-danger btn btn-sm ml-2"
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </>
                        ) : null}
                      </div>
                      {item.photo ? (
                        <img
                          alt={item.message !== '' ? item.message : item.sender}
                          src={item.photo}
                          className="qaPhoto"
                          type="button"
                          data-toggle="modal"
                          data-target="#photoModal"
                          onClick={() => {
                            setCurrentModalData(item);
                          }}
                        />
                      ) : null}
                      <p
                        dangerouslySetInnerHTML={{ __html: linkify(item.message) }}
                      ></p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div style={{ position: 'relative' }}>
            <div className="row chat-msgBox chatInputHolder p-0">
              {!isModerator ? (
                <>
                  {acceptImg ? (
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={(e) => {
                            changedFile(e);
                          }}
                          id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                          style={{
                            background: '#F9FEFF',
                            borderRadius: '0',
                            padding: '0.375rem 6px',
                          }}
                        >
                          <i className="fa fa-picture-o mr-3" aria-hidden="true"></i>
                          {changeFile ? changeFile.name : 'Escolher Imagem'}
                        </label>
                      </div>
                    </div>
                  ) : null}
                  <input
                    id="textMessageInput"
                    className="form-control custom-border"
                    value={currentMessage}
                    onChange={(e) => {
                      setCurrentMessage(e.target.value);
                    }}
                    type="text"
                    onKeyUp={(e) =>
                      e.keyCode === 13 && sendMessage() && e.preventDefault()
                    }
                    style={{
                      background: '#F9FEFF',
                      border: 'none',
                      borderBottom: '1px solid  #ced4da',
                      borderRadius: '0',
                      paddingLeft: '10px',
                    }}
                    placeholder="Digite sua Mensagem"
                  />
                  <div className="btn btn-event-send-msg" onClick={sendMessage}>
                    <i className="fa fa-chevron-right" />
                  </div>
                </>
              ) : null}
              <div className="row no-gutters mt-2 pr-2 scroll-row">
                <div className="form-check form-switch" id="autoScrollCheckHolder">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={autoScroll}
                    onChange={() => {
                      setAutoScroll(!autoScroll);
                    }}
                    id="autoScrollCheck"
                  />
                  <label className="form-check-label" htmlFor="autoScrollCheck">
                    Rolagem automática
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentModalData ? (
        <div
          className="modal fade"
          id="photoModal"
          tabIndex="-1"
          aria-labelledby="photoModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content qaModal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="photoModalLabel">
                  Foto de {currentModalData.sender}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  alt={
                    currentModalData.message !== ''
                      ? currentModalData.message
                      : currentModalData.sender
                  }
                  src={currentModalData.photo}
                  className="qaPhotoModal"
                />
                <p style={{ marginTop: 40, maxWidth: 600, wordBreak: 'break-word' }}>
                  {currentModalData.message}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
};

export default QA;
