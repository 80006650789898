import React, { useEffect, useState } from 'react';
import MainPreview from './mainPreview';
import ListPreview from './listPreview';
import moment from 'moment';
import 'moment/locale/pt-br';
require('./styles.css');

const Agenda = (props) => {
  const [agendaData, setAgendaData] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [listData, setListData] = useState(null);
  const [textHeight, setTextHeight] = useState(0);
  const [listHeight, setListHeight] = useState(0);

  const [reload, setReload] = useState(false);

  const handleResizeAgenda = () => {
    if (
      document.getElementById('mainImage') &&
      document.getElementById('mainImage').clientHeight !== null
    ) {
      setTextHeight(document.getElementById('mainImage').clientHeight);
      setListHeight(
        document.getElementById('mainPalestranteHolder').clientHeight +
          document.getElementById('mainPreviewHolder').clientHeight -
          12
      );
    }
  };

  const init = () => {
    if (
      props.data !== null &&
      props.data.agenda !== null &&
      props.data.agenda.length !== 0
    ) {
      setAgendaData(props.data.agenda);
      const agendaArray = JSON.parse(JSON.stringify(props.data.agenda));
      let mainItem = null;
      let indexMain = null;

      const dateNow = moment(new Date());
      agendaArray.forEach((item) => {
        let dataFim = item.data;

        if (item.hora_fim <= item.hora_inicio) {
          dataFim = moment(item.data, 'YYYY-MM-DD').add(1, 'days').format();
        }
        item.isLive = dateNow.isBetween(
          item.data.substring(0, 11) + item.hora_inicio + '+00:00',
          dataFim.substring(0, 11) + item.hora_fim + '+00:00'
        );
        item.isOld = dateNow.isSameOrAfter(
          dataFim.substring(0, 11) + item.hora_fim + '+00:00'
        );
        item.isFuture = dateNow.isSameOrBefore(
          item.data.substring(0, 11) + item.hora_inicio + '+00:00'
        );
      });

      const finalArray = [];

      for (let index = 0; index < agendaArray.length; index++) {
        const element = agendaArray[index];
        if (!element.isOld) {
          finalArray.push(element);
        }
      }

      for (let index = 0; index < finalArray.length; index++) {
        const element = finalArray[index];
        if (!element.isOld) {
          mainItem = element;
          indexMain = index;
          break;
        }
      }

      if (indexMain !== null) {
        finalArray.splice(indexMain, 1);
      }

      setMainData(mainItem);
      setListData(finalArray);
    }
  };

  const addListenters = () => {
    if (agendaData && agendaData !== null) {
      window.addEventListener('resize', handleResizeAgenda);
      handleResizeAgenda();
      return () => window.removeEventListener('resize', handleResizeAgenda);
    }
  };

  useEffect(init, [reload]);
  useEffect(addListenters);

  if (
    agendaData &&
    agendaData !== null &&
    (mainData !== null || listData.length > 0)
  ) {
    return (
      <div className="row no-gutters">
        <div
          className={listData.length === 0 ? 'col-12' : 'col-xl-8 col-lg-7'}
          id="mainAgendaHolder"
        >
          <MainPreview
            mainData={mainData}
            handleResizeAgenda={handleResizeAgenda}
            textHeight={textHeight}
            setReload={setReload}
            reload={reload}
          />
        </div>
        <div
          className={listData.length === 0 ? 'hide' : 'col-xl-4 col-lg-5'}
          id="agendaListHolder"
          style={{ height: listHeight }}
        >
          <ListPreview listData={listData} />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Agenda;
