import React, { memo } from 'react';
import Zoom from '../zoom/index';
import { Link } from 'react-router-dom';
import { existUrl } from '../../utils/Ultis';
import './ItemRoomFair.scss';

const ItemRoomFair = ({ roomsFair, openTab, preview = false }) => {
  const twoButtons = existUrl(roomsFair && roomsFair.urlChatVideo);
  return (
    <>
      {roomsFair && (
        <div className="row background-custom-div border-shadow rounded">
          {twoButtons && (
            <div className="col-12 col-lg-6 mb-3 mb-lg-0">
              {!preview ? (
                <Link
                  target="_blank"
                  to="route"
                  className="text-decoration-none"
                  onClick={(e) => openTab(e, roomsFair && roomsFair.urlChatVideo)}
                >
                  <button className="btn btn-dark btn-customcolor btn-lg btn-block btn-color p-3 bg-gradient-black">
                    {roomsFair.nome_plateia_virtual
                      ? roomsFair.nome_plateia_virtual
                      : 'PLATEIA VIRTUAL'}
                  </button>
                </Link>
              ) : (
                <button className="btn btn-dark btn-customcolor btn-lg btn-block btn-color p-3 bg-gradient-black">
                  {roomsFair.nome_plateia_virtual
                    ? roomsFair.nome_plateia_virtual
                    : 'PLATEIA VIRTUAL'}
                </button>
              )}
            </div>
          )}

          <div
            className={
              !twoButtons
                ? 'col-12 col-lg-12 mb-3 mb-lg-0'
                : 'col-12 col-lg-6 mb-3 mb-lg-0'
            }
          >
            {!preview ? (
              <>
                {roomsFair.formato === 'ZOOM' ? (
                  <Zoom roomId={roomsFair.roomFairId} eventId={roomsFair.eventId} />
                ) : (
                  <Link
                    to={'/room/' + roomsFair.roomFairId}
                    className="text-decoration-none"
                  >
                    <button className="btn btn-dark btn-customcolor btn-lg btn-block btn-color p-3 bg-gradient-black">
                      {roomsFair.nome_botao_conexao
                        ? roomsFair.nome_botao_conexao
                        : 'CONECTE-SE'}
                    </button>
                  </Link>
                )}
              </>
            ) : (
              <button className="btn btn-dark btn-customcolor btn-lg btn-block btn-color p-3 bg-gradient-black">
                {roomsFair.nome_botao_conexao
                  ? roomsFair.nome_botao_conexao
                  : 'CONECTE-SE'}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ItemRoomFair);
