import Avatar from 'react-avatar';
import React, { memo, useEffect, useState } from 'react';
import LinkMediaSocial from './ListMidiaSocial';
import { Link } from 'react-router-dom';
import { existUrl } from '../../utils/Ultis';
import { isMobile } from 'react-device-detect';

const InfoRepresentative = ({ roomsFair, openTab, openTabLog, preview = false }) => {
  const [info, setInfo] = useState({
    description: 'Nenhum informação encontrada',
    urlAvatar: '',
  });

  const [linkMediaSocial, setLinkMediaSocial] = useState([]);

  useEffect(() => {
    if (roomsFair && roomsFair.infoRepresentative) {
      setInfo(roomsFair.infoRepresentative);
    }

    if (roomsFair && roomsFair.linkMediaSocial) {
      setLinkMediaSocial(roomsFair.linkMediaSocial);
    }
  }, [roomsFair]);

  return (
    <>
      <div className="row text-center" style={{ justifyContent: 'flex-end' }}>
        <div className="col-xl-6 col-lg-12 text-center">
          <div className="row">
            <div className="col-12 text-center pt-2">
              <p className="mt-4">
                <strong>{info.attendantTitle}</strong>
              </p>
              <p className="descriptionAttendant">{info.description}</p>
            </div>
            {existUrl(roomsFair && roomsFair.urlChatVideo) && (
              <div className="col-12 text-center pt-2">
                {preview ? (
                  <button className="btn btn-block btn-blue">VideoChat</button>
                ) : (
                  <Link
                    target="_blank"
                    to="route"
                    className="btn btn-block btn-blue"
                    onClick={(e) =>
                      openTabLog(
                        e,
                        roomsFair && roomsFair.urlChatVideo,
                        'VIDEO_CHAT'
                      )
                    }
                  >
                    VideoChat
                  </Link>
                )}
              </div>
            )}

            {existUrl(roomsFair && roomsFair.urlGifts) && (
              <div className="col-12 text-center pt-2">
                {preview ? (
                  <button className="btn btn-block btn-blue">
                    {roomsFair.nome_botao}
                  </button>
                ) : (
                  <Link
                    target="_blank"
                    to="route"
                    className="btn btn-block btn-blue"
                    onClick={(e) =>
                      openTabLog(e, roomsFair && roomsFair.urlGifts, 'E-COMMERCE')
                    }
                  >
                    {roomsFair.nome_botao}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 text-center pt-2">
          <div className="color-border-img">
            <Avatar
              googleId="118096717852922241760"
              size="110"
              round={true}
              src={info.urlAvatar}
            />
          </div>
          {linkMediaSocial && linkMediaSocial.length > 0 && (
            <LinkMediaSocial
              mediaSocial={linkMediaSocial}
              openTab={openTab}
              preview={preview}
            />
          )}
        </div>
        <div
          className="row"
          style={
            isMobile
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-end', marginRight: '20px' }
          }
        >
          <div className="col-xl-5 col-lg-12 text-center my-auto p-lg-4">
            <img
              className="image-stand-logo"
              src={roomsFair && roomsFair.urlLobby ? roomsFair.urlLobby : ''}
              width="100%"
              alt="LOGO"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(InfoRepresentative);
