import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-timezone';
import Slider from 'react-slick';
import AddToCalendar from '../react-add-to-calendar/lib/ReactAddToCalendar';
require('./styles.css');

const Programacao = (props) => {
  const [mainData, setMainData] = useState(null);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const getSlidesToShow = (length, max) => {
    return length <= max ? length : max;
  };

  const getResponsive = (length) => {
    return [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: getSlidesToShow(length, 1),
        },
      },
    ];
  };

  const init = () => {
    if (
      props.data !== null &&
      props.data.programacao !== null &&
      props.data.programacao.length !== 0
    ) {
      setMainData(props.data.programacao);
    }
  };

  const getPalestras = (palestras) => {
    return palestras.length > 0 ? (
      <ul className="palestrasUl">
        {palestras.map((palestra, indexPalestra) => {
          return palestra.horarios.length > 0 ? (
            <li key={indexPalestra} className="row">
              <div className="col-xl-4">
                <h3>{palestra.nome + ' - ' + palestra.tema}</h3>
                {getPalestraAgenda(palestra)}
                <p>{palestra.descricao}</p>
              </div>
              <div className="col-xl-8 carouselsHolder">
                {getPalestrantes(palestra)}
                {getModeradores(palestra) !== null ? <hr /> : null}
                {getModeradores(palestra)}
              </div>
            </li>
          ) : null;
        })}
      </ul>
    ) : null;
  };

  const getHoraFim = (item) => {
    let dataFim = item.data;
    if (item.hora_fim <= item.hora_inicio) {
      dataFim = moment(item.data, 'YYYY-MM-DD').add(1, 'days').format();
    }
    return dataFim.substring(0, 11) + item.hora_fim + '+00:00';
  };

  const getPalestraAgenda = (palestra) => {
    return (
      <>
        <h5>Horários</h5>
        <ul className="palestraHorarios">
          {palestra.horarios.map((palestraHorario, indexPalestraHorario) => {
            return (
              <li key={indexPalestraHorario} title="Adicionar ao calendário">
                <AddToCalendar
                  buttonLabel={''}
                  event={{
                    title: palestra.nome,
                    description: palestra.descricao,
                    location:
                      process.env.REACT_APP_MAIN_URL +
                      '/room/' +
                      palestraHorario.sala_id,
                    startTime:
                      palestraHorario.data.substring(0, 11) +
                      palestraHorario.hora_inicio +
                      '+00:00',
                    endTime: getHoraFim(palestraHorario),
                  }}
                  buttonIconClass={''}
                  buttonTemplate={{ calendar: 'calendar' }}
                />
                {' - '}
                <Moment format="LLL" locale="pt-br" tz={userTimeZone}>
                  {palestraHorario.data.substring(0, 11) +
                    palestraHorario.hora_inicio +
                    '+00:00'}
                </Moment>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const getPalestrantes = (item) => {
    let palestrantesLength = 0;
    for (let index = 0; index < item.palestrantes.length; index++) {
      const element = item.palestrantes[index];
      if (element.tipo === 'PALESTRANTE') {
        palestrantesLength++;
      }
    }
    return palestrantesLength > 0 ? (
      <>
        {/* <h3 className="subTitle">Palestrantes</h3> */}
        <div className="palestrantesHolder">
          <Slider
            {...settings}
            slidesToShow={getSlidesToShow(palestrantesLength, 2)}
            responsive={getResponsive(palestrantesLength)}
          >
            {item.palestrantes.map((palestrante, indexPalestrante) => {
              return palestrante.tipo === 'PALESTRANTE' ? (
                <div key={indexPalestrante} className="palestranteItem">
                  <img
                    className="palestranteImg"
                    src={palestrante.url_foto}
                    alt={palestrante.nome}
                  />
                  <h5>{palestrante.nome}</h5>
                  <h4>{palestrante.titulo}</h4>
                  <p>{palestrante.mini_bio}</p>
                </div>
              ) : null;
            })}
          </Slider>
        </div>
      </>
    ) : null;
  };

  const getModeradores = (item) => {
    let moderadoresLength = 0;
    for (let index = 0; index < item.palestrantes.length; index++) {
      const element = item.palestrantes[index];
      if (element.tipo === 'MODERADOR') {
        moderadoresLength++;
      }
    }
    return moderadoresLength > 0 ? (
      <>
        {/* <h3 className="subTitle">Moderadores</h3> */}
        <div className="palestrantesHolder">
          <Slider
            {...settings}
            slidesToShow={getSlidesToShow(moderadoresLength, 2)}
            responsive={getResponsive(moderadoresLength)}
          >
            {item.palestrantes.map((moderador, indexModerador) => {
              return moderador.tipo === 'MODERADOR' ? (
                <div key={indexModerador} className="palestranteItem">
                  <img
                    className="palestranteImg"
                    src={moderador.url_foto}
                    alt={moderador.nome}
                  />
                  <h5>{moderador.nome}</h5>
                  <h4>{moderador.titulo}</h4>
                  <p>{moderador.mini_bio}</p>
                </div>
              ) : null;
            })}
          </Slider>
        </div>
      </>
    ) : null;
  };

  useEffect(init, []);

  useEffect(() => {
    $('#accordionProgramacao .collapse').on('shown.bs.collapse', function () {
      var scrollTo = $(this).parent().attr('id');
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#' + scrollTo).offset().top,
        },
        1000
      );
    });
  }, [mainData]);

  if (mainData && mainData !== null && mainData.length > 0) {
    return (
      <>
        <h3 className="titleProgramacao">Programação</h3>
        <div id="accordionProgramacao" className="container-fluid">
          {mainData.map((item, index) => {
            return (
              <div key={index} className="card" id={'cardCollapse' + index}>
                <div
                  className="card-header"
                  id={'heading' + index}
                  data-toggle="collapse"
                  data-target={'#collapse' + index}
                  aria-expanded={index === 0 ? 'true' : 'false'}
                  aria-controls={'collapse' + index}
                >
                  <i className="fa fa-angle-up rotate-icon"></i>
                  <i className="fa fa-angle-down rotate-icon"></i>
                  <h5 className="mb-0 palestraNome">{item.room.nome}</h5>
                </div>
                <div
                  id={'collapse' + index}
                  className={index === 0 ? 'collapse show' : 'collapse'}
                  aria-labelledby={'heading' + index}
                  data-parent="#accordionProgramacao"
                >
                  <div className="card-body">{getPalestras(item.palestras)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Programacao;
