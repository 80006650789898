import {useSelector} from 'react-redux';
import React from 'react';
import checkIcon from '../../assets/images/check.svg';
import errorIcon from '../../assets/images/error.svg';
import infoIcon from '../../assets/images/info.svg';
import warningIcon from '../../assets/images/warning.svg';
import Toast from "./Toast";


export default function Messages() {
    const {messages} = useSelector((state) => state.messages);

    const getToast = (type, message) => {
        const id = Math.floor((Math.random() * 101) + 1);

        switch (type) {
            case 'success':
                return {
                    id,
                    title: 'Sucesso',
                    description: message,
                    backgroundColor: '#5cb85c',
                    icon: checkIcon
                }
            case 'danger':
                return {
                    id,
                    title: 'Erro',
                    description: message,
                    backgroundColor: '#d9534f',
                    icon: errorIcon
                }
            case 'info':
                return {
                    id,
                    title: 'Informação',
                    description: message,
                    backgroundColor: '#5bc0de',
                    icon: infoIcon
                }
            case 'warning':
                return {
                    id,
                    title: 'Alerta',
                    description: message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }
            default:
                return {
                    id,
                    title: 'Erro',
                    description: message,
                    backgroundColor: '#d9534f',
                    icon: errorIcon
                }
        }
    }

    return (
        <>
            {
                messages && messages.filter((obj) => obj.message !== '').map((item, index) => (
                    <Toast key={index}
                           toast={getToast(item.level, item.message)}
                    />
                ))
            }
        </>
    );
}
