import React, {memo, useEffect, useState} from 'react';

const NetworkLetterList = (props) => {
    const [letterList, setLetterList] = useState([]);
    const [letterIndex, setLetterIndex] = useState();

    const genCharArray = () => {
        const charA = "a";
        const charZ = "z";
        let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);

        for (; i <= j; ++i) {
            a.push(String.fromCharCode(i));
        }

        return a;
    }

    const setColor = (index) => {
        setLetterIndex(index);
    }

    useEffect(() => {
        if (letterList.length<=0) {
            setLetterList(genCharArray());
        }

    }, [letterList]);

    return (
        <>
            <div className="ml-2 mr-2 m-0">
                <button
                    type="button"
                    className={`btn btn-link btn-sm text-decoration-none ${letterIndex === 27 ? "text-color-custom" : "text-semi-light"} p-0`}
                    value={''}
                    onClick={e => props.getLetter('') && setColor(27)}
                >
                    <i className="fa fa-circle"/> <strong>A-Z</strong>
                </button>
            </div>
            {
                letterList && letterList.length>0 && letterList.map((item, index) => {
                    return (
                        <div className="ml-2 mr-2 m-0 p-0" key={index}>
                            <button
                                type="button"
                                className={`btn btn-link btn-sm text-decoration-none ${letterIndex === index ? "text-color-custom" : "text-semi-light"} p-0`}
                                value={item}
                                onClick={e => props.getLetter(item) && setColor(index)}
                            >
                                <i className="fa fa-circle"/> <strong>{item.toUpperCase()}</strong>
                            </button>
                        </div>
                    );
                })
            }
        </>
    )
};

export default memo(NetworkLetterList);
