import './Main.css';
import React from 'react';

export default (props) => {
  return (
    <>
      <main
        className={
          process.env.REACT_APP_ENV_SHOW === 'TRUE'
            ? 'content container-fluid p-0 alert-show'
            : 'content container-fluid p-0'
        }
      >
        {props.children}
      </main>
    </>
  );
};
