import React from 'react';
import Avatar from 'react-avatar';
import LinkMediaSocial from '../stands/ListMidiaSocial';

const NetworkModal = ({ participant, onClose }) => {
  if (!participant) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          width: '90%',
          maxWidth: '500px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          position: 'relative',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          &times;
        </button>
        <div style={{ textAlign: 'center' }}>
          <Avatar
            googleId="118096717852922241760"
            size="90"
            round={true}
            src={participant.avatarImage}
          />
        </div>
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <strong>{participant.name}</strong>
        </div>
        <div style={{ textAlign: 'center' }}>
          <small>{participant.company}</small>
        </div>
        <div style={{ textAlign: 'center' }}>
          <small>{participant.responsibility}</small>
        </div>
        {participant.mini_bio && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '16px',
            }}
          >
            <small>{participant.mini_bio}</small>
          </div>
        )}
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <LinkMediaSocial
            itemData={participant}
            mediaSocial={participant.linkMediaSocial}
            align=""
            marginRight="mr-0"
          />
        </div>
      </div>
    </div>
  );
};

export default NetworkModal;
