import Api from './config/Api';
import { setLoading } from '../store/loading/LoadingReducer';
import { addMessage } from '../store/messages/MessagesReducer';
import { responseError } from './exception/ApiException';
import { refreshUserAuth } from '../store/auth/AuthReducer';
import { MediaSocialEnum } from '../utils/MediaSocialEnum';

class NetworkServices {
  editNetwork(dataInput, history) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post(
          '/network/' + dataInput.eventId,
          dataInput
        );

        if (response && response.data && response.status === 200) {
          dispatch(refreshUserAuth());
          dispatch(setLoading(false));

          dispatch(
            addMessage({
              message: 'Network do participante cadastro com sucesso!',
              level: 'success',
            })
          );

          history.push('/network/participant/' + dataInput.eventId);
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
    };
  }

  searchNetwork(dataInput, eventId) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post(
          '/network/search/' + eventId,
          dataInput
        );

        if (response && response.data && response.data) {
          dispatch(setLoading(false));
          return response.data;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return null;
    };
  }

  loadSocialMedia(obj) {
    const item = [];

    item.push({
      urlIcon: '',
      urlPage: obj.linkedinUrl,
      typeMedia: MediaSocialEnum.LINKEDIN,
    });

    item.push({
      urlIcon: '',
      urlPage: obj.twitterUrl,
      typeMedia: MediaSocialEnum.TWITTER,
    });

    item.push({
      urlIcon: '',
      urlPage: obj.facebookUrl,
      typeMedia: MediaSocialEnum.FACEBOOK,
    });

    item.push({
      urlIcon: '',
      urlPage: obj.instagramUrl,
      typeMedia: MediaSocialEnum.INSTAGRAM,
    });

    if (obj.networkMobile) {
      item.push({
        urlIcon: '',
        urlPage:
          'https://api.whatsapp.com/send?phone=' +
          obj.networkMobile.replace(/\D/g, ''),
        typeMedia: MediaSocialEnum.WHATSAPP,
      });
    }
    return item;
  }

  updateInterestAreas(interestAreas, value, item) {
    return interestAreas.map((m) => {
      if (item !== 'Todas as Categorias' && m.name === 'Todas as Categorias') {
        return {
          checked: false,
          name: m.name,
        };
      }

      if (item !== 'Todas as Categorias' && m.name === item) {
        return {
          checked: value,
          name: m.name,
        };
      }

      if (item === 'Todas as Categorias' && m.name === 'Todas as Categorias') {
        return {
          checked: true,
          name: m.name,
        };
      }

      if (item === 'Todas as Categorias') {
        return {
          checked: false,
          name: m.name,
        };
      }

      return m;
    });
  }

  updateCategoriasNetwork(categorias_network, value, item) {
    return categorias_network.map((m) => {
      if (item !== 'Todas as Categorias' && m.name === 'Todas as Categorias') {
        return {
          checked: false,
          name: m.name,
        };
      }

      if (item !== 'Todas as Categorias' && m.name === item) {
        return {
          checked: value,
          name: m.name,
        };
      }

      if (item === 'Todas as Categorias' && m.name === 'Todas as Categorias') {
        return {
          checked: true,
          name: m.name,
        };
      }

      if (item === 'Todas as Categorias') {
        return {
          checked: false,
          name: m.name,
        };
      }

      return m;
    });
  }

  getInterestAreasSelected(item, selectedInterestAreas) {
    let areas = item !== 'Todas as Categorias' ? selectedInterestAreas : [];

    if (areas.find((f) => f === item)) {
      areas = areas.filter((f) => f !== item);
    } else {
      areas = areas.concat(item);
    }

    if (item === 'Todas as Categorias') {
      areas = [];
    }
    return areas;
  }

  getCategoriasNetworkSelected(item, setSelectedCategorias_network) {
    let areas = item !== 'Todas as Categorias' ? setSelectedCategorias_network : [];

    if (areas.find((f) => f === item)) {
      areas = areas.filter((f) => f !== item);
    } else {
      areas = areas.concat(item);
    }

    if (item === 'Todas as Categorias') {
      areas = [];
    }
    return areas;
  }

  getSearchOnline(value, checked) {
    if (value === 'ONLINE' && !checked) {
      return '';
    }

    if (value === 'OFFLINE' && !checked) {
      return '';
    }

    return value;
  }
}

export default new NetworkServices();
