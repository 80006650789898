import React, { useEffect } from 'react';
import Main from '../components/template/Main';

const PageNotFound = (props) => {
  useEffect(() => {
    if (process.env.REACT_APP_MAIN_URL.includes(window.location.host)) {
      localStorage.setItem('@navigator', JSON.stringify({ eventId: null }));
    }
  });
  if (
    process.env.REACT_APP_MAIN_URL.includes(window.location.host) ||
    (!process.env.REACT_APP_MAIN_URL.includes(window.location.host) &&
      window.location.pathname !== '/')
  ) {
    return (
      <Main>
        <div className="container-sm my-5">
          <div className="card text-center bg-light mb-3">
            <div className="card-header card-title text-left">
              <h2>
                <strong>{props.title}</strong>
              </h2>
            </div>
            <div className="card-body">
              <h3 className="text-warning">
                <i className="fa fa-warning fa-2x" />
                <div className="mt-3">{props.description}</div>
              </h3>
            </div>
          </div>
        </div>
      </Main>
    );
  } else {
    return <></>;
  }
};

export default PageNotFound;
