import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Countdown from '../countdown';
import 'moment/locale/pt-br';
import 'moment-timezone';
require('./styles.css');

const MainPreview = (props) => {
  const [palestranteData, setPalestranteData] = useState(null);

  const getPalestrante = () => {
    if (props.mainData && props.mainData !== null) {
      setPalestranteData(props.mainData.palestrantes);
    }
  };

  // const capitalizeFirstLetter = (string) => {
  //   string = string.toLowerCase();
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  const getIsLive = (mainData) => {
    return mainData.isLive ? (
      <div id="isLiveHolder"></div>
    ) : (
      <Countdown
        reload={props.reload}
        setReload={props.setReload}
        timeTillDate={
          mainData.data.substring(0, 10) + ' ' + mainData.hora_inicio + '+00:00'
        }
      />
    );
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(getPalestrante, []);

  if (props.mainData && props.mainData !== null && palestranteData !== null) {
    return (
      <>
        <div id="mainPalestranteHolder">
          <Moment format="LLL" locale="pt-br" tz={userTimeZone}>
            {props.mainData.data.substring(0, 11) +
              props.mainData.hora_inicio +
              '+00:00'}
          </Moment>
          <div className="pull-right">{getIsLive(props.mainData)}</div>
          <div className="clearfix"></div>
        </div>
        <div id="mainPreviewHolder">
          <Link to={'/room/' + props.mainData.roomId} title="Assista agora">
            <img
              src={props.mainData.urlIcon}
              alt={props.mainData.palestra_nome}
              id="mainImage"
              onLoad={() => {
                props.handleResizeAgenda();
              }}
            />
          </Link>
          <div id="mainTextHolder">
            <div
              style={{
                maxHeight: props.textHeight,
              }}
            >
              <h3>{props.mainData.palestra_nome}</h3>
              {palestranteData.map((item, index) => {
                return (
                  <p key={index} className="mb-0">
                    {item.nome}
                  </p>
                );
              })}
              <p className="mb-0 mt-1">{props.mainData.palestra_descricao}</p>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default MainPreview;
