import Api from './config/Api';
import { setLoading } from '../store/loading/LoadingReducer';
import { addMessage } from '../store/messages/MessagesReducer';
import { responseError } from './exception/ApiException';
import instanceApiUploadS3 from './config/ApiUploadS3';
import { updateProfile } from '../session/Auth';
import { refreshUserAuth } from '../store/auth/AuthReducer';
import { removeItem } from '../store/cart/CartReducer';
import { removeRemoveAllItem } from '../store/cart/CartItemReducer';
import AuthService from './AuthServices';

class UserServices {
  register(data, cart, history, doc) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        if (doc) {
          await Api.instance.post('/users/register', data);
        } else {
          await Api.instance.post('/users/registerNoDoc', data);
        }

        if (cart && cart.modelEvent && cart.modelEvent === 'CONVIDADOS') {
          dispatch(removeItem());
          dispatch(removeRemoveAllItem());
        }

        dispatch(setLoading(false));

        dispatch(
          addMessage({
            message: 'Cadastro realizado com sucesso!',
            level: 'success',
          })
        );

        const { eventId, email, password } = data;
        await dispatch(
          AuthService.signIn({ eventId, email, password }, cart, history)
        );
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
    };
  }

  confirmRegister(data, history) {
    Api.instance.get('/confirm/' + data.codeActive).catch();
    history.push('/login');
  }

  editUserProfile(data, { roomsFair, managerEvents, history, cart }) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/users/edit', data);

        if (response && response.data && response.status === 201) {
          updateProfile(data);
          dispatch(refreshUserAuth());

          dispatch(setLoading(false));

          if (cart) {
            history.push('/shoppingFinish');
            return;
          }

          if (roomsFair && roomsFair.length > 0 && managerEvents) {
            history.push('/eventLobby');
          } else {
            history.push('/home');
          }
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
    };
  }

  uploadPhoto(fileType, file) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.post('/users/signedUrl', { fileType });

        if (response && response.data && response.data.payload) {
          const { signedUrl, urlFile } = response.data.payload;
          try {
            await instanceApiUploadS3.put(signedUrl, file);
          } catch (e) {}

          dispatch(setLoading(false));

          return urlFile;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }

      dispatch(setLoading(false));

      return null;
    };
  }

  findUserProfile(eventId) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await Api.instance.get('/users/' + eventId);

        if (response && response.data && response.data.payload) {
          dispatch(setLoading(false));
          return response.data.payload;
        }
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(addMessage(responseError(e)));
      }
      dispatch(setLoading(false));
      return null;
    };
  }
}

export default new UserServices();
