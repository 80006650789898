import React, {memo} from "react";

const InputQuantity = ({disabled=false, cart, item, decrementCount, incrementCount, onChangeValues, floatDirection = "float-sm-right"}) => {
    return (
        <div className={`d-flex ${floatDirection}`}>
            <button disabled={disabled} className="custom-btn-count btn mr-1"
                    onClick={() => decrementCount(item)}> -
            </button>
            <input
                disabled={disabled}
                type="number"
                id="ingressSimples"
                name="ingressSimples"
                className="custon-ingress-input form-control"
                min="0"
                required
                value={Number(item.quantity)}
                onChange={e =>
                    onChangeValues(
                        cart,
                        item.ticketId,
                        e.target.value
                    )
                }
            />
            <button
                disabled={disabled}
                className="custom-btn-count btn ml-1"
                    onClick={() => incrementCount(item)}> +
            </button>
        </div>
    )
}


export default memo(InputQuantity);
