import IconCheck from '../svg/IconCheck';
import React, { memo } from 'react';

const PaymentDiscountFree = ({ cancelOrder, finishFreeEvent }) => {
  return (
    <>
      <div className="col-lg-12 col-md-12 p-2 mt-4">
        <div className="row">
          <div className="col-12 p-2">
            <div className="row">
              <div className="col-12 col-lg-4 offset-lg-4">
                <button
                  className="btn  btn-lg btn-grey"
                  onClick={() => cancelOrder()}
                >
                  CANCELAR
                </button>
              </div>

              <div className="col-12 col-lg-4">
                <button
                  className="btn btn-lg btn-orange"
                  onClick={() => finishFreeEvent(null, 'FREE')}
                >
                  FINALIZAR
                  <IconCheck />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PaymentDiscountFree);
