import React, {memo} from "react";
import {consts} from "react-elastic-carousel";

const NavigatorArrow = ({type, onClick, isEdge}) => {
    if (type === consts.PREV) {
        return (
            <button className="btn-custom-carousel bg-white border-0 m-auto left rounded border-shadow" onClick={onClick} disabled={isEdge}>
                <i className="mt-1 mr-1 fa fa-caret-left"/>
            </button>
        )
    }

    return (
        <button className="btn-custom-carousel bg-white border-0 m-auto right rounded border-shadow" onClick={onClick} disabled={isEdge}>
            <i className="mt-1 ml-1 fa fa-caret-right"/>
        </button>
    )
}

export default memo(NavigatorArrow);
