import React, { useEffect, useState } from 'react';
import Chat from './Chat';
import QA from './QA';
import External from './External';
import EventRoomShop from './EventRoomShop';
import QueueEvent from './QueueEvent';

const Interatividades = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState(null);

  const productActive = props.roomsFair.urlShopbanner
    ? JSON.parse(props.roomsFair.urlShopbanner).length > 0
    : false;

  let dados = JSON.parse(props.dadosInteratividade);

  dados.push({
    name: 'Loja',
    type: 'PRODUTOS',
    products: props.roomsFair.urlShopbanner,
    active: productActive,
  });

  const tabsAtivas = dados.filter(function (data) {
    return data.active === true;
  });

  useEffect(() => {
    if (activeTabName) {
      const isActive = tabsAtivas.filter(function (data) {
        return data.type === activeTabName;
      });

      if (isActive.length > 0) {
        for (let index = 0; index < tabsAtivas.length; index++) {
          if (tabsAtivas[index].type === activeTabName) {
            setActiveTab(index);
          }
        }
      } else {
        setActiveTab(0);
        if (tabsAtivas.length > 0) {
          setActiveTabName(tabsAtivas[0].type);
        }
      }
    }
  }, [activeTab, dados, tabsAtivas, activeTabName]);

  useEffect(() => {
    if (tabsAtivas.length > 0) {
      setActiveTabName(tabsAtivas[0].type);
    }

    // eslint-disable-next-line
  }, []);

  return dados ? (
    <div className="row mx-0" style={{ height: '100%' }}>
      <div
        className="col-2 p-0"
        style={{
          height: '100%',
          background: '#F9FEFF',
          borderRight: '1px solid #c8c8c8',
        }}
      >
        <ul className="interatividades-list m-0" style={{ listStyleType: 'none' }}>
          {tabsAtivas.map((item, index) => {
            if (item.active) {
              return (
                <li key={'tabsInteratividade' + index}>
                  <button
                    className={activeTab === index ? 'active event-btn-active' : ''}
                    onClick={() => {
                      setActiveTab(index);
                      setActiveTabName(item.type);
                    }}
                    style={{
                      width: '100%',
                      border: 'unset',
                      background: 'transparent',
                      padding: '20px 0',
                    }}
                  >
                    <div
                      className="d-flex interatividades-btns"
                      style={{ flexDirection: 'column', color: '#BEC4D9' }}
                    >
                      {item.type === 'CHAT' ? (
                        <i className="fa fa-comment" aria-hidden="true"></i>
                      ) : null}
                      {item.type === 'QA' ? (
                        <i className="fa fa-comments" aria-hidden="true"></i>
                      ) : null}
                      {item.type === 'EXTERNO' ? (
                        <i
                          className="pb-2 fa fa-external-link-square"
                          aria-hidden="true"
                        ></i>
                      ) : null}
                      {item.type === 'PRODUTOS' ? (
                        <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                      ) : null}
                      {item.type === 'FILA' ? (
                        <i className="fa fa-hourglass" aria-hidden="true"></i>
                      ) : null}
                      {item.name}
                    </div>
                  </button>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
      <div className="col-10 p-0">
        {tabsAtivas.map((item, index) => {
          if (item.type === 'CHAT' && item.active) {
            return (
              <div
                key={'contentInteratividade' + index}
                className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              >
                <Chat dados={item} />
              </div>
            );
          }
          if (item.type === 'QA' && item.active) {
            return (
              <div
                key={'contentInteratividade' + index}
                className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              >
                <QA dados={item} />
              </div>
            );
          }
          if (item.type === 'EXTERNO' && item.active) {
            return (
              <div
                key={'contentInteratividade' + index}
                className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              >
                <External dados={item} />
              </div>
            );
          }

          if (item.type === 'PRODUTOS' && item.active) {
            return (
              <div
                key={'contentInteratividade' + index}
                className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              >
                <EventRoomShop item={item} />
              </div>
            );
          }

          if (item.type === 'FILA' && item.active) {
            return (
              <div
                key={'contentInteratividade' + index}
                className={activeTab === index ? 'active-tab' : 'hidden-tab'}
              >
                <QueueEvent item={item} roomsFair={props.roomsFair} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  ) : null;
};

export default Interatividades;
