import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import { existUrl } from '../../utils/Ultis';
import AuthService from '../../services/AuthServices';
import { useHistory } from 'react-router-dom';

const MenuFast = () => {
  const auth = useSelector((state) => state.auth);
  const [listMenu, setListMenu] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getUrlPageSubMenu = (typesEvent, roomFairId) => {
    if (typesEvent === 'access_room') {
      return '/roomLobby/' + roomFairId;
    }

    if (typesEvent === 'access_fairs') {
      return '/fair/' + roomFairId;
    }

    return '#';
  };

  const getUrlPageMenu = (typesEvent, eventId, urlExternal) => {
    if (typesEvent === 'lobby_event') {
      return '/eventLobby';
    }

    if (typesEvent === 'access_coffee') {
      return urlExternal;
    }

    if (typesEvent === 'access_room') {
      return '/roomsList/' + eventId;
    }

    if (typesEvent === 'access_fairs') {
      return '/fairsList/' + eventId;
    }

    if (typesEvent === 'access_information') {
      return '/information/' + eventId;
    }

    if (typesEvent === 'network_participant') {
      return '/network/' + eventId;
    }

    return '#';
  };

  useEffect(() => {
    const mountTreeMenu = (roomsFair) => {
      let eventId = '';
      let urlLobby = '';
      const treeMenu = auth.managerEvents.items.map((it) => {
        eventId = it.eventId;
        urlLobby = it.urlLobby;
        const subMenu = roomsFair
          .filter((obj) => obj.typeEvent === it.types)
          .map((item) => {
            return {
              subtitle: item.roomFairName,
              urlPage: getUrlPageSubMenu(item.typeEvent, item.roomFairId),
            };
          });

        if (
          subMenu.length <= 0 &&
          (it.types === 'access_room' || it.types === 'access_fairs')
        ) {
          return null;
        }

        if (it.types === 'network_participant' && !auth.exibeNetwork) {
          return null;
        }

        return {
          title: it.description,
          typeEvent: it.types,
          urlLobby,
          urlPage: getUrlPageMenu(it.types, eventId, it.urlExternal),
        };
      });

      return treeMenu.filter((item) => item !== null);
    };

    const fetchData = () => {
      const treeMenu = mountTreeMenu(auth.roomsFair);
      setListMenu(treeMenu ? treeMenu : []);
    };

    if (auth.roomsFair && auth.managerEvents) {
      fetchData();
    }
  }, [auth]);

  return (
    <>
      <ul className="navbar-nav m-auto">
        {listMenu &&
          listMenu
            .filter((obj) => existUrl(obj.urlPage))
            .map((item, index) => {
              return <MenuItem item={item} key={index} />;
            })}
        <li style={{ display: 'none' }}>
          <button
            id="reloadBtn"
            onClick={() => {
              dispatch(AuthService.reloadData(auth, history));
            }}
          >
            Reload
          </button>
        </li>
      </ul>
    </>
  );
};
export default memo(MenuFast);
