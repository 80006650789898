import { mask, unMask } from 'remask';
import format from 'date-format';

export const maskDocumentNumber = (documentNumber, documentType) => {
  if (!documentNumber) {
    return '';
  }

  const originalValue = unMask(documentNumber);
  let maskedValue = mask(originalValue, '999.999.999-99');
  if (documentType && documentType === 'CNPJ') {
    maskedValue = mask(originalValue, '99.999.999/9999-99');
  }

  return maskedValue;
};

export const maskPhone = (value) => {
  if (!value) {
    return '';
  }

  const originalValue = unMask(value);

  return mask(originalValue, '(99) 99999-9999');
};

export const maskZipCode = (value) => {
  if (!value) {
    return '';
  }

  const originalValue = unMask(value);

  return mask(originalValue, '99999-999');
};

export const maskCardCredit = (value) => {
  if (!value) {
    return '';
  }

  const originalValue = unMask(value);

  return mask(originalValue, '9999 9999 9999 9999');
};

export const maskNumber = (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/\D/g, '');
};

export const maskDateCardCredit = (value) => {
  if (!value) {
    return '';
  }

  if (value.length === 7) {
    const rgx = /(0[1-9]|10|11|12)[/]20[0-9]{2}/;
    const matches = rgx.exec(value);
    if (matches == null) {
      return '';
    }
  }

  const originalValue = unMask(value);

  return mask(originalValue, '99/9999');
};

export const maskCurrency = (value) => {
  if (!value) {
    value = 0;
  }

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const maskInviteVoucher = (value) => {
  if (!value) {
    return '';
  }

  return value.trim();
};

export const maskDate = (value) => {
  if (!value) {
    return '';
  }
  const date = new Date(value.replace('T', ' '));
  return format.asString('dd/MM/yyyy', date);
};

export const maskName = (value) => {
  if (!value) {
    return '';
  }

  const rgx = /^[0-9]|[´^~áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]|[/[\]]|[/()]+$/;

  return value.replace(rgx, '');
};

export const getDate = (value) => {
  if (!value) {
    return value;
  }

  return value.substr(0, 10);
};

export const getTime = (value) => {
  if (!value) {
    return value;
  }

  return value.substr(10, 6);
};
