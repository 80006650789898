export const KEY_NAVIGATOR = '@navigator';
export const KEY_NAVIGATOR_CONFIRM = '@navigatorConfirm';

const getNavigator = () => JSON.parse(localStorage.getItem(KEY_NAVIGATOR));
const getNavigatorConfirm = () =>
  JSON.parse(localStorage.getItem(KEY_NAVIGATOR_CONFIRM));

const updateNavigator = () => {
  const navigatorConfirm = getNavigatorConfirm();

  if (navigatorConfirm) {
    const {
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    } = navigatorConfirm;
    setEventInfo(
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento
    );
    removeNavigatorConfirm();
    return {
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    };
  }

  return null;
};

export const getEventId = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.eventId) {
    return result.eventId;
  }

  return '';
};

export const getExibirTraducaoAutomatica = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.exibir_traducao_automatica) {
    return result.exibir_traducao_automatica;
  }

  return '';
};

export const getExigirSenhaComplexa = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.exigir_senha_complexa) {
    return result.exigir_senha_complexa;
  }

  return '';
};

export const getFavicon = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.favicon) {
    return result.favicon;
  }

  return '';
};

export const getUrlFacebook = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.url_facebook) {
    return result.url_facebook;
  }

  return '';
};

export const getUrlLinkedin = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.url_linkedin) {
    return result.url_linkedin;
  }

  return '';
};

export const getUrlTwitter = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.url_twitter) {
    return result.url_twitter;
  }

  return '';
};

export const getCssCustom = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.css_custom) {
    return result.css_custom;
  }

  return '';
};

export const getJsCustom = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.js_custom) {
    return result.js_custom;
  }

  return '';
};

export const getCamposPersonalizados = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.campos_personalizados) {
    return result.campos_personalizados;
  }

  return '';
};

export const getPersonalizadosObrigatorios = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.personalizados_obrigatorios) {
    return result.personalizados_obrigatorios;
  }

  return '';
};

export const getPrimaryColor = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.primaryColor) {
    return result.primaryColor;
  }

  return '#f18e12';
};

export const getSecondaryColor = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.secondaryColor) {
    return result.secondaryColor;
  }

  return '#000000';
};

export const getEventTitle = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.eventTitle && result.eventTitle !== '') {
    return result.eventTitle;
  }

  return null;
};

export const getTituloAcessoEvento = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.titulo_acesso_evento && result.titulo_acesso_evento !== '') {
    return result.titulo_acesso_evento;
  }

  return '';
};

export const getTituloCadastrar = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.titulo_cadastrar && result.titulo_cadastrar !== '') {
    return result.titulo_cadastrar;
  }

  return '';
};

export const getTituloInscrevase = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.titulo_inscrevase && result.titulo_inscrevase !== '') {
    return result.titulo_inscrevase;
  }

  return '';
};

export const getTituloLogin = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.titulo_login && result.titulo_login !== '') {
    return result.titulo_login;
  }

  return '';
};

export const getTituloAreasInteresse = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (
    result &&
    result.titulo_areasInteresse &&
    result.titulo_areasInteresse !== ''
  ) {
    return result.titulo_areasInteresse;
  }

  return '';
};

export const getTituloInformacoesGerais = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (
    result &&
    result.titulo_informacoes_gerais &&
    result.titulo_informacoes_gerais !== ''
  ) {
    return result.titulo_informacoes_gerais;
  }

  return '';
};

export const getTicketId = () => {
  const result = getNavigator();

  if (result && result.ticketId) {
    return result.ticketId;
  }

  return '';
};

export const getModelEvent = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.modelEvent && result.modelEvent !== '') {
    return result.modelEvent;
  }

  return null;
};

export const getUrlLogo = () => {
  let result = getNavigator();

  if (!result) {
    result = updateNavigator();
  }

  if (result && result.urlLogo) {
    return result.urlLogo;
  }

  return '';
};

export const setTicketId = (ticketId) => {
  const result = getNavigator();
  if (result) {
    localStorage.setItem(KEY_NAVIGATOR, JSON.stringify({ ...result, ticketId }));
  }
};

export const setEventInfo = (
  eventId,
  exibir_traducao_automatica,
  exigir_senha_complexa,
  favicon,
  primaryColor,
  secondaryColor,
  urlLogo,
  eventTitle,
  modelEvent,
  url_facebook,
  url_linkedin,
  url_twitter,
  url_landpage,
  css_custom,
  js_custom,
  campos_personalizados,
  personalizados_obrigatorios,
  direcionar_lobby_salas,
  url_lgpd_cliente,
  texto_caixa_home,
  titulo_acesso_evento,
  titulo_cadastrar,
  titulo_inscrevase,
  titulo_login,
  titulo_areasInteresse,
  titulo_informacoes_gerais,
  formas_pagamento
) => {
  const result = getNavigator();
  if (result) {
    localStorage.setItem(
      KEY_NAVIGATOR,
      JSON.stringify({
        ...result,
        eventId,
        exibir_traducao_automatica,
        exigir_senha_complexa,
        favicon,
        primaryColor,
        secondaryColor,
        urlLogo,
        eventTitle,
        modelEvent,
        url_facebook,
        url_linkedin,
        url_twitter,
        url_landpage,
        css_custom,
        js_custom,
        campos_personalizados,
        personalizados_obrigatorios,
        direcionar_lobby_salas,
        url_lgpd_cliente,
        texto_caixa_home,
        titulo_acesso_evento,
        titulo_cadastrar,
        titulo_inscrevase,
        titulo_login,
        titulo_areasInteresse,
        titulo_informacoes_gerais,
        formas_pagamento,
      })
    );
    return;
  }
  localStorage.setItem(
    KEY_NAVIGATOR,
    JSON.stringify({
      ticketId: '',
      eventId,
      exibir_traducao_automatica,
      exigir_senha_complexa,
      favicon,
      primaryColor,
      secondaryColor,
      urlLogo,
      eventTitle,
      modelEvent,
      url_facebook,
      url_linkedin,
      url_twitter,
      url_landpage,
      css_custom,
      js_custom,
      campos_personalizados,
      personalizados_obrigatorios,
      direcionar_lobby_salas,
      url_lgpd_cliente,
      texto_caixa_home,
      titulo_acesso_evento,
      titulo_cadastrar,
      titulo_inscrevase,
      titulo_login,
      titulo_areasInteresse,
      titulo_informacoes_gerais,
      formas_pagamento,
    })
  );
};

export const getNavigatorRoomFair = () => {
  const result = getNavigator();

  if (result && result.navigatorRoomFair) {
    return result.navigatorRoomFair;
  }

  return { typeEvent: '', urlLobby: '' };
};

export const setNavigatorConfirm = (data) => {
  localStorage.setItem(KEY_NAVIGATOR_CONFIRM, JSON.stringify(data));
};

export const setNavigatorRoomFair = (navigatorRoomFair) => {
  const result = getNavigator();
  if (result) {
    localStorage.setItem(
      KEY_NAVIGATOR,
      JSON.stringify({ ...result, navigatorRoomFair })
    );
    return;
  }
  localStorage.setItem(
    KEY_NAVIGATOR,
    JSON.stringify({
      navigatorRoomFair,
      ticketId: '',
      eventId: '',
      primaryColor: '#f18e12',
      secondaryColor: '#000000',
    })
  );
};

export const removeNavigator = () => {
  const result = getNavigator();
  if (result) {
    localStorage.setItem(
      KEY_NAVIGATOR,
      JSON.stringify({
        ...result,
        navigatorRoomFair: null,
        ticketId: '',
        primaryColor: '#f18e12',
        secondaryColor: '#000000',
      })
    );
    removeNavigatorConfirm();
  }
};

export const removeNavigatorConfirm = () => {
  const navigatorConfirm = getNavigatorConfirm();
  if (navigatorConfirm) {
    localStorage.removeItem(KEY_NAVIGATOR_CONFIRM);
  }
};
