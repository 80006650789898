import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const EventFree = ({ preview = false, model, maxHeight, userStorage }) => {
  const titulo_cadastrar = useSelector((state) => state.navigator.titulo_cadastrar);
  const titulo_inscrevase = useSelector(
    (state) => state.navigator.titulo_inscrevase
  );
  const titulo_login = useSelector((state) => state.navigator.titulo_login);

  return (
    <div id="cxcad" className="card border-light mb-3 border-shadow">
      <div className="bg-dark m-0 p-0">
        <div className="card-header text-light bg-transparent font-weight-bolder m-3 border-0">
          <div className="row">
            <div className="col-12">
              <h3 className="text-light">
                <strong id="caixa-inscricao">
                  {model === 'GRATUITO' ? (
                    <Link
                      to="/register"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      {titulo_inscrevase ? titulo_inscrevase : 'INSCREVA-SE'}
                    </Link>
                  ) : titulo_inscrevase ? (
                    titulo_inscrevase
                  ) : (
                    model
                  )}
                </strong>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-body m-3 pt-0 boxContentHolder"
        style={{ maxHeight: maxHeight }}
      >
        <div className="mt-3 shopping-box">
          <p className="card-title font-weight-bold">
            {userStorage && userStorage.texto_caixa_home
              ? userStorage.texto_caixa_home
              : 'Crie ou confirme o seu cadastro.'}
          </p>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="mt-5">
          {!preview ? (
            model === 'CONVIDADOS' ? (
              <>
                <Link to="/login">
                  <button className="btn btn-lg btn-white mb-2">
                    {titulo_login ? titulo_login : 'ENTRAR'}
                  </button>
                </Link>
                <Link to="/register">
                  <button className="btn btn-lg btn-orange">
                    {titulo_cadastrar ? titulo_cadastrar : 'CONFIRMAR CADASTRO'}
                  </button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <button className="btn btn-lg btn-white mb-2">
                    {titulo_login ? titulo_login : 'ENTRAR'}
                  </button>
                </Link>
                <Link to="/register">
                  <button className="btn btn-lg btn-orange">
                    {titulo_cadastrar ? titulo_cadastrar : 'CADASTRAR'}
                  </button>
                </Link>
              </>
            )
          ) : (
            <button className="btn btn-lg btn-orange">
              CADASTRAR OU CONECTE-SE
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(EventFree);
