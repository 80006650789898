import { useDispatch, useSelector } from 'react-redux';
import Main from '../components/template/Main';
import React, { useEffect, useState } from 'react';
import TemplateApp from '../components/template/TemplateApp';
import { useHistory } from 'react-router-dom';
import EventsServices from '../services/EventsServices';
import Agenda from '../components/agenda';
import SponsorsCarousel from '../components/sponsorsCarousel';
import Programacao from '../components/programacao';
import Slider from 'react-slick';

const ManagerEvents = (props) => {
  const auth = useSelector((state) => state.auth);
  const [managerEvents, setManagerEvents] = useState(null);
  const [lobbyImages, setLobbyImages] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    adaptiveHeight: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        !auth.tickets.some((ticket) => ticket.participantId === auth.participantId)
      ) {
        history.push('/home');
        return;
      }

      if (!props.preview && auth && auth.managerEvents) {
        if (!auth.tickets || auth.tickets.length <= 0) {
          history.push('/home');
          return;
        }
        setManagerEvents(auth.managerEvents);
        try {
          setLobbyImages(JSON.parse(auth.managerEvents.urlLobby));
        } catch (e) {
          console.log(e);
        }
      }

      if (props.preview) {
        const result = await dispatch(
          EventsServices.findEventsPreview(props.eventId, history)
        );

        if (result && result.managerEvents) {
          setManagerEvents(result.managerEvents);
        }
      }
    };

    if (!managerEvents) {
      fetchData().catch();
    }
  }, [dispatch, history, props, auth, managerEvents]);

  return (
    <TemplateApp>
      <Main>
        <div className="row no-gutters">
          <div className="col-lg-12 col-md-12 m-auto">
            <div id="bannerWrapper">
              {lobbyImages !== [] ? (
                <Slider {...settings}>
                  {lobbyImages.map((image, i) => {
                    return (
                      <div className="image-banner-holder" key={i}>
                        <img
                          className="image-banner"
                          src={unescape(image.url)}
                          alt={unescape(image.nome)}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : null}
            </div>
            <Agenda data={auth} />
            <SponsorsCarousel data={auth} />
            <Programacao data={auth} />
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default ManagerEvents;
