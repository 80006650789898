import React, {useEffect, useState} from "react";
import CartCalculate from "../components/cart/CartCalculate";
import {useDispatch, useSelector} from "react-redux";
import TemplateApp from "../components/template/TemplateApp";
import Main from "../components/template/Main";
import {Link} from "react-router-dom";
import {maskCurrency} from "../utils/MaskInput";
import {existUrl} from "../utils/Ultis";
import {addMessage} from "../store/messages/MessagesReducer";


const PurchaseDetail = (props) => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth);
    const [purchaseDetail, setPurchaseDetail] = useState([]);
    const [itemPurchase, setItemPurchase] = useState(null);

    const [currentPage] = useState(1);
    const [itemsPerPage] = useState(300);

    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = purchaseDetail ? purchaseDetail.slice(indexOfFirstPost, indexOfLastPost) : [];

    const downloadBillet = (e, billetUrl) => {
        e.preventDefault();
        if (existUrl(billetUrl)) {
            window.open(billetUrl, '_blank');
        } else {
            dispatch(
                addMessage({
                    message: 'Boleto Báncário indisponível!',
                    level: 'warning',
                })
            );
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const purchase = auth.purchase.find(item => item.purchaseId === Number(props.purchaseId));
            if (purchase && purchase.purchaseItems) {
                setItemPurchase(purchase);
                setPurchaseDetail(purchase.purchaseItems);
            }
        }

        if (props.purchaseId && purchaseDetail && purchaseDetail.length <= 0) {
            fetchData().catch();
        }

    }, [dispatch, auth, props, purchaseDetail]);

    return (
        <TemplateApp>
            <Main>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="my-2">
                                    <p>
                                        <strong>Detalhes da compra</strong><br/>
                                        <strong>Número do pedido: </strong>{itemPurchase && itemPurchase.purchaseId}<br/>
                                        <strong>Forma de pagamento: </strong>
                                        {
                                            itemPurchase && itemPurchase.paymentMethod === 'FREE' ? 'GRATUITO'
                                                : itemPurchase && itemPurchase.paymentMethod === 'CARD_CREDIT'
                                                ? 'CARTÃO DE CRÉDITO'
                                                : itemPurchase && itemPurchase.paymentMethod === 'BILLET'
                                                    ? 'BOLETO BANCÁRIO'
                                                    : ''
                                        }<br/>
                                        <strong>Status do pedido: </strong>
                                        {itemPurchase
                                            ? itemPurchase.statusPayment
                                                ? 'FINALIZADO'
                                                : 'PENDENTE'
                                            : ''}
                                    </p>

                                    {
                                        itemPurchase && itemPurchase.paymentMethod === 'BILLET' ?
                                            <p>
                                                <strong>Dowloand boleto: </strong>
                                                <Link to="route"
                                                      onClick={e => downloadBillet(e, itemPurchase && itemPurchase.billetUrl)}
                                                      target='_blank' className="ml-2 font-weight-bold">
                                                    <i className="fa fa-barcode"> Boleto</i>
                                                </Link>
                                            </p>
                                            : ''
                                    }

                                </div>

                                <div className="border-shadow">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 pr-0 pl-0 rounded">
                                                <table
                                                    className="table bg-white table-responsive-md table-responsive-sm mt-0">
                                                    <thead className="thead-dark">
                                                    <tr>
                                                        <th>Nº</th>
                                                        <th>Ingressos</th>
                                                        <th className="text-center">Quantidade</th>
                                                        <th>Valor</th>
                                                        <th>Total</th>
                                                        <th/>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <>
                                                        {
                                                            currentItems &&
                                                            currentItems.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.ticketId}</td>
                                                                        <td>{item.description}</td>
                                                                        <td className="text-center">{item.quantity}</td>
                                                                        <td>{maskCurrency(item.value)}</td>
                                                                        <td>{maskCurrency(item.total)}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 p-2 mt-n3 card border-right-0 border-left-0 border-bottom-0 rounded-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-label-group mt-3 float-lg-right float-md-right">
                                                    <CartCalculate
                                                        discountCoupon={itemPurchase && itemPurchase.discountCoupon}
                                                        purchaseDetail={purchaseDetail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row mb-4 justify-content-end flex-column-reverse flex-lg-row flex-md-row flex-sm-row">
                                    <div className="col-lg-4 col-md-4">
                                        <Link to={'/home/'} className="btn  btn-lg btn-grey mt-md-5 btn-cart">
                                                VOLTAR
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        </TemplateApp>
    )
}

export default PurchaseDetail;
