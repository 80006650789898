const levelError = (statusCode) => {
    if (!statusCode) {
        return 'danger';
    }

    switch (statusCode) {
        case 400:
            return 'warning';
        case 401:
            return 'danger'
        case 403:
            return 'danger'
        case 404:
            return 'warning';
        case 500:
            return 'danger';
        case 504:
            return 'danger';
        default:
            return 'danger'
    }
}

export const responseError = (e) => {
    if (e && e.message === "Tempo Excedido") {
        return {
            message: 'Tempo Excedido',
            level: levelError(504)
        }
    }

    if (!e || !e.response || !e.response.data) {
        return {
            message: 'Server Internal Error',
            level: levelError(500)
        }
    }

    const error = e.response.data;

    if (error && error.message) {
        return {
            message: error.message,
            level: levelError(error.statusCode)
        }
    }

    if (error && error.invalidParams) {
        const result = error.invalidParams.map(item => {
            return {
                message: item.message,
                level: levelError(error.statusCode)
            }
        });
        return result[0];
    }

    return {
        message: 'Server Internal Error',
        level: levelError(error.statusCode)
    }
}

export const isErrorCouponNotFound = (error) => {
    if (error && error.invalidParams) {
        const result = error.invalidParams.find(item => item.code === 'COUPON_NOT_FOUND');

        if (result) {
            return true;
        }
    }

    return false;
}