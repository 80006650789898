import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Main from '../components/template/Main';
import { setLoading } from '../store/loading/LoadingReducer';
import UploadImage from '../components/upload/UploadImage';
import TemplateApp from '../components/template/TemplateApp';
import { maskDocumentNumber, maskName, maskZipCode } from '../utils/MaskInput';
import LocalityServices from '../services/LocalityServices';
import SelectState from '../components/locality/SelectState';
import SelectCity from '../components/locality/SelectCity';
import UserServices from '../services/UserServices';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const UserProfile = (props) => {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const history = useHistory();
  const dispatch = useDispatch();

  const [userProfile, setUserProfile] = useState({
    participantId: auth.participantId,
    documentType: '',
    documentNumber: '',
    name: '',
    birthDate: '',
    phone: '',
    sex: '',
    email: '',
    password: '',
    confirmPassword: '',
    avatar: '',
    zipCode: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    state: '',
    city: '',
  });
  const [stateList, setStateList] = useState([]);
  const [cityValueList, setCityValueList] = useState([]);
  const [uploadImage, setUploadImage] = useState({ file: '', imageUrl: '' });

  const goHome = (e) => {
    e.preventDefault();

    if (auth && cart) {
      history.push('/shoppingFinish');
      return;
    }

    if (auth.roomsFair && auth.roomsFair.length > 0 && auth.managerEvents) {
      history.push('/eventLobby');
    } else {
      history.push('/home');
    }
  };

  const onLoadCities = async (stateId) => {
    setUserProfile({
      ...userProfile,
      state: stateId,
    });

    const resultCities = await dispatch(LocalityServices.findCityByState(stateId));
    if (resultCities) {
      setCityValueList(resultCities);
    }
  };

  const setValueCity = (cityId) => {
    setUserProfile({
      ...userProfile,
      city: cityId,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(UserServices.findUserProfile(auth.eventId));
      if (result) {
        const stateId = result.state;

        setUserProfile(result);

        const resultStates = await dispatch(LocalityServices.findAllStates());
        if (resultStates) {
          setStateList(resultStates);
        }

        const resultCities = await dispatch(
          LocalityServices.findCityByState(stateId)
        );
        if (resultCities) {
          setCityValueList(resultCities);
        }
      }
    };

    if (auth.participantId && userProfile.documentType === '') {
      fetchData().catch(() => dispatch(setLoading(false)));
    }
  }, [dispatch, userProfile.documentType, auth]);

  const submitImage = async () => {
    if (uploadImage.file) {
      const fileParts = uploadImage.file.name.split('.');
      const fileType = fileParts[1];

      const urlFile = await dispatch(
        UserServices.uploadPhoto(fileType, uploadImage.file)
      );

      if (urlFile && urlFile !== '') {
        return urlFile;
      }

      return '';
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const urlFile = await submitImage();

    await dispatch(
      UserServices.editUserProfile(
        { ...userProfile, avatar: urlFile, eventId: auth.eventId },
        {
          roomsFair: auth.roomsFair,
          managerEvents: auth.managerEvents,
          history,
          cart,
        }
      )
    );
  };

  return (
    <TemplateApp>
      <Main>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <div className="card-form border-shadow">
                <div className="m-1 ml-lg-5 mr-lg-5 mt-lg-4 mb-lg-4 md-md-2">
                  <h5 className="card-title text-left">
                    <strong>Perfil</strong>
                  </h5>
                </div>

                <hr className="hr-custom" />

                <div className="m-1 ml-lg-5 mr-lg-5 mb-lg-4 md-md-2">
                  <div className="card-body p-0">
                    <form onSubmit={handleSubmitForm}>
                      <div className="row no-gutters">
                        <div className="col-lg-12 p-1">
                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-3 col-lg-4 p-2">
                              <div className="form-label-group">
                                <label htmlFor="documentType">
                                  Tipo de Documento
                                </label>
                                <select
                                  className="form-control"
                                  id="documentType"
                                  value={userProfile.documentType}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      documentNumber: '',
                                      documentType: e.target.value,
                                    })
                                  }
                                >
                                  <option>...</option>
                                  <option value={'CNPJ'}>CNPJ</option>
                                  <option value={'CPF'}>CPF</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 p-2 ">
                              <div className="form-label-group">
                                <label htmlFor="documentNumber">Documento</label>
                                <input
                                  type="text"
                                  id="documentNumber"
                                  name="documentNumber"
                                  className="form-control"
                                  placeholder="Documento"
                                  value={maskDocumentNumber(
                                    userProfile.documentNumber,
                                    userProfile.documentType
                                  )}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      documentNumber: maskDocumentNumber(
                                        e.target.value,
                                        userProfile.documentType
                                      ),
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-6 col-lg-7 p-2">
                              <div className="form-label-group">
                                <label htmlFor="name">Nome</label>
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  className="form-control"
                                  placeholder="Nome"
                                  value={maskName(userProfile.name)}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      name: maskName(e.target.value),
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-lg-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="sex">Sexo</label>
                                <select
                                  className="form-control"
                                  id="genero"
                                  value={userProfile.sex || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      sex: e.target.value,
                                    })
                                  }
                                >
                                  <option />
                                  <option>MASCULINO</option>
                                  <option>FEMININO</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-5 p-2 font-weight-bold">
                            <div className="form-label-group ">
                              <label htmlFor="email">E-mail</label>
                              <input
                                readOnly
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="username"
                                className="form-control"
                                placeholder="Email"
                                value={userProfile.email || ''}
                                onChange={(e) =>
                                  setUserProfile({
                                    ...userProfile,
                                    email: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-10 p-2 font-weight-bold">
                            <div className="form-label-group ">
                              <label htmlFor="foto">Foto</label>
                              <UploadImage setUpload={setUploadImage} />
                            </div>
                          </div>

                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="birthDate">Data Nasc.</label>
                                <input
                                  type="date"
                                  id="birthDate"
                                  name="password"
                                  className="form-control"
                                  placeholder="Data Nascimento"
                                  value={userProfile.birthDate || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      birthDate: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-label-group">
                                <label htmlFor="phone">Telefone</label>
                                <PhoneInput
                                  defaultCountry="BR"
                                  placeholder="Telefone"
                                  value={userProfile.phone}
                                  onChange={(data) => {
                                    setUserProfile({ ...userProfile, phone: data });
                                  }}
                                  id="phone"
                                  name="phone"
                                  className="form-control"
                                  maxLength="20"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="zipCode">CEP</label>
                                <input
                                  maxLength="9"
                                  type="text"
                                  id="zipCode"
                                  name="zipCode"
                                  className="form-control"
                                  value={maskZipCode(userProfile.zipCode)}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      zipCode: maskZipCode(e.target.value),
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-label-group">
                                <label htmlFor="address">Endereço</label>
                                <input
                                  type="text"
                                  id="address"
                                  name="address"
                                  className="form-control"
                                  value={userProfile.address || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      address: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="number">Número</label>
                                <input
                                  maxLength="7"
                                  type="text"
                                  id="number"
                                  name="number"
                                  className="form-control"
                                  value={userProfile.number || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      number: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="complement">Complemento</label>
                                <input
                                  type="text"
                                  id="complement"
                                  name="complement"
                                  className="form-control"
                                  value={userProfile.complement || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      complement: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="neighborhood">Bairro</label>
                                <input
                                  type="text"
                                  id="neighborhood"
                                  name="neighborhood"
                                  className="form-control"
                                  value={userProfile.neighborhood || ''}
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      neighborhood: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-3 p-2">
                              <div className="form-label-group">
                                <label htmlFor="state">Estado</label>
                                <SelectState
                                  value={userProfile.state}
                                  list={stateList}
                                  setValue={onLoadCities}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-label-group">
                                <label htmlFor="city">Cidade</label>
                                <SelectCity
                                  value={userProfile.city}
                                  list={cityValueList}
                                  setValue={setValueCity}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-6 p-1">
                              <div className="form-label-group">
                                <label htmlFor="password">Alterar Senha</label>
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  autoComplete="new-password"
                                  className="form-control"
                                  placeholder="Senha"
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters font-weight-bold">
                            <div className="col-md-6 p-1">
                              <div className="form-label-group ">
                                <label htmlFor="confirmPassword">
                                  Confirmar senha
                                </label>
                                <input
                                  type="password"
                                  id="confirmPassword"
                                  name="password"
                                  autoComplete="new-password"
                                  className="form-control"
                                  placeholder="Confirmar senha"
                                  onChange={(e) =>
                                    setUserProfile({
                                      ...userProfile,
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row flex-column-reverse flex-lg-row flex-md-row flex-column-reverse">
                        <div className="col-md-6 p-lg-4 ">
                          <button
                            onClick={(e) => goHome(e)}
                            className="btn btn-lg btn-grey btn-default text-uppercase"
                            type="submit"
                          >
                            VOLTAR
                          </button>
                        </div>
                        <div className="col-md-6 p-lg-4 ">
                          <button
                            className="btn btn-lg btn-orange btn-default text-uppercase"
                            type="submit"
                          >
                            SALVAR
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default UserProfile;
