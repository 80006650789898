import React, { useEffect, useState } from 'react';

const Zoom = ({ eventId, roomId }) => {
  const [zoomURL, setZoomURL] = useState(null);

  const b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      )
    );
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('@airbnb-Token'));

    const signatureEndpoint = process.env.REACT_APP_BASE_URL + '/zoom/signature';

    const userName = b64EncodeUnicode(userData.username);
    const userEmail = userData.email;

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      },
      body: JSON.stringify({
        eventId: eventId,
        roomId: roomId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setZoomURL(
          '/meeting.html?name=' +
            userName +
            '&mn=' +
            response.meetingNumber +
            '&email=' +
            userEmail +
            '&pwd=' +
            response.password +
            '&role=0&lang=pt-PT&signature=' +
            response.signature +
            '&china=0&sdkKey=' +
            response.sdkKey +
            '&leaveUrl=' +
            '/leaveZoom/' +
            roomId
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [eventId, roomId]);

  return zoomURL ? (
    <a
      href={zoomURL}
      className="text-decoration-none"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="btn btn-dark btn-customcolor btn-lg btn-block btn-color p-3 bg-gradient-black">
        CONECTE-SE
      </button>
    </a>
  ) : null;
};

export default Zoom;
