import React from 'react';

const External = (props) => {
  const environment = process.env.REACT_APP_ENV_NAME;

  const isSliDo = props.dados.integrationLink.indexOf('sli.do') > -1;

  let link = props.dados.integrationLink;

  if (isSliDo) {
    const initialParameter =
      props.dados.integrationLink.indexOf('?') > -1
        ? '&lang=pt_BR&user_name='
        : '?lang=pt_BR&user_name=';

    link =
      props.dados.integrationLink +
      initialParameter +
      JSON.parse(localStorage.getItem('@airbnb-Token')).username +
      '&user_company=' +
      'participantId=' +
      JSON.parse(localStorage.getItem('@airbnb-Token')).participantId +
      '%2Benv' +
      environment +
      '%2BeventId' +
      JSON.parse(localStorage.getItem('@airbnb-Token')).eventId;
  }

  return (
    <iframe
      title={props.dados.name}
      src={link}
      width="100%"
      id="externalFrame"
      style={{ height: '100%' }}
      allow="camera; microphone; autoplay; encrypted-media; display-capture; clipboard-read; clipboard-write; fullscreen; geolocation; midi; payment; usb;"
    ></iframe>
  );
};

export default External;
